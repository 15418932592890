const CurrenyFormat = ({ Currency = null, trunc = false }) => {
  let formattedCurrency = new Intl.NumberFormat("en-US", {
    // maximumFractionDigits: 2,
    // minimumFractionDigits: 0,
  }).format(Currency || 0);

  if (formattedCurrency?.length > 6 && trunc) {
    formattedCurrency = formattedCurrency.slice(0,6)+"..";
  }

  return <>{formattedCurrency}</>;
};

export default CurrenyFormat;
