import {
  Box,
  CircularProgress,
  Backdrop,
  Link,
  Typography,
  Button,
  TextField,
  Modal,
  useMediaQuery,
  IconButton,
  TextareaAutosize,
  FormControl,
  styled,
  Divider,
} from "@mui/material";
import moment from "moment";
import { useState, useEffect } from "react";
import ProfilePic from "../../assets/images/dummy-profile-pic.jpeg";
import ExtendedOrderDetail from "./Extendorderdetail";
import { EquipmentAPIs } from "../../service/api-service";
import { setalertMessage, setalertMessagetype } from "redux/redux-slice";
import { useDispatch, useSelector } from "react-redux";
import { IStoreValue } from "utils/types";

const NewCssTextField = styled(TextField)({
  "& .MuiInput-underline:after": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiInput-underline:before": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiFilledInput-underline:after": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiFilledInput-underline:before": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },

  "& .MuiInputLabel-shrink.MuiInputLabel-filled": {
    color: "#2d2d2d",
  },
  "& .MuiInputBase-root": {
    color: "#2d2d2d",
    background: "#fff",
    fontFamily: "Gotham !important",
    fontSize: "clamp(12px, 3vw, 15px) !important",
    borderRadius: "10px !important",
    // border: "2px solid #faa61a",
  },
  "&. MuiInputLabel-shrink": {
    color: "red !important",
  },

  "&. MuiInputLabel-shrink.Mui-focused": {
    color: "red !important",
  },

  "& label.Mui-focused": {
    color: "#2d2d2d !important",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "2px solid #faa61a",
      backgroundColor: "#fff !important",
    },
    "&:hover fieldset": {
      borderColor: "#2d2d2d",
      border: "2px solid #faa61a",
      backgroundColor: "#fff !important",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#faa61a",
    },
  },
  "& .MuiInputBase-root-MuiFilledInput-root:before": {
    borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
  },
});

const loginstyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
  p: 4,
};

function OrderDetailComponent({ orderObject, onSuccess }) {
  const dispatch = useDispatch();

  const AuthToken = useSelector(
    (state: IStoreValue) => state.equipmentReducer.accessToken
  );

  const AuthUserId = useSelector(
    (state: IStoreValue) => state.equipmentReducer.Authuser
  );

  const [OrderDetailPopData, setOrderDetailPopData] = useState({
    thumbnail: "",
    id: "",
    make: "",
    model: "",
    start_date: "",
    start_time: "",
    end_date: "",
    end_time: "",
    profile_pic: "",
    first_name: "",
    order_status: "",
    order_from: "",
    rental_price: 0,
    minimum_delivery_fees: 0,
    security_deposit_refund_txt: "",
    security_deposit: 0,
    grand_total_txt: "",
    grand_total: 0,
    protection_fee: 0,
    transport_fee: 0,
    admin_fee: 0,
    timeZone: "UTC",
    side: "owner",
  });
  const [ActiveOrder, setActiveOrder] = useState([]);
  const [UserInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [mainOrderCancelPopup, setMainOrderCancelPopup] =
    useState<boolean>(false);
  const [cancellationReason, setCancellationReason] = useState<string>("");
  const [equipOrderId, setEquipOrderId] = useState<string>("");
  
  const getEquipementDetails = async (orderObj) => {
    try {
      setLoading(true);
      let EquipData = {};
      if (orderObj["equip_id"]) {
        const response = await EquipmentAPIs.viewEquipment({
          id: orderObj["equip_id"],
        });
        if (response.data.rowCount && response.data.rows?.[0]?.[0]) {
          EquipData = { ...response.data.rows[0][0] };
        }
      } else {
        EquipData = { ...orderObj };
      }
      // Extract extended orders and set active order list
      const hasKey = "order_extend" in orderObj;
      if (hasKey) {
        const modifiendExtendedOrder = orderObj["order_extend"]?.map(
          (order: any) => {
            order.timeZone = orderObj["timeZone"];
            order.first_name = orderObj["first_name"];
            order.profile_pic = orderObj["profile_pic"];
            // order.order_status = orderObj["order_status"];
            return order;
          }
        );
        setActiveOrder(modifiendExtendedOrder);
      }

      // Prepare user information for display
      let user_info = {
        profile_pic: orderObj["profile_pic"]
          ? orderObj["profile_pic"]
          : ProfilePic,
        first_name: orderObj["first_name"] ? orderObj["first_name"] : "NA",
      };
      setUserInfo(user_info);

      // Calculate refunded protection, security deposit, protection fee, transport fee, admin fee, and grand total
      let refunded_protection =
        orderObj["refunded_protection"] != undefined ||
        orderObj["refunded_protection"] != ""
          ? orderObj["refunded_protection"]
          : "";

      let security =
        orderObj["returnd_deposit"] != undefined ||
        orderObj["returnd_deposit"] != null
          ? orderObj["security_deposit"] - orderObj["returnd_deposit"]
          : orderObj["security_deposit"];
      let protection_fee =
        orderObj["protection_fees_refunded"] == "1"
          ? orderObj["equipment_protection_fees"] -
            (refunded_protection == ""
              ? orderObj["equipment_protection_fees"]
              : refunded_protection)
          : orderObj["equipment_protection_fees"];
      let transaport =
        orderObj["delivery_charges"] * 2 + orderObj["minimum_delivery_fees"];

      let adminfee =
        orderObj["admin_commission"] +
        (orderObj["minimum_delivery_fees"] != undefined &&
          orderObj["minimum_delivery_fees"] != null &&
          orderObj["minimum_delivery_fees"] != "0" &&
          orderObj["delivery_address"] != "" &&
          parseFloat(orderObj["admin_transport_commission"]));

      let grandTotal =
        orderObj["protection_fees_refunded"] != "1"
          ? orderObj["admin_commission"] != null
            ? orderObj["total_price"]
            : orderObj["total_price"]
          : orderObj["admin_commission"] != null
          ? orderObj["total_price"] -
            (orderObj["order_status"] == "order_canceled"
              ? orderObj["security_deposit"]
              : 0)
          : orderObj["total_price"];

      // Set data for order detail popup
      setOrderDetailPopData({
        thumbnail: EquipData["thumbnail"],
        id: "Order ID #" + orderObj["order_id"],
        make: EquipData["make"] ? EquipData["make"] : "NA",
        model: EquipData["model"] ? EquipData["model"] : "",
        start_date: orderObj["start_date"],
        start_time: orderObj["start_time"],
        end_date: orderObj["end_date"],
        end_time: orderObj["end_time"],
        profile_pic: orderObj["profile_pic"]
          ? orderObj["profile_pic"]
          : ProfilePic,
        first_name: orderObj["first_name"] ? orderObj["first_name"] : "NA",
        order_status: orderObj["order_status"],
        order_from: orderObj["delivery_address"] == "" ? "Renter" : "Owner",
        rental_price: orderObj["price"],
        minimum_delivery_fees: orderObj["minimum_delivery_fees"],
        security_deposit_refund_txt:
          orderObj["returnd_deposit"] != undefined ||
          orderObj["returnd_deposit"] != null
            ? "(Refunded" +
              "-$" +
              new Intl.NumberFormat("en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              }).format(orderObj["returnd_deposit"]) +
              ")"
            : "",
        security_deposit: security,
        grand_total_txt:
          orderObj["order_status"] == "order_canceled" ? "(Refunded)" : "",
        grand_total: grandTotal,
        protection_fee: protection_fee,
        transport_fee: transaport,
        admin_fee: adminfee,
        side: "owner",
        timeZone: orderObj["timeZone"],
      });
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleCancellationReason = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCancellationReason(event.target.value);
  };

  const cancelMainOrderByOwner = async () => {
    try {
      if (!cancellationReason) {
        dispatch(setalertMessage("Please add reason for cancellation."));
        dispatch(setalertMessagetype("error"));
        setLoading(false);
        return false;
      }
      setLoading(true);

      let data = {
        order_id: equipOrderId,
        owner_id: AuthUserId?.toString(),
        reason: cancellationReason,
      };
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const Result = await EquipmentAPIs.CancelOrderByOwner(data, headers);

      if (Result["type"] == "RXSUCCESS") {
        setCancellationReason("");
        dispatch(setalertMessage("Order Cancel Successfully."));
        dispatch(setalertMessagetype("success"));
        setLoading(false);
        setMainOrderCancelPopup(false);
        setOrderDetailPopData((prev) => ({
          ...prev,
          order_status: "order_canceled",
        }));
        const modifiedOrders = ActiveOrder.map((order) => ({
          ...order,
          order_status: "order_canceled",
        }));
        if (JSON.stringify(modifiedOrders) !== JSON.stringify(ActiveOrder)) {
          setActiveOrder(modifiedOrders);
        }
        onSuccess({ orderType: "main", orderId: equipOrderId });
      } else {
        dispatch(setalertMessage(Result["message"]));
        dispatch(setalertMessagetype("error"));
        setLoading(false);
        return false;
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (orderObject.length) {
      const orderObj = orderObject[0];
      getEquipementDetails(orderObj);
    }
  }, [orderObject]);

  return (
    <>
      {loading && (
        <Box>
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer - 1,
            }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Box>
      )}
      <Box
        // className="vehicle-div"
        sx={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          gap: "1rem",
          mb: 2,
        }}
      >
        {/* <Box 
        className="vehicle-img"
        > */}
        <Box
          component={"img"}
          src={OrderDetailPopData["thumbnail"]}
          alt=""
          sx={{
            width: {
              xs: "120px",
              sm: "140px",
              md: "160px",
              lg: "160px",
              xl: "180px",
            },
            height: {
              xs: "80px",
              sm: "90px",
              md: "100px",
              lg: "100px",
              xl: "120px",
            },
            border: "3px solid white",
            display: "block",
            objectFit: "contain",
            borderRadius: "0px !important",
          }}
        />
        {/* </Box> */}
        <Box sx={{ fontWeight: "bold" }}>
          <Typography
            title={
              OrderDetailPopData.make
                ? OrderDetailPopData.make
                : "Not Available"
            }
            sx={{
              display: "-webkit-box",
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 1,
              color: "#faa61a",
              fontFamily: "Montserrat !important",
              fontWeight: "700",
              fontSize: {
                xs: "14px",
                sm: "14px",
                md: "16px",
                lg: "20px",
                xl: "20px",
              },
              textTransform: "capitalize",
            }}
          >
            {OrderDetailPopData.make
              ? OrderDetailPopData.make
              : "Not Available"}
          </Typography>
          {/* <Typography style={{ color: "#faa61a" }}>
            {OrderDetailPopData.model
              ? OrderDetailPopData.model
              : "Not Available"}
          </Typography> */}
          <Typography
            sx={{
              color: "#fff",
              fontFamily: "Montserrat !important",
              fontSize: {
                xs: "12px",
                sm: "12px",
                md: "14px",
                lg: "14px",
                xl: "14px",
              },
            }}
          >
            {OrderDetailPopData.id}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography
          sx={{
            color: "#fff",
            fontFamily: "Montserrat !important",
            fontWeight: "500",
          }}
        >
          Rental Dates
        </Typography>
        <Box
          // className="owner-div owner-div-new edit-eq-owner"
          sx={{
            // display:'grid',
            // gridTemplateColumns:'repeat(2, 1fr)',
            display: "flex",
            // alignItems: "flex-end",
            // justifyContent: "space-between",
            gap: "0.5rem",
            alignItems: "center",
          }}
        >
          <Box
            // className="owner-edit-dates"
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "2px",
            }}
          >
            <Typography
              // className="modal-dates-grid"
              sx={{
                color: "#fff",
                fontFamily: "Montserrat !important",

                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <Typography
                component={"span"}
                sx={{
                  color: "#fff",
                  fontFamily: "Montserrat !important",
                  fontSize: {
                    xs: "12px",
                    sm: "13px",
                    md: "13px",
                    lg: "14px",
                    xl: "14px",
                  },
                }}
              >
                Start:
              </Typography>
              <Typography
                component={"span"}
                sx={{
                  color: "#fff",
                  fontFamily: "Montserrat !important",
                  fontSize: {
                    xs: "12px",
                    sm: "13px",
                    md: "13px",
                    lg: "14px",
                    xl: "14px",
                  },
                }}
              >
                {moment(OrderDetailPopData.start_date, ["YYYY-MM-DD"]).format(
                  "MM/DD/YY"
                )}{" "}
                {moment(OrderDetailPopData.start_time, ["HH:mm:ii"]).format(
                  "hh:mm a"
                )}
                &nbsp;(
                {OrderDetailPopData["timeZone"]
                  ? OrderDetailPopData["timeZone"]
                  : "UTC"}
                )
              </Typography>
            </Typography>
            <Typography
              // className="modal-dates-grid"
              sx={{
                color: "#fff",
                fontFamily: "Montserrat !important",

                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <Typography
                component={"span"}
                sx={{
                  color: "#fff",
                  fontFamily: "Montserrat !important",
                  fontSize: {
                    xs: "12px",
                    sm: "13px",
                    md: "13px",
                    lg: "14px",
                    xl: "14px",
                  },
                }}
              >
                End:
              </Typography>
              <Typography
                component={"span"}
                sx={{
                  color: "#fff",
                  fontFamily: "Montserrat !important",
                  fontSize: {
                    xs: "12px",
                    sm: "13px",
                    md: "13px",
                    lg: "14px",
                    xl: "14px",
                  },
                }}
              >
                {moment(OrderDetailPopData.end_date, ["YYYY-MM-DD"]).format(
                  "MM/DD/YY"
                )}{" "}
                {moment(OrderDetailPopData.end_time, ["HH:mm:ii"]).format(
                  "hh:mm a"
                )}
                &nbsp;(
                {OrderDetailPopData["timeZone"]
                  ? OrderDetailPopData["timeZone"]
                  : "UTC"}
                )
              </Typography>
            </Typography>
          </Box>
          <Box
            // className="owner-img-grid"
            sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
          >
            <Box
              component={"img"}
              src={OrderDetailPopData.profile_pic}
              alt=""
              sx={{
                width: {
                  xs: "38px",
                  sm: "40px",
                  md: "45px",
                  lg: "45px",
                  xl: "45px",
                },
                height: {
                  xs: "38px",
                  sm: "40px",
                  md: "45px",
                  lg: "45px",
                  xl: "45px",
                },
                border: "2px solid white",
                display: "block",
                objectFit: "cover",
                borderRadius: "50%",
              }}
            />

            <Box
              // className="ro-name"
              sx={{ display: "flex", flexDirection: "column", gap: "0px" }}
            >
              <Typography
                sx={{
                  color: "#fff",
                  fontFamily: "Montserrat !important",
                  fontSize: {
                    xs: "13px",
                    sm: "13px",
                    md: "14px",
                    lg: "14px",
                    xl: "16px",
                  },
                }}
              >
                RENTER:
              </Typography>
              <Typography
                sx={{
                  color: "#faa61a",
                  fontFamily: "Montserrat !important",
                  fontSize: {
                    xs: "12px",
                    sm: "12px",
                    md: "14px",
                    lg: "14px",
                    xl: "16px",
                  },
                }}
              >
                {OrderDetailPopData.first_name.charAt(0).toUpperCase() +
                  OrderDetailPopData.first_name.slice(1)}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Divider
        sx={{
          borderColor: "#faa61a",
          borderWidth: "1.2px",
          mt: 1.5,
          mb: 1.5,
        }}
      />
      <Box className="equipment-rate-div">
        <Box
          // className="equipment-rate-inner"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            mt: 2,
            mb: 2,
          }}
        >
          <Box
            // className="rate-list-grid"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                color: "#fff",
                fontFamily: "Montserrat !important",
                fontSize: {
                  xs: "12px",
                  sm: "13px",
                  md: "13px",
                  lg: "14px",
                  xl: "14px",
                },
              }}
            >
              Status :{" "}
              <span
                style={{
                  color: "#faa61a",
                  textTransform: "uppercase",
                }}
              >
                {OrderDetailPopData.order_status == "completed"
                  ? "Complete"
                  : OrderDetailPopData.order_status == "order_canceled"
                  ? "Cancelled"
                  : OrderDetailPopData.order_status == "payment_complete" ||
                    OrderDetailPopData.order_status == "security_settled" ||
                    OrderDetailPopData.order_status == "dispute" ||
                    OrderDetailPopData.order_status == "dispute_settled"
                  ? "Pending"
                  : "Active"}
              </span>
            </Typography>
          </Box>
          <Box
            // className="rate-list-grid"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                color: "#fff",
                fontFamily: "Montserrat !important",
                fontSize: {
                  xs: "12px",
                  sm: "13px",
                  md: "13px",
                  lg: "14px",
                  xl: "14px",
                },
              }}
            >
              Transport : {OrderDetailPopData.order_from}
            </Typography>
          </Box>
          <Box
            // className="rate-list-grid"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                color: "#fff",
                fontFamily: "Montserrat !important",
                fontSize: {
                  xs: "12px",
                  sm: "13px",
                  md: "13px",
                  lg: "14px",
                  xl: "14px",
                },
              }}
            >
              Rental Price
            </Typography>

            <Typography
              sx={{
                color: "#fff",
                fontFamily: "Montserrat !important",
                fontSize: {
                  xs: "12px",
                  sm: "13px",
                  md: "13px",
                  lg: "14px",
                  xl: "14px",
                },
              }}
            >
              $
              {new Intl.NumberFormat("en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              }).format(OrderDetailPopData.rental_price)}
            </Typography>
          </Box>
          <Box
            // className="rate-list-grid"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                color: "#fff",
                fontFamily: "Montserrat !important",
                fontSize: {
                  xs: "12px",
                  sm: "13px",
                  md: "13px",
                  lg: "14px",
                  xl: "14px",
                },
              }}
            >
              Security Deposit {OrderDetailPopData.security_deposit_refund_txt}
            </Typography>

            <Typography
              sx={{
                color: "#fff",
                fontFamily: "Montserrat !important",
                fontSize: {
                  xs: "12px",
                  sm: "13px",
                  md: "13px",
                  lg: "14px",
                  xl: "14px",
                },
              }}
            >
              $
              {new Intl.NumberFormat("en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              }).format(OrderDetailPopData.security_deposit)}
            </Typography>
          </Box>

          <Box
            // className="rate-list-grid"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {OrderDetailPopData.order_from != "Renter" && (
              <>
                <Typography
                  sx={{
                    color: "#fff",
                    fontFamily: "Montserrat !important",
                    fontSize: {
                      xs: "12px",
                      sm: "13px",
                      md: "13px",
                      lg: "14px",
                      xl: "14px",
                    },
                  }}
                >
                  Transport Charges
                </Typography>

                <Typography
                  sx={{
                    color: "#fff",
                    fontFamily: "Montserrat !important",
                    fontSize: {
                      xs: "12px",
                      sm: "13px",
                      md: "13px",
                      lg: "14px",
                      xl: "14px",
                    },
                  }}
                >
                  $
                  {new Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }).format(OrderDetailPopData.transport_fee)}
                </Typography>
              </>
            )}
          </Box>
          <Box
            // className="rate-list-grid"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                color: "#fff",
                fontFamily: "Montserrat !important",
                fontSize: {
                  xs: "12px",
                  sm: "13px",
                  md: "13px",
                  lg: "14px",
                  xl: "14px",
                },
              }}
            >
              Admin Fee
            </Typography>

            <Typography
              sx={{
                color: "#fff",
                fontFamily: "Montserrat !important",
                fontSize: {
                  xs: "12px",
                  sm: "13px",
                  md: "13px",
                  lg: "14px",
                  xl: "14px",
                },
              }}
            >
              -$
              {new Intl.NumberFormat("en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              }).format(OrderDetailPopData.admin_fee)}
            </Typography>
          </Box>
          {OrderDetailPopData.order_status == "order_canceled" && (
            <Box
              // className="rate-list-grid"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography sx={{ color: "#faa61a" }}>
                Order has been Cancelled
              </Typography>
            </Box>
          )}

          {/* <Divider
            sx={{
              borderColor: "#faa61a",
              borderWidth: "1.2px",
              mt: 1.5,
              mb: 1.5,
            }}
          /> */}

          <Box
            // className="rate-list-grid g-total"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            <Typography
              sx={{
                color: "#fff",
                fontFamily: "Montserrat !important",
                fontSize: {
                  xs: "12px",
                  sm: "13px",
                  md: "13px",
                  lg: "14px",
                  xl: "14px",
                },
                fontWeight: "800",
              }}
            >
              Total {OrderDetailPopData.grand_total_txt}
            </Typography>

            <Typography
              sx={{
                color: "#fff",
                fontSize: {
                  xs: "14px",
                  sm: "14px",
                  md: "15px",
                  lg: "16px",
                  xl: "16px",
                },
                fontWeight: "800",
                fontFamily: "Montserrat !important",
              }}
            >
              $
              {new Intl.NumberFormat("en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              }).format(OrderDetailPopData.grand_total)}
            </Typography>
          </Box>
          {OrderDetailPopData.order_status == "payment_complete" && (
            <Box
              className="rate-list-btn"
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  color: "#c21414",
                  cursor: "pointer",
                  textDecorationLine: "underline",
                  textDecorationColor: "#c21414",
                  // padding: "2px 8px",
                  borderRadius: "5px",
                  fontFamily: "Montserrat !important",
                  fontWeight: "700",
                }}
                onClick={() => {
                  setEquipOrderId(orderObject[0]?.id?.toString());
                  setMainOrderCancelPopup(true);
                }}
              >
                X&nbsp;Cancel Order
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      {ActiveOrder.length > 0 && (
        <ExtendedOrderDetail
          ExtendedOrder={ActiveOrder}
          UserInfo={UserInfo}
          Success={(data) => {
            if (data) {
            }
          }}
        />
      )}

      <Modal
        keepMounted
        open={mainOrderCancelPopup}
        onClose={() => setMainOrderCancelPopup(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popups"
          sx={{
            ...loginstyle,
            padding: "0px",
          }}
        >
          <Box className="login__main">
            <Box
              className="login__main-div edit-eqp"
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                className="login__main-div__form popup-cmmn popup-white edit-eqp-inner"
                style={{ border: "5px solid #faa61a", borderRadius: "10px" }}
              >
                <Typography
                  variant="h4"
                  style={{ color: "#faa61a", textAlign: "center" }}
                >
                  Cancel
                </Typography>

                <Box>
                  {loading && (
                    <Box>
                      <Backdrop
                        sx={{
                          color: "#000",
                          zIndex: (theme) => theme.zIndex.drawer - 1,
                        }}
                        open={loading}
                      >
                        <CircularProgress color="inherit" />
                      </Backdrop>
                    </Box>
                  )}
                </Box>

                <Box>
                  <FormControl
                    fullWidth
                    className="form-fields"
                    sx={{ width: "100%" }}
                  >
                    <NewCssTextField
                      id="outlined-multiline-static"
                      multiline
                      placeholder={"Cancellation reason"}
                      rows={2}
                      fullWidth
                      variant="filled"
                      onChange={handleCancellationReason}
                      value={cancellationReason}
                      className="popup-input"
                    />
                  </FormControl>
                </Box>

                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  <Button
                    className="signin-btn"
                    type="button"
                    fullWidth
                    variant="contained"
                    sx={{ backgroundColor: "#139246" }}
                    onClick={cancelMainOrderByOwner}
                  >
                    Ok
                  </Button>
                  <Button
                    className="signin-btn"
                    type="button"
                    fullWidth
                    variant="contained"
                    sx={{ backgroundColor: "#faa61a !important" }}
                    onClick={() => {
                      setMainOrderCancelPopup(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default OrderDetailComponent;
