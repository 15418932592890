import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  TextField,
  Button,
  Typography,
  styled,
  Backdrop,
  InputAdornment,
  IconButton,
  FormControl,
  Modal,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";
import { EquipmentAPIs } from "../../service/api-service";
import { IApiResponseRawData } from "utils/types";
import SocialLogin from "./SocialLogin";
import {
  setLoginStatus,
  setLoginModalOpen,
  setLogInLoading,
  setalertMessagetype,
  setalertMessage,
  setaccessToken,
  SetRedirectPage,
  setAuthuser,
  setEmailVerify,
} from "redux/redux-slice";
import { IStoreValue } from "utils/types";
import SocialEmailVerifyModal from "./socialEmailVerificationModal";

const CssTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    color: "#2e2e2e",
    background: "#fff",
    fontFamily: "GothamBold !important",
    fontSize: "clamp(10px, 3vw, 12px) !important",
    borderRadius: " 8px !important",
    // border: "2px solid #faa61a",
  },
  "& label.Mui-focused": {
    color: "#faa61a",
    fontWeight: "bold",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#2e2e2e",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "2px solid #faa61a",
    },
    "&:hover fieldset": {
      borderColor: "#2e2e2e",
      border: "2px solid #faa61a",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#faa61a",
    },
  },
});

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: "7px",
    "& fieldset": {
      border: "2px solid #faa61a",
      borderRadius: "7px",
    },
    "&:hover fieldset": {
      borderColor: "#faa61a",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#faa61a",
    },
  },
  "& .MuiInputBase-input": {
    color: "black",
    backgroundColor: "#fff",
    fontFamily: "Montserrat !important",
    fontSize: "14px",
    fontWeight: "500",
    borderRadius: "7px",
  },
});
const mailformat =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const UserLogin = (IProps) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = React.useState(false);

  const isLoggedin = useSelector(
    (state: IStoreValue) => state.equipmentReducer.isLogin
  );
  const loading = useSelector(
    (state: IStoreValue) => state.equipmentReducer.LogInLoading
  );
  const RedirectPage = useSelector(
    (state: IStoreValue) => state.equipmentReducer.RedirectPage
  );

  const [socialLoginData, setSocialLoginData] = useState({});
  const [emailVerifyModelOpen, setEmailVerifyModelOpen] = useState(false);

  const [resetPasswordModal, setResetPasswordModal] = useState<boolean>(false);

  const handelResetPasswordModalOpen = () => setResetPasswordModal(true);
  const handelResetPasswordModalClose = () => setResetPasswordModal(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const [resetEmail, setResetEmail] = useState<string>("");
  const [resetPasswordLoading, setResetPasswordLoading] =
    useState<boolean>(false);

  const handleResetPasswordApi = async() => {
    try {
      const email = resetEmail?.trim();
      if (!email || !mailformat.test(email)) {
        throw new Error("Please enter valid email address!");
      }
      setResetPasswordLoading(true);
      const response = await EquipmentAPIs.ForgotPassword({ email });
      if(response["type"] === "RXSUCCESS") {
        dispatch(setalertMessage(response.message));
        dispatch(setalertMessagetype("success"));
        handelResetPasswordModalClose();
      } else {
        throw new Error(response?.message)
      }
    } catch (error) {
      dispatch(setalertMessage(error.message));
      dispatch(setalertMessagetype("error"));
    } finally {
      setResetPasswordLoading(false);
    }
  };

  const handleResetEmailChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setResetEmail(value);
  };
  const handleResetEmailKeyUp = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      handleResetPasswordApi();
    }
  };

  // Handle User Login with email and password
  const login = async (e) => {
    e.preventDefault();
    try {
      dispatch(setLogInLoading(true));
      if (email == "") {
        dispatch(setalertMessage("Please fill email address!"));
        dispatch(setalertMessagetype("error"));
        dispatch(setLogInLoading(false));
        dispatch(setLoginStatus(false));
        return false;
      }

      if (password == "") {
        dispatch(setalertMessage("Please fill password!"));
        dispatch(setalertMessagetype("error"));
        dispatch(setLogInLoading(false));
        dispatch(setLoginStatus(false));
        return false;
      }

      if (!email.match(mailformat)) {
        dispatch(setalertMessage("Invalid email address!"));
        dispatch(setalertMessagetype("error"));
        dispatch(setLogInLoading(false));
        dispatch(setLoginStatus(false));
        return false;
      } else if (!password || password == "") {
        dispatch(setalertMessage("Invalid password!"));
        dispatch(setalertMessagetype("error"));
        dispatch(setLogInLoading(false));
        dispatch(setLoginStatus(false));
        return false;
      } else {
        let device_token = localStorage.getItem("firebaseToken");
        const Auth_login: IApiResponseRawData = await EquipmentAPIs.userLogin({
          email: email,
          password: password,
          device_token: device_token,
          new_signup: true,
        });
        //is_profile_completed
        if (Auth_login["status"] == 200) {
          dispatch(setLogInLoading(false));
          let auth_user = Auth_login["data"];
          localStorage.setItem("token-info", JSON.stringify(auth_user));
          dispatch(setaccessToken(Auth_login["data"]["auth_token"]));
          dispatch(setAuthuser(Auth_login["data"]["user_id"]));
          dispatch(setEmailVerify(Auth_login["data"]["is_user_verify"])); // save user email verification status

          setEmail("");
          setPassword("");
          dispatch(setLoginStatus(true));
          dispatch(setLoginModalOpen(false));

          var redirect = "";
          if (
            Auth_login["data"]["is_user_verify"] === "0" ||
            parseInt(Auth_login["data"]["is_profile_completed"]) === 1
          ) {
            redirect = "home";
            //   if (Auth_login["data"]["is_profile_completed"] == "1" || Auth_login["data"]["is_profile_completed"] == 1) {
            //     redirect = 'home';
            //   } else {
            //     redirect = 'signup';
            //   }
          } else {
            redirect = "signup";
          }

          if (redirect == "signup") {
            window.location.href = "/signup";
            return false;
          }

          //dispatch(setalertMessage("Welcome to GoEquipMe. Login successfully."));
          dispatch(setalertMessage(Auth_login["message"]));
          dispatch(setalertMessagetype("success"));

          let RedirectLink = localStorage.getItem("RedirectLink");
          const currentUrl = window.location.href;
          if (currentUrl.includes("detail/")) {
            window.location.reload();
            return;
          }

          if (RedirectPage) {
            // by GD 21 Feb 2024 added if (RedirectPage == 'notifications')
            if (RedirectPage == "notifications") {
              window.location.href = "/notifications";
              return;
            }
            // window.open(
            //     RedirectPage,
            //     '_blank'
            // );
            // by GD 15 march 2024 commented window.open and added next line for redirection
            window.location.href = RedirectPage;
            SetRedirectPage("");
          } else if (RedirectLink) {
            localStorage.removeItem("RedirectLink");
            // window.open(RedirectLink);
            // by GD 15 march 2024 commented window.open and added next line for redirection
            if (
              parseInt(Auth_login["data"]["is_profile_completed"]) === 0 &&
              RedirectLink.includes("listequipment")
            ) {
              window.location.href = "/";
              return false;
            }
            window.location.href = RedirectLink;
          } else {
            window.location.href = "/";
          }
        } else {
          dispatch(setLogInLoading(false));
          dispatch(setLoginStatus(false));
          dispatch(setalertMessage(Auth_login["message"]));
          dispatch(setalertMessagetype("error"));
        }
      }
    } catch (err) {
      console.log(err);
      dispatch(setLogInLoading(false));
      setEmail("");
      setPassword("");
      dispatch(setLoginStatus(false));
    }
  };

  // Handle Social Login
  const socialLogin = (result) => {
    dispatch(setLogInLoading(false));
    console.log("result in login: ", result);
    if (result["is_user_verify"] == "1") {
      console.log("in slog if: ");
      setSocialLoginData(result);
      setEmailVerifyModelOpen(true);
      return;
    }
    // return
    localStorage.setItem("token-info", JSON.stringify(result));
    dispatch(setaccessToken(result["auth_token"]));
    dispatch(setAuthuser(result["user_id"]));

    if (
      result["is_profile_completed"] == "0" ||
      result["is_profile_completed"] == 0
    ) {
      window.location.href = "/signup";
    }

    dispatch(setLoginStatus(true));
    dispatch(setLoginModalOpen(false));
    //window.location.href = "/";
    dispatch(setalertMessage("Welcome to GoEquipMe. Login successfully."));
    dispatch(setalertMessagetype("success"));

    let RedirectLink = localStorage.getItem("RedirectLink");
    const currentUrl = window.location.href;
    if (currentUrl.includes("detail/")) {
      window.location.reload();
      return;
    }
    if (RedirectPage) {
      // by GD 21 Feb 2024 added if (RedirectPage == 'notifications')
      if (RedirectPage == "notifications") {
        window.location.href = "/notifications";
        return;
      }
      // window.open(
      //     RedirectPage,
      //     '_blank'
      // );
      // by GD 15 march 2024 commented window.open and added next line for redirection
      window.location.href = RedirectPage;
      SetRedirectPage("");
    } else if (RedirectLink) {
      localStorage.removeItem("RedirectLink");
      // window.open(RedirectLink);
      // by GD 15 march 2024 commented window.open and added next line for redirection
      window.location.href = RedirectLink;
    } else {
      window.location.href = "/";
    }
  };

  const socialLoginStatus = (loadingStatus) => {
    // if (loadingStatus == 'true') {
    //     dispatch(setLogInLoading(true));
    // } else {
    //     dispatch(setLogInLoading(false));
    // }
  };

  // Handle signup Redirect to signup page
  const HandleSignup = () => {
    window.location.href = "/signup";
    return;
  };

  return (
    <>
      <Box className="login__main">
        {/* new login */}
        {loading && (
          <Box>
            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={loading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </Box>
        )}

        <Box className="login-main-div">
          <Box className="login-main-inner">
            <Typography
              variant="h2"
              className="welcome-heading"
              sx={{ textAlign: "center", color: "#fff" }}
            >
              Welcome!
            </Typography>

            <Box className="login-with-social">
              <SocialLogin
                onSucess={socialLogin}
                onLoading={socialLoginStatus}
              />
            </Box>

            <Box className="login-fields-div">
              <Typography sx={{ textAlign: "center", color: "#fff" }}>
                Login with your email address
              </Typography>

              <Box className="input-div">
                <CssTextField
                  id="login-email"
                  variant="outlined"
                  placeholder="Email Address"
                  sx={{ width: "100%" }}
                  onChange={(e) => setEmail(e.target.value.trim())}
                  value={email}
                  onKeyUp={(event) => {
                    if (event.key === "Enter") {
                      login(event);
                    }
                  }}
                />
              </Box>
              <Box className="input-div">
                <CssTextField
                  id="login-pswd"
                  variant="outlined"
                  // label="Password"
                  placeholder="Password"
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                          className="eye-icon"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{ width: "100%" }}
                  onChange={(e) => setPassword(e.target.value.trim())}
                  value={password}
                  onKeyUp={(event) => {
                    if (event.key === "Enter") {
                      login(event);
                    }
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  mb: 3,
                  mt: 1,
                  cursor: "pointer",
                  fontFamily: "GothamBold !important",
                }}
                onClick={handelResetPasswordModalOpen}
              >
                <Box sx={{color:"#fff"}}>Forgot Password?</Box>
              </Box>
              <Box className="signin-btn-div">
                <Button
                  className="signin-btn"
                  fullWidth
                  variant="contained"
                  sx={{
                    boxShadow: "none",
                    borderRadius: "15px",
                    backgroundColor: "#139245",
                    fontFamily: "GothamBold",
                    width: "70%",
                  }}
                  onClickCapture={login}
                >
                  Login
                </Button>
              </Box>
            </Box>

            <Box className="signin-signup">
              <Typography sx={{ textAlign: "center", color: "#fff" }}>
                Don't have an account?
              </Typography>
              <Box className="signup-btn-div">
                <Button
                  className="signup-btn"
                  fullWidth
                  variant="contained"
                  sx={{
                    boxShadow: "none",
                    borderRadius: "15px",
                    backgroundColor: "#139245",
                    fontFamily: "GothamBold",
                    width: "70%",
                  }}
                  onClickCapture={HandleSignup}
                >
                  Sign Up
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>

        {emailVerifyModelOpen && (
          <SocialEmailVerifyModal
            emailVerifyModelOpen={emailVerifyModelOpen}
            setEmailVerifyModelOpen={setEmailVerifyModelOpen}
            data={socialLoginData}
          />
        )}

        <Modal
          keepMounted
          open={resetPasswordModal}
          onClose={handelResetPasswordModalClose}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box
            sx={{
              position: "absolute" as "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: {
                xs: "80%",
                sm: "400px",
                md: "400px",
                lg: "400px",
                xl: "400px",
              },
              bgcolor: "#2e2e2e",
              border: "5px solid #faa61a",
              borderRadius: "10px",
              outline: "none !important",
              p: 3,
            }}
          >
            <Box>
              <Box>
                <IconButton
                  sx={{
                    position: "absolute",
                    top: "-20px",
                    right: "-20px",
                    bgcolor: "#faa61a",
                    color: "#000",
                    "&:hover": {
                      bgcolor: "#faa61a",
                    },
                  }}
                  onClick={handelResetPasswordModalClose}
                >
                  <CloseIcon />
                </IconButton>
                <Box>
                  <Box>
                    {resetPasswordLoading && (
                      <Box>
                        <Backdrop
                          sx={{
                            color: "#fff",
                            zIndex: (theme) => theme.zIndex.drawer + 1,
                          }}
                          open={resetPasswordLoading}
                        >
                          <CircularProgress color="inherit" />
                        </Backdrop>
                      </Box>
                    )}
                    <CustomTextField
                      id="outlined-disabled"
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        style: {
                          backgroundColor: "#fff",
                        },
                      }}
                      value={resetEmail}
                      onChange={handleResetEmailChange}
                      onKeyUp={handleResetEmailKeyUp}
                      placeholder="Email"
                    />
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "1rem",
                        mt: 3,
                        mb: 2,
                      }}
                    >
                      <Button
                        type="button"
                        variant="contained"
                        sx={{
                          width: "100%",
                          backgroundColor: "#faa61a !important",
                          fontFamily: "Montserrat !important",
                          fontWeight: "700",
                          fontSize: "16px",
                          borderRadius: "7px",
                        }}
                        onClick={handelResetPasswordModalClose}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="button"
                        variant="contained"
                        sx={{
                          width: "100%",
                          backgroundColor: "#139246",
                          fontFamily: "Montserrat !important",
                          fontWeight: "700",
                          fontSize: "16px",
                          borderRadius: "7px",
                          "&:hover": {
                            backgroundColor: "#0f853e",
                          },
                        }}
                        onClick={handleResetPasswordApi}
                      >
                        Submit
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>
      </Box>
    </>
  );
};

export default UserLogin;
