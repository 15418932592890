import React from "react";
import { Box } from "@mui/material";
import gemArrow from "assets/images/gem-arrow.png";

interface SeeMoreBtnProps {
  HandlePagination: () => void; // Correct type definition for the function prop
}

const SeeMoreBtn: React.FC<SeeMoreBtnProps> = ({
  HandlePagination,
}) => {
  return (
    <Box className="pagination-section">
      <Box
        className="btn-load-more"
        onClick={HandlePagination}
        role="button"
        tabIndex={0}
        // onKeyDown={(e) => {
        //   if (e.key === "Enter" || e.key === " ") HandleRentalYardPagination();
        // }}
      >
        {"See More"}
        <img
          src={gemArrow}
          alt="GoEquipMe"
          className="gem-arrow arrow-load-more"
        />
      </Box>
    </Box>
  );
};

export default SeeMoreBtn;
