import { Box, Button, Link } from '@mui/material';
import MainLayout from 'layouts/MainLayout';
import { useEffect, useState, useRef } from 'react';
import { useNavigate, Link as RLink } from 'react-router-dom';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { SetRedirectPage, setLogInLoading, setLoginModalOpen } from 'redux/redux-slice';
import { IStoreValue } from 'utils/types';

// components
import BuildCrewQuestions from 'components/howItWorks/GotQuestions/BuildCrewQuestions';
import Testimonials from 'components/howItWorks/Testimonials/Testimonials';
import BuildaCrew_Slider from 'components/howItWorks/Testimonials/BuildaCrew_Slider';
import BuildCrewTab from 'components/howItWorks/TabLikeSec/BuildCrewTab';

// images
import growImg from '../../../assets/images/howitworks/image_LetsGrowTogether.png';
import partTimeImg from '../../../assets/images/howitworks/image_PartTime.png';
import careerImg from '../../../assets/images/howitworks/image_Career.png';
import step1Img from '../../../assets/images/howitworks/image_Step1.png';
import step2Img from '../../../assets/images/howitworks/image_Step2.png';
import step3Img from '../../../assets/images/howitworks/image_Step3.png';
import arrowImg from '../../../assets/images/howitworks/arrow-horizontal.png';
import arrowVImg from '../../../assets/images/howitworks/arrow-vertical.png';

// styles
import './styles.scss';


const BuildCrew = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const UserID = useSelector((state: IStoreValue) => state.equipmentReducer.Authuser);

  const LoginPopup = () => {
    localStorage.setItem("RedirectLink", "/buildACrew");
    dispatch(setLoginModalOpen(true));
    dispatch(setLogInLoading(false));
  };

  const [step, setStep] = useState(1);
  const officeTrailreRef = useRef(null);
  useEffect(() => { window.scroll(0, 0) }, []);
  return (
    <MainLayout>
      <Box className='hw'>

        <Box className='bac-top-main'>
          <Box className='bac-top'>
            <Box className='bac-L'>
              <h1 className='bac-heading'>
                Build a <span>crew</span>
              </h1>

            </Box>
            <Box className='bac-R'>
              <Button className='bac-btn'
                onClick={() => officeTrailreRef.current.click()}>
                Get Started!
              </Button>
            </Box>
          </Box>
          <p className='bac-subheading'>
            Refer and watch your Wallet grow!
          </p>
        </Box>

        {/* Tab */}
        <BuildCrewTab />

        {/* Lets grow together */}
        <Box className='lgt'>
          <Box className='lgt-content'>
            <p className='lgt-heading'>
              Let’s Grow Together!
            </p>
            <p className='lgt-text'>
              We created an affiliate marketing program to
              further our partnership with you! Let’s grow
              GoEquipMe together… and earn together!
            </p>
          </Box>
          <Box className='lgt-img'>
            <img src={growImg} alt="" />
          </Box>
        </Box>

        {/* Tabs */}
        <Box className='mw'>
          <Box className='mw-head'>
            <p className='mw-heading'>
              So Many Ways to Earn...
            </p>
            <p className='mw-subheading'>
              Do you know someone <BuildaCrew_Slider />
            </p>
            <p className='mw-text'>
              Refer them to GoEquipMe and get paid!
            </p>
          </Box>
          <Box className='mw-grid'>
            <Box className='mw-gridL'>
              {/* {step === 1 && <img src={step1Img} alt="" />}
              {step === 2 && <img src={step2Img} alt="" />}
              {step === 3 && <img src={step3Img} alt="" />} */}

              <img src={step1Img} alt="" style={{ display: step === 1 ? 'block' : 'none' }} />
              <img src={step2Img} alt="" style={{ display: step === 2 ? 'block' : 'none' }} />
              <img src={step3Img} alt="" style={{ display: step === 3 ? 'block' : 'none' }} />
            </Box>
            <Box className='mw-gridR'>
              <Box className={`mw-item ${step === 1 && "active"}`} onMouseEnter={() => setStep(1)}>
                <p className='mw-item-heading'>
                  BECOME A CREW BOSS
                </p>
                <p className='mw-item-text'>
                  Create a user account and
                  activate your Crew Boss status
                  from your Owners.
                </p>
                <span>
                  Step 1
                </span>
              </Box>
              <Box className={`mw-item ${step === 2 && "active"}`} onMouseEnter={() => setStep(2)}>
                <p className='mw-item-heading'>
                  SHARE GoEquipMe
                </p>
                <p className='mw-item-text'>
                  Use your referral code and share
                  GoEquipMe to others.
                </p>
                <span>
                  Step 2
                </span>
              </Box>
              <Box className={`mw-item ${step === 3 && "active"}`} onMouseEnter={() => setStep(3)}>
                <p className='mw-item-heading'>
                  GET PAID
                </p>
                <p className='mw-item-text'>
                  As your crew grows, so does
                  your Wallet.
                </p>
                <span>
                  Step 3
                </span>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* Testimonials */}
        <Testimonials />

        {/* Make it part time */}
        <Box className='mipt'>
          <Box className='mipt-item'>
            <p className='mipt-item-heading'>
              MAKE IT PART-TIME
            </p>
            <p className='mipt-item-text'>
              Share at your leisure, enjoy some
              extra cash!
            </p>
            <Box className='mipt-item-img'>
              <img src={partTimeImg} alt="" />
            </Box>
          </Box>

          <Box className='arrow'>
            <img src={arrowImg} className='d-arrowimg' alt="" />
            <img src={arrowVImg} className='m-arrowimg' alt="" />
          </Box>

          <Box className='mipt-item'>
            <p className='mipt-item-heading'>
              MAKE IT A CAREER!
            </p>
            <p className='mipt-item-text'>
              Jump in with both feet and unlock
              6-figure earning potential!
            </p>
            <Box className='mipt-item-img'>
              <img src={careerImg} alt="" />
            </Box>
          </Box>
        </Box>

        {/* How to get started */}
        <Box className='htgs'>
          <Box className='htgs-in'>
            <p className='htgs-heading'>
              How to Get Started
            </p>
            <Box>
              <p className='htgs-text'>Create a &nbsp;free user account
                {/* {UserID
                  ?
                  <Link className='htgs-link'
                    // to="/signup"
                    href="/myofficetrailer"
                  >
                    free user account
                  </Link>
                  :
                  <Link className='htgs-link'
                    // to="/signup"
                    href="/signup"
                  >
                    free user account
                  </Link>
                } */}
                . Go to your&nbsp;Owners
                {UserID
                  ?
                  <Link className='htgs-link'
                    sx={{ cursor: 'pointer', display: 'none' }}
                    ref={officeTrailreRef}
                    href='/buildACrew'
                  // onClick={() => {
                  //   if (UserID) {
                  //     navigate("/myofficetrailer")
                  //   } else {
                  //     dispatch(SetRedirectPage("myofficetrailer"));
                  //     LoginPopup();
                  //   }
                  // }}
                  >Owners
                  </Link>
                  :
                  <Link className='htgs-link'
                    sx={{ cursor: 'pointer', display: 'none' }}
                    ref={officeTrailreRef}
                    onClick={() => {
                      if (UserID) {
                        // navigate("/myofficetrailer");
                      } else {
                        dispatch(SetRedirectPage("buildACrew"));
                        LoginPopup();
                      }
                    }}
                  >Owners
                  </Link>
                }.
                Click on your Crew tab for more information.</p>
            </Box>
            <Box className='htgs-btn-div'>
              <Button className='htgs-btn'
                onClick={() => officeTrailreRef.current.click()}
              >
                Let’s Go!
              </Button>
            </Box>
          </Box>
        </Box>

        {/* Got Questions */}
        <BuildCrewQuestions />
      </Box>
    </MainLayout>
  )
}

export default BuildCrew;
