import { Box, Button, Link, TextField, Grid } from "@mui/material";
import React, { useCallback, useState, useEffect } from "react";
import welcomeTo from "assets/images/welcome_txt.png";
import thankYou from "assets/images/thank-you.png";
import goLending from "assets/images/goequipme-lending.png";
import webLanding from "assets/images/weblanding-b.png";
import goDollar from "assets/images/go-dollar.png";
import Typography from "@mui/material/Typography";
import { Add } from "@mui/icons-material";
import EquipmentType from "./EquipmentType";
import validator from "validator";
import { ILoanEquipment } from "utils/types";
import { EquipmentAPIs } from "service/api-service";
import MainLayout from "../../layouts/MainLayout";
import "./styles.css";
import { S_IFREG } from "node:constants";
import { setalertMessage, setalertMessagetype } from "redux/redux-slice";
import { useDispatch } from "react-redux";

const searchInputProp = {
  disableUnderline: true,
  style: {
    border: "3px solid #faa61a",
    borderRadius: "3px",
    // background: '#e6e7e8',
    height: "45px",
  },
};

const inputLabelProp = {
  style: {
    color: "#7f8084",
  },
};

const formTitleSx = {
  alignItems: "center",
  display: "flex",
  marginLeft: "15px",
};

const Welcome = () => {
  const dispatch = useDispatch();
  const [step, setStep] = useState<number>(1);
  const [fullName, setFullName] = useState<string>("");
  const [companyName, setCompanyName] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [nameerror, setNameerror] = useState<string>("");
  const [phoneerror, setPhoneerror] = useState<string>("");
  const [emailerror, setEmailerror] = useState<string>("");

  const [equipments, updateEquipments] = useState<Array<ILoanEquipment>>([
    {
      id: 0,
      type: "",
      amount: 0,
      loanType: "",
    },
  ]);

  const [fullNameError, setFullNameError] = useState<boolean>(false);
  const [phoneError, setPhoneError] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  let btnNextDisableStatus = true;
  if (step === 1) {
    btnNextDisableStatus = false;
  } else if (step === 2) {
    btnNextDisableStatus = false;
    // btnNextDisableStatus = phoneError || emailError
  }

  const nextClick = useCallback(async () => {
    // if (step === 0 || !btnNextDisableStatus) {

    if (step === 1) {
      if (fullName.length == 0) {
        setFullNameError(fullName === "");
        setNameerror("Full name required!");
      } else {
        setNameerror("");
        setStep(step + 1);
      }

      // setFullNameError(fullName === '')
      // if (fullName != '') {
      //     setStep(step + 1)
      // }
    }
    if (step === 2) {
      let regs =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (phoneNumber == "") {
        setPhoneerror("Phone number requried");
        setPhoneError(true);
      }

      // if (emailAddress == '') {
      //   setEmailError(true)
      //   setEmailerror('Email address requried');
      // }

      if (phoneNumber.length < 12 && phoneNumber.length > 0) {
        setPhoneError(true);
        setPhoneerror("Invalid phone number");
      } else if (emailAddress == "") {
        setEmailError(true);
        setEmailerror("Email address requried");
      } else if (!regs.test(emailAddress)) {
        setEmailError(true);
        setEmailerror("Invalid Email address");
      } else {
        setEmailError(false);
        setPhoneError(false);
        setStep(step + 1);
      }
    }

    // btnNextDisableStatus = fullNameError
    // setStep(step + 1)
    // } else
    if (step === 3) {
      try {
        setLoading(true);
        const modifiedEquip = equipments.map((equip) => ({
          type: equip.type,
          loan_amount: equip.amount,
          loan_type: equip.loanType,
        }));
        const response = await EquipmentAPIs.postLoanEquipments({
          name: fullName,
          company: companyName,
          phone: phoneNumber,
          email: emailAddress,
          equipments: modifiedEquip,
        });
        if (response["type"] === "RXSUCCESS") {
          setStep(step + 1);
        } else {
          throw new Error(response["message"]);
        }
      } catch (exception) {
        console.log("Exception: ", exception);
        dispatch(setalertMessage(exception["message"]));
        dispatch(setalertMessagetype("error"));
      } finally {
        setLoading(false);
      }
    }
  }, [
    btnNextDisableStatus,
    companyName,
    emailAddress,
    equipments,
    fullName,
    phoneNumber,
    step,
  ]);

  const onFullNameChange = useCallback((e) => {
    let name = e.target.value;
    name = name.replace(/[^a-zA-Z ]/g, "");

    setNameerror("");
    setFullNameError(false);

    if (name != null) {
      // condition use for remove space starting of string
      if (name.length > 0) {
        name = name.trimStart();
      }
    }

    if (name.length > 13) {
      setNameerror("Max length should be 13 characters only.");
      setFullNameError(fullName === "");
    } else {
      setFullName(name);
    }

    //setFullName(name)
  }, []);
  const onCompanyNameChange = useCallback((e) => {
    setCompanyName(e.target.value);
  }, []);

  const onPhoneNumberChange = useCallback((e) => {
    let phone = e.target.value;
    phone = phone.replace(/[^0-9 ]/g, "");
    if (phone.length == 0) {
      setPhoneError(false);
      setPhoneerror("");
      setPhoneNumber("");
    }
    if (phone.length > 0) {
      setPhoneError(false);
      setPhoneerror("");
      phoneFormat(phone);
    }

    // setPhoneNumber(e.target.value)
  }, []);
  const onEmailChanged = useCallback((e) => {
    let mail = e.target.value;
    if (mail.length == 0 || mail.length > 0) {
      setEmailerror("");
      setEmailError(false);
    }

    setEmailAddress(e.target.value);
  }, []);

  const onAddEquipmentClick = useCallback(() => {
    if (equipments.length >= 3) {
      return false;
    }
    updateEquipments([
      ...equipments,
      {
        id: equipments.length,
        type: "",
        amount: 0,
        loanType: "",
      },
    ]);
  }, [equipments]);

  useEffect(() => {
    // setFullNameError(fullName === '')
    window.scrollTo(0, 0);
  }, [fullName]);

  // useEffect(() => {
  //   setEmailError(!validator.isEmail(emailAddress))
  // }, [emailAddress])

  // useEffect(() => {
  //   if ((phoneNumber < 1000000000) || (phoneNumber > 9999999999)) {
  //     setPhoneError(true)
  //   } else {
  //     setPhoneError(false)
  //   }
  // }, [phoneNumber])
  const phoneFormat = (phNumber) => {
    var lastChar = phNumber.substr(phNumber.length - 1);
    if (isNaN(lastChar)) {
      setPhoneError(true);
      return;
    } else {
      var match = phNumber.match(/(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        var number = [match[1], "-", match[2], "-", match[3]].join("");
        setPhoneNumber(number);
        setPhoneError(false);
        return;
      }
      setPhoneError(false);
      setPhoneNumber(phNumber);
    }
  };

  const HandleRedirectBackSale = (event) => {
    window.history.go(-1);
    return false;
  };

  return (
    <MainLayout insideMainContainer={false}>
      <Box
        component={"div"}
        className="layout-container h-full welcome-container"
      >
        {step < 3 && (
          <Box className="equip-lending-form">
            <Box
              component={"div"}
              className={
                step === 1 || step === 2
                  ? "step-one-container center-right mobile-hidden"
                  : "step-one-container"
              }
            >
              <Box>
                <img src={welcomeTo} alt="Welcome to" className="welcome-to" />
              </Box>
              <Box>
                <img
                  src={goLending}
                  alt="Go Equipment Lending"
                  className="go-lending"
                />
              </Box>
              <Typography id="evolution_text">
                THE EVOLUTION OF EQUIPMENT FINANCING
              </Typography>
              <Typography className="financing-text" id="financing_text">
                Our Loan Specialists will help YOU get equipment financing for:
              </Typography>
              <ul className="financing-list">
                <li>PURCHASES</li>
                <li>REFINANCES</li>
                <li>CASH-OUTS</li>
                <li>COMPETITIVE RATE</li>
              </ul>
              <Box className="start-btn-container">
                {step === 0 && (
                  <Button variant="outlined" id="btn_start" onClick={nextClick}>
                    <span>{"<"}</span>
                    <Typography>Get Started</Typography>
                    <span>{">"}</span>
                  </Button>
                )}
              </Box>
            </Box>
            {step !== 0 && (
              <Box className="welcome-form">
                <Box className="form-container">
                  {step === 1 && (
                    <Box className="form-container-inner">
                      <Box className="form-title-wrapper">
                        <Grid container spacing={0}>
                          <Grid
                            className="form-title-wrapper-img"
                            item
                            xs={2}
                            md={2}
                            sx={{ padding: "5px !important" }}
                          >
                            <img
                              src={goDollar}
                              alt="Dollar"
                              className="go-dollar"
                              style={{ width: "80%", height: "auto" }}
                            />
                          </Grid>
                          <Grid
                            className="form-title-wrapper-text"
                            item
                            xs={10}
                            md={10}
                            sx={{ display: "flex" }}
                          >
                            <Typography
                              variant="h3"
                              id="form_title"
                              sx={formTitleSx}
                              // sx={{ fontSize: { xs: '20px', sm: '20px', md: '22px', lg: '28px', xl: '32px' }, color: '#faa61a', fontWeight: '900', fontFamily: 'GothamBold !important' }}
                            >
                              Let's Get Started!
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box
                        className="step-bar-container"
                        sx={{
                          display: "grid !important",
                          gridTemplateColumns: "repeat(3, 1fr) !important",
                          gap: "1rem",
                        }}
                      >
                        <span
                          className="step-bar active"
                          style={{ width: "100% !important" }}
                        ></span>
                        <span
                          className="step-bar"
                          onClick={() => {
                            setStep(2);
                          }}
                          style={{ width: "100% !important" }}
                        ></span>
                        <span
                          className="step-bar"
                          style={{ width: "100% !important" }}
                        ></span>
                      </Box>
                      <Box className="form-input">
                        <TextField
                          label="Full Name *"
                          fullWidth
                          size="small"
                          InputProps={searchInputProp}
                          InputLabelProps={inputLabelProp}
                          variant="filled"
                          value={fullName}
                          onChange={onFullNameChange}
                          error={fullNameError}
                          //helperText={fullNameError ? 'Full name required!' : ''}
                          helperText={nameerror}
                        />
                      </Box>
                      <Box className="form-input">
                        <TextField
                          label="Company Name"
                          fullWidth
                          size="small"
                          InputProps={searchInputProp}
                          InputLabelProps={inputLabelProp}
                          variant="filled"
                          value={companyName}
                          onChange={onCompanyNameChange}
                        />
                      </Box>
                      <Box className="next-btn-container">
                        <Button
                          variant="outlined"
                          id="btn_start"
                          className={fullName == "" && "deactive"}
                          onClick={nextClick}
                        >
                          <Typography className="btn_start">Next</Typography>
                        </Button>
                      </Box>
                    </Box>
                  )}
                  {step === 2 && (
                    <Box className="form-container-inner">
                      <Box className="form-title-wrapper">
                        <Grid container spacing={0}>
                          <Grid
                            className="form-title-wrapper-img"
                            item
                            xs={2}
                            md={2}
                            sx={{ padding: "5px !important" }}
                          >
                            <img
                              src={goDollar}
                              alt="Dollar"
                              className="go-dollar"
                              style={{ width: "80%", height: "auto" }}
                            />
                          </Grid>
                          <Grid
                            className="form-title-wrapper-text"
                            item
                            xs={10}
                            md={10}
                            sx={{ display: "flex" }}
                          >
                            <Typography
                              variant="h3"
                              id="form_title"
                              sx={formTitleSx}
                            >
                              Contact Info
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>

                      <Box className="step-bar-container">
                        <span
                          className="step-bar active"
                          onClick={() => {
                            setStep(1);
                          }}
                        ></span>
                        <span className="step-bar active"></span>
                        <span className="step-bar"></span>
                      </Box>
                      <Box className="form-input">
                        <TextField
                          label="Phone # *"
                          fullWidth
                          size="small"
                          InputProps={searchInputProp}
                          InputLabelProps={inputLabelProp}
                          variant="filled"
                          type="text"
                          value={phoneNumber}
                          onChange={onPhoneNumberChange}
                          error={phoneError}
                          inputProps={{ maxLength: 12 }}
                          helperText={phoneerror}
                        />
                      </Box>
                      <Box className="form-input">
                        <TextField
                          label="Email Address *"
                          fullWidth
                          size="small"
                          InputProps={searchInputProp}
                          InputLabelProps={inputLabelProp}
                          variant="filled"
                          value={emailAddress}
                          onChange={onEmailChanged}
                          error={emailError}
                          //helperText={emailError ? 'Email address invalid' : ''}
                          helperText={emailerror}
                        />
                      </Box>
                      <Box className="next-btn-container">
                        <Button
                          variant="outlined"
                          id="btn_start"
                          className={
                            (phoneNumber == null || emailAddress == "") &&
                            "deactive"
                          }
                          onClick={nextClick}
                        >
                          <Typography className="btn_start">Next</Typography>
                        </Button>
                      </Box>
                    </Box>
                  )}
                  <Typography
                    className="cancel_exit"
                    onClick={HandleRedirectBackSale}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textDecorationLine: "underline",
                      textDecorationColor: "#2e2e2e",
                      color: "#2e2e2e",
                      cursor: "pointer",
                      fontFamily: "Gotham !important",
                    }}
                  >
                    Cancel & Exit
                  </Typography>
                  {/* <Box className="next-btn-container">
                    {step === 1 && (
                      <Button
                        variant="outlined"
                        id="btn_start"
                        className={fullName == "" && "deactive"}
                        onClick={nextClick}
                      >
                        <Typography className="btn_start">Next</Typography>
                      </Button>
                    )}

                    {step === 2 && (
                      <Button
                        variant="outlined"
                        id="btn_start"
                        className={
                          (phoneNumber == null || emailAddress == "") &&
                          "deactive"
                        }
                        onClick={nextClick}
                      >
                        <Typography className="btn_start">Next</Typography>
                      </Button>
                    )}
                  </Box> */}
                </Box>
              </Box>
            )}
          </Box>
        )}
        {step === 3 && (
          <Box className="equip-lending-form">
            <Box
              component={"div"}
              className="step-one-container mobile-hidden form-containers"
            >
              {/* <Box  className='step-one-container center-right mobile-hidden'> */}
              <Box>
                <img src={welcomeTo} alt="Welcome to" className="welcome-to" />
              </Box>
              <Box>
                <img
                  src={goLending}
                  alt="Go Equipment Lending"
                  className="go-lending"
                />
              </Box>
              <Typography id="evolution_text">
                THE EVOLUTION OF EQUIPMENT FINANCING
              </Typography>
              <Typography className="financing-text" id="financing_text">
                Our Loan Specialists will help YOU get equipment financing for:
              </Typography>
              <ul className="financing-list">
                <li>PURCHASES</li>
                <li>REFINANCES</li>
                <li>CASH-OUTS</li>
                <li>COMPETITIVE RATE</li>
              </ul>
              {/* </Box> */}

              {/* <img
              src={webLanding}
              alt='Web Landing'
              className='web-landing'
            /> */}
            </Box>
            <Box className="welcome-form">
              <Box className=" form-container" sx={{ overflow: "hidden" }}>
                <Box
                  className="form-container-inner last-form"
                  sx={{
                    overflowY: "scroll",
                    maxHeight: "450px",
                    minHeight: "88% !important",
                  }}
                >
                  <Typography
                    variant="h4"
                    id="form_title"
                    className="form_title_last"
                  >
                    What Do You Want to Finance?
                  </Typography>
                  <Box
                    sx={{
                      overflow: "auto",
                      mt: 2,
                    }}
                    className="equipment-type-wrapper"
                  >
                    {equipments.map((equipment) => (
                      <EquipmentType
                        equipment={equipment}
                        equipments={equipments}
                        updateEquipments={updateEquipments}
                      />
                    ))}
                  </Box>
                  {equipments.length <= 2 && (
                    <Button
                      variant="outlined"
                      id="add_equipment"
                      onClick={onAddEquipmentClick}
                      size="small"
                      sx={{
                        border: 0,
                        mt: 2,
                        ":hover": {
                          border: 0,
                        },
                      }}
                    >
                      <Add
                        sx={{
                          color: "#faa61a",
                        }}
                      />
                      <Typography
                        sx={{
                          color: "#faa61a",
                          fontWeight: 700,
                        }}
                      >
                        Add Equipment
                      </Typography>
                    </Button>
                  )}
                  <Box className="next-btn-container">
                    <Button
                      variant="outlined"
                      id="btn_start"
                      onClick={nextClick}
                    >
                      :''
                      {/* <span>{'<'}</span> */}
                      <Typography className="btn_start">
                        {loading ? "Submitting..." : "Submit"}
                      </Typography>
                      {/* <span>{'>'}</span> */}
                    </Button>
                  </Box>
                  <Typography
                    className="cancel_exit"
                    onClick={HandleRedirectBackSale}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textDecorationLine: "underline",
                      textDecorationColor: "#2e2e2e",
                      color: "#2e2e2e",
                      cursor: "pointer",
                      fontFamily: "Gotham !important",
                    }}
                  >
                    Cancel & Exit
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        {step === 4 && (
          <Box className="equip-lending-form thankyou-box">
            <Box component={"div"} className="thankyou" sx={{ width: "100%" }}>
              <Box className="thankyou-inner">
                <Box className="thank-you-box">
                  <img
                    src={thankYou}
                    alt="Thank you"
                    className="img-thank-you"
                  />
                </Box>
                <Typography className="financing-text" id="financing_text">
                  One of our Loan Specialists will contact you soon to discuss
                  your options!
                </Typography>

                <Box className="start-btn-container">
                  <Link
                    href="/"
                    sx={{
                      textDecoration: "none",
                    }}
                  >
                    <Button variant="outlined" id="btn_start">
                      {/* <span>{'<'}</span> */}
                      <Typography className="btn_start">Exit</Typography>
                      {/* <span>{'>'}</span> */}
                    </Button>
                  </Link>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </MainLayout>
  );
};

export default Welcome;
