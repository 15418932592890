import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ProToolsCard from "./ProToolsCard";
import { Backdrop, Box, CircularProgress, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { IStoreValue } from "utils/types";
import { EquipmentAPIs } from "../../../service/api-service";
import { setalertMessage, setalertMessagetype } from "redux/redux-slice";
import SeeMoreBtn from "components/common/SeeMoreBtn";

interface GemTypes {
  id: string;
  link: string;
  link_text: string;
  updated_at: string;
}
const limit = 10;
const GemProTools = () => {
  const dispatch = useDispatch();
  const AuthToken = useSelector(
    (state: IStoreValue) => state.equipmentReducer.accessToken
  );
  const AuthUserId = useSelector(
    (state: IStoreValue) => state.equipmentReducer.Authuser
  );

  const proToolRef = useRef<HTMLDivElement | null>(null);
  const [gemProToolsData, setGemProToolsData] = useState<Array<GemTypes>>([
    // {
    //   id: "",
    //   link: "",
    //   link_text: "",
    //   updated_at: "",
    // },
  ]);
  const [loading, setLoading] = useState<boolean>(false);
  const [paginationLoader, setPaginationLoader] = useState<boolean>(false);
  const pageNo = useRef<number>(1);
  const [total, setTotal] = useState<number>(0);
  const maxPage = useMemo(() => Math.floor(total / limit), [total]);
  const [lastScrollPosition, setLastScrollPosition] = useState(0);
  const callPagination = useRef<Boolean>(true);
  const getProToolsData = async function (page = 0) {
    try {
      page ? setPaginationLoader(true) : setLoading(true);
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const params = { user_id: AuthUserId?.toString(), page, per_page: limit };
      const response = await EquipmentAPIs.getGemProTools(params, headers);
      if (response["type"] === "RXSUCCESS") {
        if (!page) {
          setGemProToolsData(response.data);
          setTotal(parseFloat(response?.totalRecords));
        } else {
          setGemProToolsData((prev) => [...prev, ...response.data]);
        }
        callPagination.current = true;
      } else {
        throw new Error(response["message"]);
      }
    } catch (error) {
      dispatch(setalertMessage(error["message"]));
      dispatch(setalertMessagetype("error"));
    } finally {
      setPaginationLoader(false);
      setLoading(false);
    }
  };

  const openInNewTab = useCallback((path) => {
    window.open(path, "_blank", "noopener,noreferrer");
  }, []);

  // const handlePagination = function () {
  //   getProToolsData(pageNo);
  //   setPageNo((prev) => prev + 1);
  // };

  const handlePagination = function () {
    const parentElement = proToolRef.current;
    if (!parentElement) return;
    if (pageNo.current > maxPage) return; // Ensure there are at least 3 children
    const childrenArray = Array.from(parentElement.children);
    const thirdLastChild = childrenArray[childrenArray.length - 3];

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            if (callPagination.current && pageNo.current <= maxPage) {
              callPagination.current = false;
              getProToolsData(pageNo.current);
              pageNo.current = pageNo.current + 1;
            }
          }
        });
      },
      { threshold: 0.1 }
    );

    observer.observe(thirdLastChild);

    return () => {
      observer.disconnect();
    };
  };

  useEffect(() => {
    if (AuthUserId) {
      getProToolsData();
    }
  }, [AuthUserId]);

  const handleScroll = () => {
    const currentScrollPosition = window.scrollY;

    if (currentScrollPosition > lastScrollPosition) {
      // Only act if scrolling down
      handlePagination();
    }

    // Update the last scroll position
    setLastScrollPosition(currentScrollPosition);
  };

  useEffect(() => {
    // Add the scroll event listener to the window
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollPosition]);

  return (
    <Box sx={{ width: "100%", boxSizing: "border-box" }}>
      {loading && (
        <Box>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            {/* <CircularProgress color="inherit" /> */}
            <Typography variant="h5">Loading Your Pro Tools...</Typography>
          </Backdrop>
        </Box>
      )}
      <Box>
        <Typography
          variant="h6"
          sx={{
            color: "#fff",
            fontFamily: "GothamBold !important",
            fontSize: "clamp(20px,3vw,40px)",
            mt: 2,
            mb: 2,
          }}
        >
          GEM Pro Tools
        </Typography>
      </Box>
      {gemProToolsData.length > 0 ? (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" },
            gap: { xs: "1rem", md: "2rem" },
            width: "100%",
            boxSizing: "border-box",
          }}
          ref={proToolRef}
        >
          {gemProToolsData.map((item) => (
            <ProToolsCard data={item} key={item?.id} openFile={openInNewTab} />
          ))}
        </Box>
      ) : !loading ? (
        <Box
          sx={{
            color: "#fff",
            textAlign: "center",
            paddingTop: "24px",
            fontFamily: "GothamBold !important",
          }}
        >
          No Record Found!
        </Box>
      ) : (
        ""
      )}
      {paginationLoader && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress sx={{ color: "#faa61a",mt:4 }} />
        </Box>
      )}
      {/* {total > gemProToolsData.length &&
        (paginationLoader ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <SeeMoreBtn HandlePagination={handlePagination} />
        ))} */}
    </Box>
  );
};

export default GemProTools;
