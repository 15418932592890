import React, { createContext } from "react";
import EquipmentOrdersChild from "./EquipmentOrdersChild";
import { useNavigate } from "react-router-dom";
export const orderContext = createContext(null);

export const EquipmentOrdersProvider = ({ children }) => {
  const navigate = useNavigate();
  const handleGPSNavigation = function (element) {
    const equipId = element?.equip_id;
    if (equipId) {
      navigate(`/myGps/${equipId}`);
    }
  };
  return (
    <orderContext.Provider value={{ handleGPSNavigation }}>
      {children}
    </orderContext.Provider>
  );
};

const EquipmentOrders = () => {
  return (
    <EquipmentOrdersProvider>
      <EquipmentOrdersChild />
    </EquipmentOrdersProvider>
  );
};

export default EquipmentOrders;
