export const getFormattedGoogleAdress = (googleData) => {
  if (!googleData) {
    return {
      city: "",
      country: "",
      formatted_address: "",
      lat: 0,
      lng: 0,
      locality: "",
      state: "",
      zipcode: "",
    };
  }
  const addressComponents = googleData.address_components;
  const getComponent = (type) =>
    addressComponents.find((component) => component.types.includes(type))
      ?.long_name || "";
  const { lat, lng } = googleData.geometry.location;
  const formattedAddress = {
    formatted_address: googleData.formatted_address,
    state:
      getComponent("administrative_area_level_1") ||
      getComponent("administrative_area_level_2") ||
      "",
    city: getComponent("locality") || getComponent("sublocality") || "",
    zipcode: getComponent("postal_code"),
    country: getComponent("country") || "",
    lat,
    lng,
  };
  return formattedAddress;
};
