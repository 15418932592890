import React, { useCallback, useEffect, useState, useRef } from "react";
import { EquipmentAPIs } from "../../../service/api-service";
import {
  Box,
  CircularProgress,
  Typography,
  Backdrop,
  Link,
  Modal,
  IconButton,
  Divider,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import {
  setalertMessagetype,
  setalertMessage,
  setSessionExpire,
  setEquipAddType,
} from "redux/redux-slice";
import { IApiResponseRawData, IStoreValue } from "utils/types";
import gemArrow from "assets/images/gem-arrow.png";
import ForSale from "assets/images/for-sale.png";

import ProfilePic from "assets/images/dummy-profile-pic.jpeg";
import check from "assets/images/check.png";
import "./../newstyles.scss";
import EditEquipment from "../../EquipmentEdit/EditEquipment";
import CurrentFormat from "../../common/CurrenyFormat";
import CloseIcon from "@mui/icons-material/Close";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  TelegramShareButton,
  TelegramIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
  FacebookMessengerIcon,
} from "react-share";
var base_url = window.location.origin;
var base_url_link = "";
const loginstyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
  p: 4,
};

const SaleEquips = ({
  Load = false,
  UpdateTab,
  ReloadTab = 0,
  reloadTabUpdate,
  handleListEquipment,
}) => {
  const dispatch = useDispatch();
  const AuthToken = useSelector(
    (state: IStoreValue) => state.equipmentReducer.accessToken
  );
  const AuthProfileData = useSelector(
    (state: IStoreValue) => state.equipmentReducer.AuthProfileData
  );

  const [loading, setloading] = React.useState(false);
  const [EquipResult, setEquipResult] = React.useState([]);
  const [paginationPage, setpaginationPage] = React.useState(5);
  const [TotalResult, setTotalResult] = React.useState(0);
  const [Page, setPage] = React.useState(0);
  const [CurrentActiveEquip, setCurrentActiveEquip] = React.useState(null);
  const [ActiveEquipID, setActiveEquipID] = React.useState(null);
  const [ActiveEquipIndex, setActiveEquipIndex] = React.useState(null);

  const [EditClick, setEditClick] = React.useState(false);
  const [ShareYardPopup, setShareYardPopup] = React.useState(false);

  useEffect(() => {
    // Scroll the window to the top
    window.scrollTo(0, 0);

    // Check if AuthProfileData is not null
    if (
      AuthProfileData &&
      AuthProfileData != null &&
      AuthProfileData["user_id"]
    ) {
      //setRenterLocation({ lat: AuthProfileData['lat'], lng: AuthProfileData['lng'] })
      setPage(0);
      // console.log("page in effet 1: " , Page);
      // Fetch sales equipment data
      GetSaleEquip(AuthProfileData["user_id"], 0, "default");
      // Update base_url_link for sharing
      base_url_link = `${base_url}/share-rental-yard/sale/${AuthProfileData["user_id"]}/${AuthProfileData["user_id"]}`;
    }
  }, [AuthProfileData]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (AuthProfileData && AuthProfileData != null && ReloadTab) {
      setPage(0);
      // console.log("reload in sales");

      console.log("object", AuthProfileData["user_id"], "===");
      // console.log("page in effet 2: " , Page);
      // by GD due to page error
      GetSaleEquip(AuthProfileData["user_id"], 0, "default");
      // end by GD
      // GetSaleEquip(AuthProfileData['user_id'], Page, 'default')
      base_url_link =
        base_url + "/share-rental-yard/sale/" + AuthProfileData["user_id"];
    }
  }, [ReloadTab, AuthProfileData]);

  // Asynchronous function to fetch sales equipment data
  /**
   * Function: GetSaleEquip
   * Description:
   * this is the main sales yard component for listing details etc.
   * Fetches sales equipment data from the server, updates the state, and handles success or error scenarios.
   * If successful, sets the current page, updates the total results, and sets the default active sale equipment.
   * If an error occurs, dispatches relevant alert messages and handles session expiration.
   *
   * @param {string} user_id - The user ID for fetching equipment data.
   * @param {number} new_page - The page number for pagination.
   * @param {string} type - The type of response ('default', 'next', etc.).
   */
  const GetSaleEquip = async (user_id, new_page, type) => {
    try {
      console.log("get sales equips");

      // Set loading state to true
      setloading(true);

      // Call the GetSaleEquips API to fetch equipment data
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const EquipData = await EquipmentAPIs.GetSaleEquips(
        {
          user_id: user_id.toString(),
          page: new_page,
        },
        headers
      );

      // console.log("salesEquipData: ", EquipData);

      // Check the type of response received
      if (EquipData["type"] == "RXSUCCESS") {
        // Set the current page
        setPage(new_page);

        let old_res = [];
        // Check if the type is not default
        if (type !== "default") {
          old_res = [...EquipResult];
        }

        // Update total results and create a new array combining old and new data
        setTotalResult(EquipData["total"]);
        let _LastArray = [...old_res, ...EquipData["data"]];
        setEquipResult(_LastArray);
        setloading(false);

        //set default active sale equip
        let equipId = localStorage.getItem("EquipID");
        let equipType = localStorage.getItem("EquipType");

        // Check if the stored type is sale
        if (
          equipType != undefined &&
          equipType == "sale" &&
          equipType != null
        ) {
          // Check if there is a stored equipment ID
          if (equipId != undefined && equipId != null) {
            setCurrentActiveEquip(_LastArray[0]);
            setActiveEquipID(equipId);
            localStorage.removeItem("EquipID");
            localStorage.removeItem("EquipType");
          }
        } else {
          // by gd for delete fxn error when deleting an equipment
          if (_LastArray.length > 0 && _LastArray[ActiveEquipIndex]) {
            setCurrentActiveEquip(_LastArray[ActiveEquipIndex]);
            setActiveEquipID(_LastArray[ActiveEquipIndex]["id"]);
          } else if (_LastArray.length > 0) {
            setActiveEquipIndex(0);
            setCurrentActiveEquip(_LastArray[0]);
            setActiveEquipID(_LastArray[0]["id"]);
          } else {
            // when the item we delete is last item
            // setCurrentActiveEquip(undefined);
            // setActiveEquipID(null);
            // setActiveEquipIndex(null);
          }
          // end by GD
          // setCurrentActiveEquip(_LastArray[ActiveEquipIndex]);
          // setActiveEquipID(_LastArray[ActiveEquipIndex]['id']);
        }
      } else if (
        EquipData["status"] == false &&
        EquipData["slug"] == "Session Expired"
      ) {
        // Dispatch session expiration action
        dispatch(setSessionExpire(true));
        setloading(false);
        return false;
      } else {
        // Dispatch error alert messages
        dispatch(setalertMessage(EquipData["message"]));
        dispatch(setalertMessagetype("error"));
        setloading(false);
        return false;
      }
    } catch (err) {
      // Log and handle errors
      console.log("error in getSalesEquip");
      console.log(err);
      setloading(false);
    }
  };

  // Handle pagination click
  /**
   * Function: HandlePagination
   * Description:
   * Handles the click event for pagination.
   * Sets loading state to true, calculates the new page number, and calls GetSaleEquip to fetch the next set of data.
   */
  const HandlePagination = () => {
    setloading(true);
    let new_page = Number(Page) + 1;

    // Call GetSaleEquip to fetch the next set of data
    GetSaleEquip(AuthProfileData["user_id"], new_page, "next");
  };

  /**
   * Function: HandleActiveClick
   * Description:
   * used to show the equipment details on the right side of the screen.
   * Handles the click event for selecting an active equipment.
   * Sets the current active equipment, its ID, and index in the equipment array.
   *
   * @param {Object} Equip - The equipment object being clicked.
   * @param {number} index - The index of the equipment in the array.
   */
  const HandleActiveClick = (Equip = null, index) => {
    // Set the current active equipment, its ID, and index
    setCurrentActiveEquip(Equip);
    setActiveEquipID(Equip.id);
    setActiveEquipIndex(index);
  };

  return (
    <>
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}

      {/* for disaplay share Yard option */}
      <Box className="rentyrd-heading">
        <Typography>My Sales Yard</Typography>
        {EquipResult.length > 0 ? (
          <Link
            className="rentyrd-share-link"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setShareYardPopup(true);
            }}
          >
            Share Yard
          </Link>
        ) : (
          <></>
          // <Link
          //   className="rentyrd-share-link"
          //   style={{ cursor: "pointer" }}
          //   onClick={HandleNoShare}
          // >
          //   Share Yard
          // </Link>
        )}
      </Box>

      <Box className="rentyrd-grid">
        <Box className="rentyrd-grid-item rentyrd-left">
          {/* show listing on left side if totalEquipments is more than 0 else show no equipmnets  */}
          {TotalResult > 0 ? (
            <>
              {EquipResult.map((equipment, indx) => (
                <Box key={equipment.id}>
                  <Box
                    className={
                      ActiveEquipID == equipment.id
                        ? "rentyrd-card rental-box-active"
                        : "rentyrd-card"
                    }
                    id={"active-" + equipment["id"]}
                    onClick={() => {
                      HandleActiveClick(equipment, indx);
                    }}
                  >
                    <Box
                      // className="rentyrd-card-grid"
                      sx={{
                        display: "flex",
                        flexDirection: {
                          xs: "column",
                          sm: "row",
                          md: "row",
                          lg: "row",
                          xl: "row",
                        },
                        alignItems: "center",
                        gap: "1rem",
                      }}
                    >
                      <Box
                        // className="rentyrd-card-item-left"
                        sx={{
                          width: {
                            xs: "100%",
                            sm: "auto",
                            md: "auto",
                            lg: "auto",
                            xl: "auto",
                          },
                        }}
                      >
                        <Box
                          // className="rentyrd-card-img"
                          sx={{ position: "relative", width: "100%" }}
                        >
                          <Box
                            sx={{
                              position: "absolute",
                              top: "-5px",
                              left: "-10px",
                              background: "transparent",
                              zIndex: "10",
                              padding: "5px 15px",
                              color: "#fff",
                              fontFamily: "Gotham !important",
                            }}
                          >
                            <Box
                              component={"img"}
                              src={ForSale}
                              alt=""
                              sx={{
                                width: "55px",
                                height: "auto",
                                display: "block",
                              }}
                            />
                            {/* <Typography>For Sale</Typography> */}
                          </Box>
                          {/* <img className='eqppmnt-img' src={equipment["thumbnail"]} alt="" /> */}
                          <Box
                            component={"img"}
                            src={equipment["thumbnail"]}
                            alt=""
                            sx={{
                              width: {
                                xs: "100%",
                                sm: "200px",
                                md: "250px",
                                lg: "180px",
                                xl: "200px",
                              },
                              height: {
                                xs: "195px",
                                sm: "150px",
                                md: "187px",
                                lg: "135px",
                                xl: "150px",
                              },
                              objectFit: "cover",
                            }}
                          />
                        </Box>
                      </Box>
                      <Box
                        // className="rentyrd-card-item-right"
                        sx={{ width: "100%" }}
                      >
                        <Box
                        // className="rentyrd-right"
                        >
                          <Typography
                            // className="vehicle-model"
                            title={equipment.make}
                            sx={{
                              display: "-webkit-box",
                              overflow: "hidden",
                              WebkitBoxOrient: "vertical",
                              WebkitLineClamp: 1,
                              color: "#fff",
                              fontSize: {
                                xs: "18px",
                                sm: "19.5px",
                                md: "21px",
                                lg: "23px",
                                xl: "25px",
                              },
                              fontFamily: "GothamBold !important",
                            }}
                          >
                            {equipment.make
                              ? equipment.make.length > 20
                                ? equipment.make.substring(0, 20) + "..."
                                : equipment.make
                              : "NA"}
                          </Typography>
                          <Typography
                            // className="vehicle-type"
                            title={equipment.model}
                            sx={{
                              display: "-webkit-box",
                              overflow: "hidden",
                              WebkitBoxOrient: "vertical",
                              WebkitLineClamp: 1,
                              color: "#faa61a",
                              fontSize: {
                                xs: "14px",
                                sm: "15px",
                                md: "17px",
                                lg: "18.5px",
                                xl: "20px",
                              },
                            }}
                          >
                            {equipment.model
                              ? equipment.model.length > 22
                                ? equipment.model.substring(0, 22) + "..."
                                : equipment.model
                              : "NA"}
                          </Typography>
                          <Box
                            // className="rentyrd-address"
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              gap: "0.5rem",
                              padding: "0.5rem 0",
                              borderBottom: "2px solid gray",
                            }}
                          >
                            <Typography
                              title={equipment.city ? equipment.city : ""}
                              sx={{
                                color: "#fff",
                                fontSize: {
                                  xs: "12px",
                                  sm: "13px",
                                  md: "13px",
                                  lg: "14px",
                                  xl: "14px",
                                },
                              }}
                            >
                              {equipment.city
                                ? equipment.city.length > 15
                                  ? equipment.city.substring(0, 15) + "... "
                                  : equipment.city
                                : "NA"}
                              {equipment.state
                                ? equipment.state.length > 15
                                  ? ", " +
                                    equipment.state.substring(0, 15) +
                                    "..."
                                  : ", " + equipment.state
                                : ""}
                            </Typography>
                            {/* {equipment.owner_pickup_return == "1" ? (
                               <Typography>Delivery Available</Typography>
                              <Typography></Typography>
                            ) : (
                              <Typography></Typography>
                            )} */}
                            {/* by gd for display rpo_available in sales listing */}
                            {equipment.rpo_available == "1" && (
                              <>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "0.8rem",
                                  }}
                                >
                                  <Box
                                    component={"img"}
                                    src={check}
                                    alt=""
                                    style={{
                                      width: "18px",
                                      height: "18px",
                                      marginTop: "0px",
                                    }}
                                  />
                                  <Typography
                                    sx={{
                                      gap: "8px",
                                      color: "#faa61a !important",
                                      fontFamily: "Montserrat !important",
                                      fontWeight: "700",
                                    }}
                                  >
                                    RPO Avbl
                                  </Typography>
                                </Box>
                              </>
                            )}
                            {/* end */}
                          </Box>
                          <Box
                            className=""
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              padding: "0.5rem 0",
                            }}
                          >
                            <Box className="">
                              <Typography>
                                <Typography
                                  component={"span"}
                                  sx={{
                                    // fontSize: "clamp(14px, 3vw, 22px)",
                                    fontSize: {
                                      xs: "14px",
                                      sm: "16px",
                                      md: "18px",
                                      lg: "20px",
                                      xl: "22px",
                                    },
                                    color: "#fff",
                                    fontFamily: "Montserrat !important",
                                    fontWeight: "bold",
                                  }}
                                >
                                  $
                                  <CurrentFormat
                                    Currency={equipment.equip_sale_price}
                                  />
                                </Typography>
                                &nbsp;
                                <Typography
                                  component={"span"}
                                  className="rentyrd-rates-span"
                                  sx={{
                                    fontSize: {
                                      xs: "10px",
                                      sm: "11px",
                                      md: "12px",
                                      lg: "13px",
                                      xl: "14px",
                                    },
                                    fontFamily: "Montserrat !important",
                                    fontWeight: "600",
                                    // fontSize: "clamp(9px, 3vw, 14px)",
                                    color: "#faa61a",
                                  }}
                                >
                                  {equipment.obo == 1 && <>obo</>}
                                </Typography>
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                  {EquipResult.length != indx + 1 && (
                    <hr className="gray-line" />
                  )}
                </Box>
              ))}

              {TotalResult > paginationPage &&
                EquipResult.length < TotalResult && (
                  <Box className="pagination-section">
                    <Box
                      className="btn-load-more"
                      sx={{ color: "#faa61a !important" }}
                      onClick={HandlePagination}
                    >
                      {"See More"}
                      <img
                        src={gemArrow}
                        alt="GoEquipMe"
                        className="gem-arrow arrow-load-more"
                      />
                    </Box>
                  </Box>
                )}
            </>
          ) : (
            <>
              <Typography className="empty-rent">
                <span>You do not have any equipment listed yet.</span>
                <span>
                  To list your first item&nbsp;
                  <Link
                    className="rentyrd-menu-link"
                    onClick={(event) => {
                      dispatch(setEquipAddType("sale"));
                      handleListEquipment(event);
                    }}
                  >
                    click here
                  </Link>
                </span>
              </Typography>
            </>
          )}
        </Box>

        {/* to show active equipment details on right side of page */}
        {CurrentActiveEquip != null && (
          <>
            <Box
              className="rentyrd-grid-item rentyrd-right"
              sx={{ display: TotalResult > 0 ? "block" : "none" }}
            >
              <Box className="rentyrd-left-float">
                <Box className="equipment-details-heading">
                  <Typography>Equipment Details </Typography>
                </Box>

                <Box className="rentyrd-card">
                  <Box
                    // className="rentyrd-card-grid"
                    sx={{
                      display: "flex",
                      flexDirection: {
                        xs: "column",
                        sm: "row",
                        md: "row",
                        lg: "row",
                        xl: "row",
                      },
                      alignItems: "center",
                      gap: "1rem",
                    }}
                  >
                    <Box
                      // className="rentyrd-card-item-left"
                      sx={{
                        width: {
                          xs: "100%",
                          sm: "auto",
                          md: "auto",
                          lg: "auto",
                          xl: "auto",
                        },
                      }}
                    >
                      <Box
                        // className="rentyrd-card-img"
                        sx={{ position: "relative", width: "100%" }}
                      >
                        <Box
                          sx={{
                            position: "absolute",
                            top: "-5px",
                            left: "-10px",
                            background: "transparent",
                            zIndex: "10",
                            padding: "5px 15px",
                            color: "#fff",
                            fontFamily: "Gotham !important",
                          }}
                        >
                          {/* <img
                            src={ForSale}
                            alt=""
                            style={{
                              width: "55px",
                              height: "auto",
                              display: "block",
                            }}
                          /> */}
                          <Box
                            component={"img"}
                            src={ForSale}
                            alt=""
                            sx={{
                              width: "55px",
                              height: "auto",
                              display: "block",
                            }}
                          />
                          {/* <Typography>For Sale</Typography> */}
                        </Box>
                        {/* <img
                          className="eqppmnt-img"
                          src={CurrentActiveEquip["thumbnail"]}
                          alt=""
                        /> */}

                        <Box
                          component={"img"}
                          src={CurrentActiveEquip["thumbnail"]}
                          alt=""
                          sx={{
                            width: {
                              xs: "100%",
                              sm: "200px",
                              md: "250px",
                              lg: "180px",
                              xl: "200px",
                            },
                            height: {
                              xs: "195px",
                              sm: "150px",
                              md: "187px",
                              lg: "135px",
                              xl: "150px",
                            },
                            objectFit: "cover",
                          }}
                        />
                      </Box>
                    </Box>
                    <Box
                      // className="rentyrd-card-item-right"
                      sx={{ width: "100%" }}
                    >
                      <Box
                      // className="rentyrd-right"
                      >
                        <Typography
                          // className="vehicle-model"
                          sx={{
                            display: "-webkit-box",
                            overflow: "hidden",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 1,
                            color: "#fff",
                            fontSize: {
                              xs: "18px",
                              sm: "19.5px",
                              md: "21px",
                              lg: "23px",
                              xl: "25px",
                            },
                            fontFamily: "GothamBold !important",
                          }}
                        >
                          {CurrentActiveEquip["make"]
                            ? CurrentActiveEquip["make"]
                            : "Not Available"}
                        </Typography>
                        <Typography
                          // className="vehicle-type"
                          sx={{
                            display: "-webkit-box",
                            overflow: "hidden",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 1,
                            color: "#faa61a",
                            fontSize: {
                              xs: "14px",
                              sm: "15px",
                              md: "17px",
                              lg: "18.5px",
                              xl: "20px",
                            },
                          }}
                        >
                          {CurrentActiveEquip["model"]
                            ? CurrentActiveEquip["model"]
                            : "Not Available"}
                        </Typography>
                        <Box
                          // className="rentyrd-address"
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: "0.5rem",
                            padding: "0.5rem 0",
                            borderBottom: "2px solid gray",
                          }}
                        >
                          <Typography
                            title={
                              CurrentActiveEquip["city"]
                                ? CurrentActiveEquip["city"]
                                : "NA"
                            }
                            sx={{
                              display: "-webkit-box",
                              overflow: "hidden",
                              WebkitBoxOrient: "vertical",
                              WebkitLineClamp: 2,

                              color: "#fff",
                              fontSize: {
                                xs: "12px",
                                sm: "13px",
                                md: "13px",
                                lg: "14px",
                                xl: "14px",
                              },
                            }}
                          >
                            {CurrentActiveEquip["city"]
                              ? CurrentActiveEquip["city"]
                              : "NA"}
                            {CurrentActiveEquip["state"]
                              ? ", " + CurrentActiveEquip["state"]
                              : "NA"}
                          </Typography>
                          {CurrentActiveEquip["owner_pickup_return"] == "1" ? (
                            // <Typography>Delivery Available</Typography>
                            <Typography></Typography>
                          ) : (
                            <Typography></Typography>
                          )}
                        </Box>
                        <Box
                          className=""
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            padding: "0.5rem 0",
                          }}
                        >
                          <Box className="">
                            <Typography>
                              <Typography
                                component={"span"}
                                sx={{
                                  // fontSize: "clamp(14px, 3vw, 22px)",
                                  fontSize: {
                                    xs: "14px",
                                    sm: "16px",
                                    md: "18px",
                                    lg: "20px",
                                    xl: "22px",
                                  },
                                  color: "#fff",
                                  fontFamily: "Montserrat !important",
                                  fontWeight: "bold",
                                }}
                              >
                                $
                                <CurrentFormat
                                  Currency={
                                    CurrentActiveEquip["equip_sale_price"]
                                  }
                                />
                              </Typography>
                              &nbsp;
                              <Typography
                                component={"span"}
                                className="rentyrd-rates-span"
                                sx={{
                                  fontSize: {
                                    xs: "10px",
                                    sm: "11px",
                                    md: "12px",
                                    lg: "13px",
                                    xl: "14px",
                                  },
                                  fontFamily: "Montserrat !important",
                                  fontWeight: "600",
                                  // fontSize: "clamp(9px, 3vw, 14px)",
                                  color: "#faa61a",
                                }}
                              >
                                {CurrentActiveEquip["obo"] == 1 && <> obo</>}
                              </Typography>
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Box
                // className="rentyrd-order"
                >
                  <Divider
                    sx={{
                      borderColor: "#757575",
                      borderWidth: "1.5px",
                      mb: 2,
                      mt: 2,
                    }}
                  />
                  {/* <hr className="gray-line" /> */}
                  <Box className="">
                    <Box
                      className=""
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "1rem",
                      }}
                    >
                      {/* <Box className=""> */}
                      <Box
                        component={"img"}
                        src={
                          AuthProfileData["profile_image"]
                            ? AuthProfileData["profile_image"]
                            : ProfilePic
                        }
                        alt=""
                        style={{
                          width: "55px",
                          height: "55px",
                          borderRadius: "50%",
                          border: "2px solid #fff",
                          maxHeight: "55px",
                          objectFit: "contain",
                        }}
                      />
                      {/* </Box> */}
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "2px",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#fff",
                            fontWeight: "500",
                            fontFamily: "Montserrat !important",
                            fontSize: "15px",
                          }}
                        >
                          {/* <span style={{ color: "#fff" }}> */}
                          Owner:
                          {/* </span> */}
                        </Typography>
                        <Typography
                          onClick={() => {
                            // setOwnerInfo(true);
                          }}
                          sx={{
                            cursor: "pointer",
                            color: "#faa61a",
                            textDecoration: "underline",
                            fontFamily: "Montserrat !important",
                          }}
                        >
                          {/* <span
                            style={{
                              cursor: "pointer",
                              color: "#faa61a",
                              textDecoration: "underline",
                            }}
                            onClick={() => {
                              // setOwnerInfo(true);
                            }}
                          > */}
                          {AuthProfileData["first_name"]
                            .charAt(0)
                            .toUpperCase() +
                            AuthProfileData["first_name"].slice(1)}
                          {/* </span> */}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box className="rentyrd-renter-detail">
                    {CurrentActiveEquip["rpo_available"] == 1 && (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "flex-start",
                            gap: "1rem",
                          }}
                        >
                          <Box
                            component={"img"}
                            src={check}
                            alt=""
                            sx={{
                              width: "18px",
                              height: "18px",
                              marginTop: "3px",
                            }}
                          />
                          <Typography>
                            <Typography
                              component={"span"}
                              // className="radio-span-yellow"
                              sx={{
                                fontFamily: "Montserrat !important",
                                fontWeight: "bold",
                                color: "#faa61a",
                                fontSize: {
                                  xs: "14px",
                                  sm: "14px",
                                  md: "15px",
                                  lg: "15px",
                                  xl: "16px",
                                },
                              }}
                            >
                              RPO Available:&nbsp;
                            </Typography>
                            <Typography
                              component={"span"}
                              // className="radio-span-white"
                              sx={{
                                fontFamily: "Montserrat !important",
                                color: "#fff",
                                fontSize: {
                                  xs: "14px",
                                  sm: "14px",
                                  md: "15px",
                                  lg: "15px",
                                  xl: "16px",
                                },
                              }}
                            >
                              The owner may offer this as a rental with purchase
                              option.
                            </Typography>
                          </Typography>
                        </Box>
                      </>
                    )}
                  </Box>
                </Box>

                <Box
                // className="active-rental edit-listing-heading"
                >
                  <Typography
                    // variant="h4"
                    // className="active-rental-heading"
                    sx={{
                      color: "#faa61a",
                      fontFamily: "GothamBold !important",
                      // fontSize:'clamp(20px, 3vw, 35px)'
                      fontSize: {
                        xs: "22px",
                        sm: "25px",
                        md: "27px",
                        lg: "31px",
                        xl: "35px",
                      },
                    }}
                  >
                    Edit your sale listing
                  </Typography>
                </Box>

                {/* <hr className="gray-line" /> */}
                <Divider sx={{ borderColor: "gray", borderWidth: "1.5px" }} />
                {/* show editEquipment options */}
                {CurrentActiveEquip != null && (
                  <EditEquipment
                    Ref_EquipId={CurrentActiveEquip["id"]}
                    OnSuccess={(e) => {
                      if (e == "success") {
                        GetSaleEquip(AuthProfileData["user_id"], 0, "default");
                      }
                    }}
                    EditClick={EditClick}
                    ListEquipType="sale"
                    UpdateTab={(e) => {
                      UpdateTab(e);
                    }}
                    // by GD for hiding and show rentit button
                    equipType={CurrentActiveEquip["equip_list_type"]}
                    reloadTabUpdate={reloadTabUpdate}
                    // end by GD
                  />
                )}
              </Box>
            </Box>
          </>
        )}
      </Box>

      {/* ShareYard Popup */}
      <Modal
        keepMounted
        open={ShareYardPopup}
        onClose={() => setShareYardPopup(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: {
              xs: "80%",
              sm: "400px",
              md: "auto",
              lg: "auto",
              xl: "auto",
            },
            maxWidth: "500px",
            bgcolor: "#2e2e2e",
            border: "5px solid #faa61a",
            borderRadius: "10px",
            outline: "none !important",
            p: 3,
          }}
        >
          <IconButton
            onClick={() => setShareYardPopup(false)}
            sx={{
              position: "absolute",
              top: "-20px",
              right: "-20px",
              bgcolor: "#faa61a",
              color: "#000",
              "&:hover": {
                bgcolor: "#faa61a",
              },
            }}
          >
            <CloseIcon />
          </IconButton>

          {/* <IconButton
            className="cross-btn"
            onClick={() => setShareYardPopup(false)}
          >
            <CloseIcon />
          </IconButton> */}

          <Box
          // className="login__main"
          >
            {/* <Box
              className="login__main-div edit-eqp"
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            > */}
            <Typography
              // variant="h5"
              sx={{
                color: "#faa61a",
                fontSize: {
                  xs: "18px",
                  sm: "24px",
                  md: "26px",
                  lg: "28px",
                  xl: "32px",
                },
                fontWeight: "700",
                fontFamily: "Montserrat !important",
                mb: 2,
              }}
            >
              Share Yard
            </Typography>
            <Box
              // className="login__main-div__form edit-eqp-inner pupup-scroll"
              sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1rem",
                  flexWrap: "wrap",
                }}
              >
                <FacebookShareButton url={base_url_link}>
                  <FacebookIcon size={38} round />
                </FacebookShareButton>
                <FacebookShareButton url={base_url_link}>
                  <FacebookMessengerIcon size={38} round />
                </FacebookShareButton>

                <TwitterShareButton url={base_url_link}>
                  <TwitterIcon size={38} round />
                </TwitterShareButton>

                <TelegramShareButton url={base_url_link}>
                  <TelegramIcon size={38} round />
                </TelegramShareButton>

                <WhatsappShareButton url={base_url_link}>
                  <WhatsappIcon size={38} round />
                </WhatsappShareButton>

                <EmailShareButton url={base_url_link}>
                  <EmailIcon size={38} round />
                </EmailShareButton>
              </Box>
            </Box>
            {/* </Box> */}
          </Box>
        </Box>
      </Modal>
    </>
  );
};
export default SaleEquips;
