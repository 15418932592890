import { Modal, Box, IconButton, Typography } from "@mui/material";
import React from "react";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  TelegramShareButton,
  TelegramIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
  FacebookMessengerIcon,
} from "react-share";
import CloseIcon from "@mui/icons-material/Close";
const SocialShareModal = ({ open, handleClose, title, shareUrl }) => {
  return (
    <Modal
      keepMounted
      open={open}
      onClose={handleClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box
        className="download-popups"
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          boxShadow: 24,
          p: 4,
          padding: "0px",
        }}
      >
        <IconButton className="cross-btn" onClick={handleClose}>
          <CloseIcon />
        </IconButton>

        <Box className="login__main">
          <Box
            className="login__main-div edit-eqp"
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box className="login__main-div__form edit-eqp-inner pupup-scroll">
              <Typography variant="h5" style={{ color: "#faa61a" }}>
                {title}
              </Typography>

              <Box>
                <FacebookShareButton url={shareUrl}>
                  <FacebookIcon size={32} round style={{ padding: "8px" }} />
                  <FacebookMessengerIcon
                    size={32}
                    round
                    style={{ padding: "8px" }}
                  />
                </FacebookShareButton>

                <TwitterShareButton url={shareUrl}>
                  <TwitterIcon size={32} round style={{ padding: "8px" }} />
                </TwitterShareButton>

                <TelegramShareButton url={shareUrl}>
                  <TelegramIcon size={32} round style={{ padding: "8px" }} />
                </TelegramShareButton>

                <WhatsappShareButton url={shareUrl}>
                  <WhatsappIcon size={32} round style={{ padding: "8px" }} />
                </WhatsappShareButton>

                <EmailShareButton url={shareUrl}>
                  <EmailIcon size={32} round style={{ padding: "8px" }} />
                </EmailShareButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default SocialShareModal;
