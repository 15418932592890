import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  Dispatch,
} from "react";
import {
  Box,
  Button,
  Container,
  Link,
  Typography,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Modal,
  IconButton,
  useMediaQuery,
  FormControl,
  TextField,
  styled,
  InputAdornment,
  Divider,
} from "@mui/material";
import NumericInput from "material-ui-numeric-input";
import { useDispatch, useSelector } from "react-redux";
import { EquipmentAPIs } from "../../service/api-service";
import { IApiResponseRawData, IStoreValue } from "utils/types";
import moment from "moment";
import { setalertMessagetype, setalertMessage } from "redux/redux-slice";
import CloseIcon from "@mui/icons-material/Close";
import gemArrow from "assets/images/gem-arrow.png";
import ChatWindow from "../ChatWindow/ChatWindow";
import EditEquipment from "../EquipmentEdit/EditEquipment";
//import UserInfo from "../UserInfo/Userinfo";
import CurrentFormat from "../common/CurrenyFormat";
import ProfilePic from "assets/images/dummy-profile-pic.jpeg";
import OrderDetail from "../OrderDetail/VieworderDetail";
import { useNavigate } from "react-router-dom";
import ExtendedOrderDetail from "../common/Extendorderdetail";
let reason = "";
const today = moment().format("YYYY-MM-DD");
const loginstyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
  p: 4,
};

const NewCssTextField = styled(TextField)({
  "& .MuiInput-underline:after": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiInput-underline:before": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiFilledInput-underline:after": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiFilledInput-underline:before": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },

  "& .MuiInputLabel-shrink.MuiInputLabel-filled": {
    color: "#2d2d2d",
  },
  "& .MuiInputBase-root": {
    color: "#2d2d2d",
    background: "#fff",
    fontFamily: "Gotham !important",
    fontSize: "clamp(12px, 3vw, 15px) !important",
    borderRadius: "10px !important",
    // border: "2px solid #faa61a",
  },
  "&. MuiInputLabel-shrink": {
    color: "red !important",
  },

  "&. MuiInputLabel-shrink.Mui-focused": {
    color: "red !important",
  },

  "& label.Mui-focused": {
    color: "#2d2d2d !important",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "2px solid #faa61a",
      backgroundColor: "#fff !important",
    },
    "&:hover fieldset": {
      borderColor: "#2d2d2d",
      border: "2px solid #faa61a",
      backgroundColor: "#fff !important",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#faa61a",
    },
  },
  "& .MuiInputBase-root-MuiFilledInput-root:before": {
    borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
  },
});

var images_gallery = [];

/**
 * Function: NewRentalDetail
 * Description:
 * this is the equipment for show rental yard selected equipment details and edit options
 * Fetches rental ordesr and show list.
 * If an error occurs, dispatches relevant alert messages and handles session expiration.
 */
const NewRentalDetail = ({
  ActiveEquip,
  Successres,
  ChatClick,
  UpdateTab,
  reloadTabUpdate,
  setActiveStep,
}) => {
  // console.log("ActiveEquip >>>>>" , ActiveEquip.equip_list_type);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const AuthToken = useSelector(
    (state: IStoreValue) => state.equipmentReducer.accessToken
  );
  const UserID = useSelector(
    (state: IStoreValue) => state.equipmentReducer.Authuser
  );
  const NotificationsList = useSelector(
    (state: IStoreValue) => state.equipmentReducer.NotificationList
  );
  const pageNo = useRef<number>(1);
  const [loading, setLoading] = useState(false);
  const [loadingMyorder, setloadingMyorder] = useState(false);
  const [EquipData, setEquipData] = React.useState([]);
  const [PaginationData, setPaginationData] = React.useState([]);
  const [PaginationPage, setPaginationPage] = React.useState(2);
  const [EquipLocation, setEquipLocation] = useState({
    lat: "",
    lng: "",
  });

  const [MyOrders, setMyOrders] = React.useState([]);
  const [OrderStatusChange, setOrderStatusChange] = React.useState(false);
  const [RefundSecuritypopup, setRefundSecuritypopup] = React.useState(false);
  const [RefundSecurityamt, setRefundSecurityamt] = React.useState(null);
  const [RefundSecurityamount, setRefundSecurityamount] = React.useState("");
  const [RefundSecurityamtreson, setRefundSecurityamtreson] =
    React.useState("");
  const [OrderStatusObj, setOrderStatusObj] = useState({
    order_id: "",
    renter_id: "",
    order_status: "",
  });

  const [orderID, setorderID] = React.useState(null);
  const [OrderDetailpopup, setOrderDetailpopup] = React.useState(false);
  const [RenterPickupImages, setRenterPickupImages] = React.useState(false);
  const [ChatOwnerToRenter, setChatOwnerToRenter] = React.useState(false);
  const [OwnerInfoPopup, setOwnerInfoPopup] = React.useState(false);
  const [OwnerInfo, setOwnerInfo] = React.useState(null);
  const [ReturnImageTitle, setReturnImageTitle] = React.useState("");
  const [RenterPickupImagesArr, setRenterPickupImagesArr] = React.useState([]);

  const matchesMobileX = useMediaQuery("(max-width:425px)");

  const [NewMsg, setNewMsg] = React.useState(null);

  const [ActiveOrder, setActiveOrder] = React.useState([]);
  const [UserInfo, setUserInfo] = useState(null);

  const [ChatData, setChatData] = React.useState({
    equipId: null,
    senderID: null,
    reciverID: null,
    reciverName: null,
    EquipMake: null,
    EquipModel: null,
  });

  const [EditClick, setEditClick] = React.useState(false);

  /**
   * Function: viewOrders
   * Description:
   * Fetches order details for the specified equipment and filters out completed, canceled,
   * settled, and disputed orders. Updates state variables for displaying relevant order information.
   *
   * @param {string} id - The ID of the equipment for which orders are to be retrieved.
   * @param {string} token - The authentication token for making the API request.
   */
  const viewOrders = async (id, token) => {
    try {
      return;
      // Set loading state to true while fetching orders
      setloadingMyorder(true);

      // Call the API to retrieve order details for the specified equipment
      const headers = { Authorization: `${token}`, request_from: "Web" };
      const Ordersdetail = await EquipmentAPIs.MyOrder(
        {
          equip_id: id.toString(),
        },
        headers
      );

      // Check if the API request was successful
      if (Ordersdetail["type"] == "RXSUCCESS") {
        let allOrders = Ordersdetail["data"];
        var NewData = [];

        // Retrieve all orders and filter out completed, canceled, settled, and disputed orders
        allOrders.map((item) => {
          item["order_status"] != "completed" &&
            item["order_status"] != "order_canceled" &&
            item["order_status"] != "order_cancelled" &&
            item["order_status"] != "security_settled" &&
            item["order_status"] != "dispute_settled" &&
            NewData.push(item);
        });

        // Update state variables with relevant order information
        setMyOrders(NewData);
        setPaginationData(NewData.slice(0, PaginationPage));
        setloadingMyorder(false);
      } else {
        // If the API request is not successful, set loading state to false
        setloadingMyorder(false);
      }
    } catch (err) {
      // Handle errors that occur during the API request
      console.log(err);
      setloadingMyorder(false);
    }
  };

  /**
   * Function: HandleOrderStatusPopup
   * Description:
   * Handles the popup for changing order status. Calculates the total security amount
   * considering both the main order and any extended orders. Opens the refund security
   * popup if the order status is "returned"; otherwise, sets up the order status change.
   *
   * @param {string} order_id - The ID of the order.
   * @param {string} user_id - The ID of the renter associated with the order.
   * @param {string} order_status - The new order status to be set.
   * @param {number} refund_security - The main order security amount.
   * @param {object} order_obj - The object containing order details, including extended orders.
   */
  const HandleOrderStatusPopup = async (
    order_id,
    user_id,
    order_status,
    refund_security,
    order_obj
  ) => {
    let security_amy = refund_security; // Main order security

    // check extended order security amount
    var ex_order = order_obj["order_extend"];
    //console.log('ex_order', ex_order);
    if (ex_order && ex_order.length > 0 && ex_order != null) {
      await Promise.all(
        ex_order.map(async (element) => {
          if (element.order_status != "order_canceled") {
            var ex_security = element.security_deposit;
            //    console.log('ex_security', ex_security);
            security_amy = security_amy + ex_security;
          }
        })
      );
    }
    // Format the total security amount
    security_amy = parseFloat(security_amy).toFixed(2);

    if (order_status == "returned") {
      // If the order status is "returned," open the refund security popup
      setRefundSecurityamt(security_amy);
      setRefundSecurityamount("");
      setRefundSecurityamtreson("");
      setRefundSecuritypopup(true);
    } else {
      // If the order status is not "returned," set up the order status change
      setOrderStatusChange(true);
      setOrderStatusObj({
        order_id: order_id,
        renter_id: user_id,
        order_status: order_status,
      });
    }
  };

  /**
   * Function: HandleorderStatus
   * Description:
   * Handles the update of order status, such as transitioning from "payment_complete" to "Pickup"
   * or "pickup" to "Return." Triggers an API request to update the order status and handles success
   * or error scenarios. Also, updates the alert message and type accordingly and triggers a refresh
   * of the orders view.
   */
  // Update order status
  const HandleorderStatus = async () => {
    try {
      setLoading(true);

      // Extract order details from OrderStatusObj
      let order_id = OrderStatusObj.order_id;
      let renter_id = OrderStatusObj.renter_id;
      let order_status = OrderStatusObj.order_status;

      // Set the title based on the new order status
      let title = "";
      if (order_status == "payment_complete") {
        title = "Pickup";
      } else if (order_status == "pickup") {
        title = "Return";
      } else if (order_status == "returned") {
        title = "";
      }

      // Call the API to update the order status
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const OrderStatus = await EquipmentAPIs.UpdateOrderStatus(
        {
          user_id: UserID,
          order_id: order_id.toString(),
          owner_id: UserID.toString(),
          renter_id: renter_id.toString(),
          title: title,
        },
        headers
      );

      // Check if the order status update was successful
      if (OrderStatus["type"] == "RXSUCCESS") {
        // Reset flags and show success alert
        setOrderStatusChange(false);

        dispatch(setalertMessage(OrderStatus["message"]));
        dispatch(setalertMessagetype("success"));
        setLoading(false);
        viewOrders(ActiveEquip["id"], AuthToken);
      } else {
        // Show error alert if the update fails
        setLoading(false);
        setOrderStatusChange(false);
        dispatch(setalertMessage(OrderStatus["message"]));
        dispatch(setalertMessagetype("error"));

        // Reset OrderStatusObj
        setOrderStatusObj({
          order_id: "",
          renter_id: "",
          order_status: "",
        });
      }
    } catch (err) {
      // Handle errors during the order status update
      setLoading(false);
      setOrderStatusChange(false);

      // Reset OrderStatusObj
      setOrderStatusObj({
        order_id: "",
        renter_id: "",
        order_status: "",
      });
    }
  };

  // handle refund security Reason field
  const HandleSecurityReson = (event) => {
    var val = event.target.value;
    setRefundSecurityamtreson(val);
  };

  // Handle Refund amount field
  const HandleRefundAmount = (event) => {
    var val = event.target.value;
    // const isValidInput = /^\d+(\.\d{0,2})?$/.test(val);
    if (val === "" || /^\d+(\.\d{0,2})?$/.test(val)) {
      setRefundSecurityamount(val);
    }
    // if (isValidInput) {
    //   setRefundSecurityamount(val);
    // }
    // if (val > RefundSecurityamt) {
    //   dispatch(setalertMessage("Refund amount cannot be greater than security deposit."));
    //   dispatch(setalertMessagetype('error'));
    //   return false;
    // } else {
    // setRefundSecurityamount(val);
    //}
  };

  /**
   * Function: securityRefund
   * Description:
   * Initiates the process of refunding the security deposit for a specific order.
   * Validates the refund amount and reason before triggering the API request.
   * Handles success and error scenarios, updating the alert message and type accordingly.
   * Additionally, triggers a refresh of the orders view upon successful refund.
   */
  // Refund Order Security
  const securityRefund = async () => {
    try {
      setLoading(true);

      // Extract refund details
      let ordersecurity = RefundSecurityamt;
      let amount = parseFloat(RefundSecurityamount);
      let resion = RefundSecurityamtreson;

      // Validate the refund amount
      // if (amount == null) {
      // above line commented and next line added by pp on 9 Apr 2024 due to placeholder changes
      if (amount == null || isNaN(amount)) {
        dispatch(setalertMessage("Please fill security amount."));
        dispatch(setalertMessagetype("error"));
        setLoading(false);
        return false;
      }

      // Validate that the refund amount is not greater than the security deposit
      if (amount > ordersecurity) {
        dispatch(
          setalertMessage(
            "Refund amount cannot be greater than security deposit."
          )
        );
        dispatch(setalertMessagetype("error"));
        setLoading(false);
        return false;
      } else if (amount < ordersecurity && resion == "") {
        // Validate that a reason is provided for partial refunds
        // console.log("in resion else");
        dispatch(setalertMessage("Please enter the reason."));
        dispatch(setalertMessagetype("error"));
        setLoading(false);
        return false;
      } else {
        // Call the API to initiate the security refund
        const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
        const RefundStatus = await EquipmentAPIs.RefundSecurity(
          {
            owner_id: UserID.toString(),
            order_id: orderID.toString(),
            description: resion.toString(),
            amount: amount.toString(),
          },
          headers
        );

        // Check if the refund process was successful
        if (RefundStatus["type"] == "RXSUCCESS") {
          // Display success message, refresh orders view, and reset flags
          dispatch(
            setalertMessage("$" + amount + " Security refunded successfully.")
          );
          dispatch(setalertMessagetype("success"));
          viewOrders(ActiveEquip["id"], AuthToken);
          setRefundSecurityamount("");
          setRefundSecurityamtreson("");
          setLoading(false);
          setRefundSecuritypopup(false);
        } else {
          // Show error message if the refund process fails
          setOrderStatusChange(false);
          dispatch(setalertMessage(RefundStatus["message"]));
          dispatch(setalertMessagetype("error"));
          setorderID(null);
          setLoading(false);
        }
      }
    } catch (err) {
      // Handle errors during the security refund process
      setLoading(false);
    }
  };

  const [OrderDetailPopData, setOrderDetailPopData] = useState({
    //"thumbnail": "",
    id: "",
    make: "",
    model: "",
    start_date: "",
    start_time: "",
    end_date: "",
    end_time: "",
    profile_pic: "",
    first_name: "",
    order_status: "",
    order_from: "",
    rental_price: 0,
    minimum_delivery_fees: 0,
    security_deposit_refund_txt: "",
    security_deposit: 0,
    grand_total_txt: "",
    grand_total: 0,
    protection_fee: 0,
    transport_fee: 0,
    admin_fee: 0,
    side: "owner",
  });

  /**
   * Function: HandleOrderDetail
   * Description:
   * Displays the detailed information of a specific order in a popup.
   * Retrieves and formats various details including equipment details, order dates, user information,
   * fees, and totals. Handles cases of refunded security deposit and canceled orders.
   * Populates the order detail popup with relevant data for the owner's view.
   */
  const HandleOrderDetail = async (orderObj) => {
    setOrderDetailpopup(true);

    // Extract extended orders and set active order list
    let ex_orders = orderObj["order_extend"];
    setActiveOrder([]);
    if (ex_orders != null) {
      setActiveOrder(ex_orders);
    }

    // Prepare user information for display
    let user_info = {
      profile_pic: orderObj["profile_pic"]
        ? orderObj["profile_pic"]
        : ProfilePic,
      first_name: orderObj["first_name"] ? orderObj["first_name"] : "NA",
    };
    setUserInfo(user_info);

    // Calculate refunded protection, security deposit, protection fee, transport fee, admin fee, and grand total
    let refunded_protection =
      orderObj["refunded_protection"] != undefined ||
      orderObj["refunded_protection"] != ""
        ? orderObj["refunded_protection"]
        : "";

    let security =
      orderObj["returnd_deposit"] != undefined ||
      orderObj["returnd_deposit"] != null
        ? orderObj["security_deposit"] - orderObj["returnd_deposit"]
        : orderObj["security_deposit"];
    let protection_fee =
      orderObj["protection_fees_refunded"] == "1"
        ? orderObj["equipment_protection_fees"] -
          (refunded_protection == ""
            ? orderObj["equipment_protection_fees"]
            : refunded_protection)
        : orderObj["equipment_protection_fees"];
    let transaport =
      orderObj["delivery_charges"] * 2 + orderObj["minimum_delivery_fees"];

    let adminfee =
      orderObj["admin_commission"] +
      (orderObj["minimum_delivery_fees"] != undefined &&
        orderObj["minimum_delivery_fees"] != null &&
        orderObj["minimum_delivery_fees"] != "0" &&
        orderObj["delivery_address"] != "" &&
        parseFloat(orderObj["admin_transport_commission"]));

    let grandTotal =
      orderObj["protection_fees_refunded"] != "1"
        ? orderObj["admin_commission"] != null
          ? orderObj["total_price"]
          : orderObj["total_price"]
        : orderObj["admin_commission"] != null
        ? orderObj["total_price"] -
          (orderObj["order_status"] == "order_canceled"
            ? orderObj["security_deposit"]
            : 0)
        : orderObj["total_price"];

    // Set data for order detail popup
    setOrderDetailPopData({
      //"thumbnail": EquipData["thumbnail"],

      id: "Order ID #" + orderObj["id"],
      make: EquipData["make"] ? EquipData["make"] : "NA",
      model: EquipData["model"] ? EquipData["model"] : "",
      start_date: orderObj["start_date"],
      start_time: orderObj["start_time"],
      end_date: orderObj["end_date"],
      end_time: orderObj["end_time"],
      profile_pic: orderObj["profile_pic"]
        ? orderObj["profile_pic"]
        : ProfilePic,
      first_name: orderObj["first_name"] ? orderObj["first_name"] : "NA",
      order_status: orderObj["order_status"],
      order_from: orderObj["delivery_address"] == "" ? "Renter" : "Owner",
      rental_price: orderObj["price"],
      minimum_delivery_fees: orderObj["minimum_delivery_fees"],
      security_deposit_refund_txt:
        orderObj["returnd_deposit"] != undefined ||
        orderObj["returnd_deposit"] != null
          ? "(Refunded" +
            "-$" +
            new Intl.NumberFormat("en-US", {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }).format(orderObj["returnd_deposit"]) +
            ")"
          : "",
      security_deposit: security,
      grand_total_txt:
        orderObj["order_status"] == "order_canceled" ? "(Refunded)" : "",
      grand_total: grandTotal,
      protection_fee: protection_fee,
      transport_fee: transaport,
      admin_fee: adminfee,
      side: "owner",
    });
    //setloadingMyorder(false);
    //setLoading(false);
  };

  /**
   * Function: HandleProjectLocation
   *
   * Description:
   * Opens a navigation link for the equipment's project location based on the delivery address.
   * If the delivery address is empty, displays an information message.
   * @param {object} obj - The object containing information about the equipment, including delivery address and location coordinates.
   * @returns {boolean} - Returns false if the delivery address is empty; otherwise, opens a navigation link in a new tab.
   */
  const HandleProjectLocation = (obj) => {
    // Check if the delivery address is empty
    if (obj.delivery_address == "") {
      // Display an information message if the delivery address is empty
      dispatch(setalertMessage("Equipment to be Picked Up."));
      dispatch(setalertMessagetype("info"));
      return false;
    } else {
      // Extract equipment and project location coordinates
      let lat = EquipLocation.lat;
      let lng = EquipLocation.lng;
      let _lat = obj.delivery_lat;
      let _lng = obj.delivery_lng;

      // Generate navigation links for Apple Maps and Google Maps
      let apple_map =
        "http://maps.apple.com/?saddr=" +
        lat +
        "," +
        lng +
        "&daddr=" +
        _lat +
        "," +
        _lng;

      let google_map =
        "https://www.google.com/maps/dir/?api=1&origin=" +
        lat +
        "," +
        lng +
        "&destination=" +
        _lat +
        "," +
        _lng +
        "&travelmode=driving";

      // Open the Google Maps navigation link in a new tab
      window.open(google_map, "_blank");
    }
  };

  /**
   * Function: HandleRenterImages
   *
   * Description:
   * Handles the retrieval and display of renter images based on the specified type (pickedup or dropoff).
   * If images are available, sets the appropriate state variables for displaying the images.
   * If no images are available, displays an error message.
   *
   * @param {string} type - The type of images to retrieve, either "pickedup" or "dropoff".
   * @param {object} Obkitem - The object containing information about the equipment item, including renter images.
   */
  const HandleRenterImages = (type, Obkitem) => {
    //setLoading(true)
    if (type == "pickedup") {
      // Check if picked up photos are available
      if (Obkitem["renter_images"] != null) {
        // Set state variables for displaying picked up photos
        setRenterPickupImagesArr([]);
        setRenterPickupImages(true);
        let imges = Obkitem["renter_images"].split(",");
        setReturnImageTitle("Pickup Photos");
        setRenterPickupImagesArr(imges);
      } else {
        // Display an error message if picked up photos are not available
        dispatch(setalertMessage("Picked up photos not available."));
        dispatch(setalertMessagetype("error"));
      }
    } else {
      // Check if dropoff photos are available
      if (Obkitem["return_renter_images"] != null) {
        setRenterPickupImagesArr([]);
        setRenterPickupImages(true);
        let imges = Obkitem["return_renter_images"].split(",");
        setReturnImageTitle("Dropoff Photos");
        setRenterPickupImagesArr(imges);
      } else {
        // Display an error message if dropoff photos are not available
        dispatch(setalertMessage("Dropoff photos not available."));
        dispatch(setalertMessagetype("error"));
      }
    }

    // Add a delay before setting loading to false (simulating an asynchronous operation)
    setTimeout(() => {
      //setLoading(false)
    }, 900);
  };

  /**
   * Function: HandleChatOwnerToRenter
   * Description:
   * Initiates a chat between the equipment owner and renter.
   * Retrieves necessary information such as sender and receiver details, equipment make and model.
   * Sets the chat data object and triggers the chat click event to open the chat.
   *
   * @param {string} user_id - The ID of the renter with whom the chat is initiated.
   * @param {string} reciverName - The name of the chat receiver (renter).
   * @param {string} equip_id - The ID of the equipment associated with the chat.
   */
  const HandleChatOwnerToRenter = (user_id, reciverName, equip_id) => {
    // Get the sender ID (current user ID)
    let senderID = UserID;

    // Get equipment make and model, default to "Not Available" if not present
    let EquipMake = EquipData["make"] ? EquipData["make"] : "Not Available";
    let EquipModel = EquipData["model"] ? EquipData["model"] : "Not Available";

    // Create a chat data object with necessary details
    let ChatObj = {
      equipId: equip_id,
      senderID: senderID,
      reciverID: user_id,
      reciverName: reciverName,
      EquipMake: EquipMake,
      EquipModel: EquipModel,
    };
    localStorage.setItem("ChatFromEquipDetail", JSON.stringify(ChatObj));
    //ChatClick(ChatObj)

    // Redirect the user to the notifications page for chat interaction
    window.location.href = "/notifications";
    // Set the chat data object
    setChatData(ChatObj);

    // Trigger the chat click event to open the chat
    ChatClick(ChatObj);
    //setChatOwnerToRenter(true)
  };

  // Cancel Order
  const [OrderCancelPopup, setOrderCancelPopup] = React.useState(false);
  const [OrderCancelId, setOrderCancelId] = React.useState(null);

  /**
   * Function: CancelOrderByOwner
   * Description:
   * Cancels the order by the equipment owner and handles success or error scenarios.
   * Validates the cancellation reason and dispatches appropriate alerts.
   * Triggers a refresh of orders after successful cancellation.
   */
  const CancelOrderByOwner = async () => {
    try {
      //setLoading(true);
      // let reason = ExtendOrderCancelIdreason;

      // Check if a cancellation reason is provided
      if (reason == "" || reason == null) {
        dispatch(setalertMessage("Please add reason for cancellation."));
        dispatch(setalertMessagetype("error"));
        setLoading(false);
        return false;
      }

      // Prepare data for order cancellation
      let data = {
        order_id: OrderCancelId.toString(),
        owner_id: UserID.toString(),
        reason: reason,
      };

      // Call the API to cancel the order
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const Result = await EquipmentAPIs.CancelOrderByOwner(data, headers);

      // Check if the cancellation was successful
      if (Result["type"] == "RXSUCCESS") {
        // Reset the reason variable
        reason = "";

        // Trigger a success alert and refresh orders
        Successres("success");
        //Successres({'status':'success', 'type':'','from':'rent'})
        dispatch(setalertMessage("Order Cancel Successfully."));
        dispatch(setalertMessagetype("success"));
        viewOrders(ActiveEquip["id"], AuthToken);
        setLoading(false);

        // Close the order cancellation popup
        setOrderCancelPopup(false);
      } else {
        // Display an error message if cancellation fails
        dispatch(setalertMessage(Result["message"]));
        dispatch(setalertMessagetype("error"));
        setLoading(false);
        return false;
      }
    } catch (err) {
      // Handle errors that occur during the cancellation process
      console.log(err);
      setLoading(false);
    }
  };

  const handleOrderTabNavigation = () => {
    navigate(`/equipmentOrders/${ActiveEquip?.id}/active`);
  };

  /**
   * useEffect: Notifications and Active Equipment Updates
   * Description:
   * This effect hook is triggered whenever there are updates to the NotificationsList or ActiveEquip.
   * It is responsible for handling new messages and updating equipment-related data.
   * If there are new notifications, it extracts the relevant information to create a new chat message.
   * If there is an active equipment, it updates the EquipData, EquipLocation, and triggers a viewOrders refresh.
   */
  useEffect(() => {
    // console.log("active equipm worked: " , ActiveEquip);

    // Check if there are new notifications
    if (NotificationsList != null && NotificationsList.length > 0) {
      // Extract information from the latest notification
      let msg = [...NotificationsList];
      let msg_Obj = msg[0];
      let newmessage = {
        reciverID: msg_Obj["sender_id"],
        senderID: msg_Obj["recever_id"],
        equipId: ChatData["equipId"],
        reciverName: ChatData["reciverName"],
        EquipMake: ChatData["EquipMake"],
      };
      setNewMsg(newmessage);
    }

    // Check if there is an active equipment
    // by GD added ActiveEquip in if due to id undefined error in viewOrders 5 jan 2024
    if (ActiveEquip && ActiveEquip != null) {
      // Update equipment-related data
      setEquipData(ActiveEquip);
      setEquipLocation({
        lat: ActiveEquip["lat"],
        lng: ActiveEquip["lng"],
      });

      // Trigger a refresh of orders related to the active equipment
      viewOrders(ActiveEquip["id"], AuthToken);
    }
    //}
  }, [ActiveEquip]); // NotificationsList

  return (
    <>
      {/* {loading && (
        <Box>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Box>
      )} */}
      {/* {!loading && ( */}
      <>
        <Box className="rentyrd-grid-item rentyrd-right">
          <Box className="rentyrd-left-float">
            <Box className="equipment-details-heading">
              <Typography>Equipment Details </Typography>
            </Box>

            <Box className="rentyrd-card">
              <Box
                // className="rentyrd-card-grid"
                sx={{
                  display: "flex",
                  flexDirection: {
                    xs: "column",
                    sm: "row",
                    md: "row",
                    lg: "row",
                    xl: "row",
                  },
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <Box
                  className="rentyrd-card-item-left"
                  sx={{
                    width: {
                      xs: "100%",
                      sm: "auto",
                      md: "auto",
                      lg: "auto",
                      xl: "auto",
                    },
                  }}
                >
                  <Box
                    // className="rentyrd-card-img"
                    sx={{ position: "relative", width: "100%" }}
                  >
                    <Box
                      component={"img"}
                      src={EquipData["thumbnail"]}
                      alt=""
                      sx={{
                        width: {
                          xs: "100%",
                          sm: "200px",
                          md: "250px",
                          lg: "180px",
                          xl: "200px",
                        },
                        height: {
                          xs: "195px",
                          sm: "150px",
                          md: "187px",
                          lg: "135px",
                          xl: "150px",
                        },
                        objectFit: "cover",
                      }}
                    />
                    {/* <img
                      className="eqppmnt-img"
                      src={EquipData["thumbnail"]}
                      alt=""
                    /> */}
                  </Box>
                </Box>
                <Box
                  // className="rentyrd-card-item-right"
                  sx={{ width: "100%" }}
                >
                  <Box
                  // className="rentyrd-right"
                  >
                    <Typography
                      // className="vehicle-model"
                      sx={{
                        display: "-webkit-box",
                        overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 1,
                        color: "#fff",
                        fontSize: {
                          xs: "18px",
                          sm: "19.5px",
                          md: "21px",
                          lg: "23px",
                          xl: "25px",
                        },
                        fontFamily: "GothamBold !important",
                      }}
                      title={
                        EquipData["make"] ? EquipData["make"] : "Not Available"
                      }
                    >
                      {EquipData["make"] ? EquipData["make"] : "Not Available"}
                    </Typography>
                    <Typography
                      // className="vehicle-type"
                      sx={{
                        display: "-webkit-box",
                        overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 1,
                        color: "#faa61a",
                        fontSize: {
                          xs: "14px",
                          sm: "15px",
                          md: "17px",
                          lg: "18.5px",
                          xl: "20px",
                        },
                      }}
                    >
                      {EquipData["model"]
                        ? EquipData["model"]
                        : "Not Available"}
                    </Typography>
                    <Box
                      // className="rentyrd-address"
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "0.5rem",
                        padding: "0.5rem 0",
                        borderBottom: "2px solid gray",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: {
                            xs: "12px",
                            sm: "13px",
                            md: "13px",
                            lg: "14px",
                            xl: "14px",
                          },
                        }}
                      >
                        {EquipData["city"] ? EquipData["city"] : "NA"}
                        {EquipData["state"]
                          ? EquipData["state"].length > 20
                            ? ", " +
                              EquipData["state"].substring(0, 20) +
                              "... "
                            : ", " + EquipData["state"]
                          : ""}
                      </Typography>
                      {EquipData["owner_pickup_return"] == "1" ? (
                        <Typography
                          sx={{
                            color: "#fff",
                            fontSize: {
                              xs: "12px",
                              sm: "13px",
                              md: "13px",
                              lg: "14px",
                              xl: "14px",
                            },
                          }}
                        >
                          Delivery Available
                        </Typography>
                      ) : (
                        <Typography
                          sx={{
                            color: "#fff",
                            fontSize: {
                              xs: "12px",
                              sm: "13px",
                              md: "13px",
                              lg: "14px",
                              xl: "14px",
                            },
                          }}
                        ></Typography>
                      )}
                    </Box>
                    <Box
                      // className="rentyrd-rates"
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "repeat(3, 1fr)",
                        padding: "0.5rem 0",
                        gap: "0",
                      }}
                    >
                      <Box
                        // className="rentyrd-rates-item"
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#fff",
                            fontSize: {
                              xs: "14px",
                              sm: "16px",
                              md: "18px",
                              lg: "20px",
                              xl: "22px",
                            },
                            fontFamily: "GothamBold !important",
                          }}
                        >
                          $
                          <CurrentFormat
                            Currency={EquipData["price_month"]}
                            trunc={true}
                          />
                          <Typography
                            component={"span"}
                            // className="rentyrd-rates-span"
                            sx={{
                              color: "#faa61a",
                              fontFamily: "Gotham !important",
                              fontSize: {
                                xs: "10px",
                                sm: "11px",
                                md: "12px",
                                lg: "13px",
                                xl: "14px",
                              },
                            }}
                          >
                            /m
                          </Typography>
                        </Typography>
                      </Box>
                      <Box
                        // className="rentyrd-rates-item"
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          borderLeft: "2px solid gray",
                          borderRight: "2px solid gray",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#fff",
                            fontSize: {
                              xs: "14px",
                              sm: "16px",
                              md: "18px",
                              lg: "20px",
                              xl: "22px",
                            },
                            fontFamily: "GothamBold !important",
                          }}
                        >
                          $
                          <CurrentFormat
                            Currency={EquipData["price_per_week"]}
                            trunc={true}
                          />
                          <Typography
                            component={"span"}
                            // className="rentyrd-rates-span"
                            sx={{
                              color: "#faa61a",
                              fontFamily: "Gotham !important",
                              fontSize: {
                                xs: "10px",
                                sm: "11px",
                                md: "12px",
                                lg: "13px",
                                xl: "14px",
                              },
                            }}
                          >
                            /w
                          </Typography>
                        </Typography>
                      </Box>
                      <Box
                        // className="rentyrd-rates-item"
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#fff",
                            fontSize: {
                              xs: "14px",
                              sm: "16px",
                              md: "18px",
                              lg: "20px",
                              xl: "22px",
                            },
                            fontFamily: "GothamBold !important",
                          }}
                        >
                          $
                          <CurrentFormat
                            Currency={EquipData["price_day"]}
                            trunc={true}
                          />
                          <Typography
                            component={"span"}
                            // className="rentyrd-rates-span"
                            sx={{
                              color: "#faa61a",
                              fontFamily: "Gotham !important",
                              fontSize: {
                                xs: "10px",
                                sm: "11px",
                                md: "12px",
                                lg: "13px",
                                xl: "14px",
                              },
                            }}
                          >
                            /d
                          </Typography>
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box className="edit-listing">
              <Button
                onClick={() => {
                  setEditClick(true);
                }}
                className="edit-listing-btn"
                sx={{ fontFamily: "Montserrat !important", fontWeight: "700" }}
              >
                EDIT THIS LISTING
              </Button>
            </Box>

            {loadingMyorder ? (
              <>
                {/* <Box>
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={loadingMyorder}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
                <Box sx={{ mt: 5 ,textAlign:"center"}}>
                  <Typography
                    sx={{
                      color: "#faa61a",
                       textDecorationLine: "underline",
                       textDecorationColor: "#faa61a",
                      fontSize: {
                        xs: "20px",
                        sm: "25px",
                        md: "30px",
                        lg: "33px",
                        xl: "35px",
                      },
                      fontFamily: "GothamBold !important",
                       cursor: "pointer",
                    }}
                  >
                    Loading...
                  </Typography>
                </Box>
              </Box> */}
              </>
            ) : (
              <>
                {MyOrders && MyOrders.length > 0 ? (
                  <>
                    {/* <Box className="active-rental">
                     <Typography className="active-rental-heading">
                       Active Rentals
                     </Typography>
                   </Box> */}
                    {/* <Box sx={{ mt: 5 ,textAlign:"center"}}>
                      <Typography
                        sx={{
                          color: "#faa61a",
                          textDecorationLine: "underline",
                          textDecorationColor: "#faa61a",
                          fontSize: {
                            xs: "20px",
                            sm: "25px",
                            md: "30px",
                            lg: "33px",
                            xl: "35px",
                          },
                          fontFamily: "GothamBold !important",
                          cursor: "pointer",
                        }}
                        onClick={()=>{
                          navigate(`/equipmentOrders/${MyOrders[0]?.equip_id}/active`)
                          // setActiveStep(9)
                          // reloadTabUpdate()
                        }}
                      >
                        View Active Orders
                      </Typography>
                    </Box> */}
                    {/* <hr className="gray-line" /> */}
                    {/* <Box
                     style={{
                       maxHeight: "500px",
                       overflowY: "scroll",
                       paddingRight: "5px",
                     }}
                   >
                     {MyOrders.map((orderdata, indx) => (
                       <>
                         <Box className="rentyrd-order">
                           <Link
                             className="rentyrd-order-id"
                             onClick={() => HandleOrderDetail(orderdata)}
                             style={{ cursor: "pointer" }}
                           >
                             Order ID #{orderdata["order_id"]}
                           </Link>

                           <Box className="rentyrd-dates">
                             <Box>
                               <Typography className="rentyrd-dates-heading">
                                 RENTAL DATES
                               </Typography>
                               <Box className="rentyrd-dates-item rental-dates">
                                 <Typography>
                                   <span>Start:</span>
                                   <span>
                                     {moment(orderdata["start_date"], [
                                       "YYYY-MM-DD",
                                     ]).format("MM/DD/YYYY")}{" "}
                                     {moment(orderdata["start_time"], [
                                       "HH:mm:ii",
                                     ]).format("hh:mm a")}
                                   </span>
                                 </Typography>
                                 <Typography>
                                   <span>End:</span>
                                   <span>
                                     {moment(orderdata["end_date"], [
                                       "YYYY-MM-DD",
                                     ]).format("MM/DD/YYYY")}{" "}
                                     {moment(orderdata["end_time"], [
                                       "HH:mm:ii",
                                     ]).format("hh:mm a")}
                                   </span>
                                 </Typography>
                               </Box>
                             </Box>

                             <Box className="rentyrd-order-status">
                               {orderdata["order_status"] ==
                               "payment_complete" ? (
                                 <Typography className="od-status-green">
                                   Order Placed
                                 </Typography>
                               ) : orderdata["order_status"] == "pickup" ? (
                                 <Typography className="od-status-green">
                                   Picked Up
                                 </Typography>
                               ) : orderdata["order_status"] == "returned" ? (
                                 <Typography className="od-status-green">
                                   Returned
                                 </Typography>
                               ) : orderdata["order_status"] == "completed" ? (
                                 <Typography className="od-status-green">
                                   Completed
                                 </Typography>
                               ) : orderdata["order_status"] ==
                                 "security_settled" ? (
                                 <Typography className="od-status-red">
                                   Security Settled
                                 </Typography>
                               ) : orderdata["order_status"] == "disputed" ? (
                                 <Typography className="od-status-red">
                                   disputed
                                 </Typography>
                               ) : orderdata["order_status"] ==
                                 "dispute_settled" ? (
                                 <Typography className="od-status-red">
                                   Dispute Sttled
                                 </Typography>
                               ) : (
                                 <Typography className="od-status-yellow">
                                   Order Returned
                                 </Typography>
                               )}

                               <Typography
                                 className="update-status-link"
                                 onClick={() => (
                                   HandleOrderStatusPopup(
                                     orderdata["order_id"],
                                     orderdata["user_id"],
                                     orderdata["order_status"],
                                     orderdata["security_deposit"],
                                     orderdata
                                   ),
                                   setorderID(orderdata["order_id"])
                                 )}
                                 style={{ cursor: "pointer" }}
                               >
                                 update status
                               </Typography>

                               {orderdata["start_date"] <= today &&
                               orderdata["order_status"] ==
                                 "payment_complete" ? (
                                 <Typography style={{ color: "red" }}>
                                   Action Needed
                                 </Typography>
                               ) : orderdata["end_date"] <= today &&
                                 (orderdata["order_status"] ==
                                   "payment_complete" ||
                                   orderdata["order_status"] == "pickup" ||
                                   orderdata["order_status"] == "returned") ? (
                                 <Typography style={{ color: "red" }}>
                                   Action Needed
                                 </Typography>
                               ) : (
                                 ""
                               )}
                             </Box>
                           </Box>

                           <Box className="rentyrd-renter-detail">
                             <Box className="renter-detail">
                               <Box className="ro-img">
                                 <img
                                   src={
                                     orderdata["profile_pic"]
                                       ? orderdata["profile_pic"]
                                       : ProfilePic
                                   }
                                   alt=""
                                 />
                               </Box>
                               <Box className="ro-name">
                                 <Typography>
                                   <span style={{ color: "#fff" }}>
                                     Renter:
                                   </span>
                                   <span
                                     style={{
                                       cursor: "pointer",
                                       color: "#faa61a",
                                       textDecoration: "underline",
                                     }}
                                     onClick={() => {
                                       setOwnerInfo(orderdata);
                                       setOwnerInfoPopup(true);
                                     }}
                                   >
                                     {orderdata["first_name"]
                                       .charAt(0)
                                       .toUpperCase() +
                                       orderdata["first_name"].slice(1)}{" "}
                                     {orderdata["last_name"]}
                                   </span>
                                 </Typography>
                                 <Link
                                   style={{ cursor: "pointer" }}
                                   className="message-renter"
                                   onClick={() => {
                                     HandleChatOwnerToRenter(
                                       orderdata["user_id"],
                                       orderdata["first_name"],
                                       orderdata["equip_id"]
                                     );
                                   }}
                                 >
                                   Message Renter
                                 </Link>
                               </Box>
                             </Box>
                           </Box>

                           <Box className="rentyrd-order-links">
                             <Link
                               href="javascript:void(0)"
                               onClick={() => {
                                 HandleProjectLocation(orderdata);
                               }}
                             >
                               Project Location
                             </Link>
                             <Link
                               href="javascript:void(0)"
                               onClick={() => {
                                 HandleRenterImages("pickedup", orderdata);
                               }}
                             >
                               Pickup Photos
                             </Link>
                             <Link
                               href="javascript:void(0)"
                               onClick={() => {
                                 HandleRenterImages("dropoff", orderdata);
                               }}
                             >
                               Dropoff Photos
                             </Link>
                           </Box>

                           <Box>
                             {orderdata["order_status"] ==
                               "payment_complete" && (
                               <Typography
                                 sx={{
                                   color: "#c21414",
                                   cursor: "pointer",
                                   textDecorationLine: "underline",
                                   textDecorationColor: "#c21414",
                                   padding: "2px 8px",
                                   borderRadius: "5px",
                                   fontFamily: "GothamBold !important",
                                 }}
                                 onClick={() => {
                                   reason = "";
                                   setOrderCancelId(orderdata["order_id"]);
                                   setOrderCancelPopup(true);
                                 }}
                               >
                                 X&nbsp;Cancel Request
                               </Typography>
                             )}
                           </Box>
                         </Box>
                         {MyOrders.length != indx + 1 && (
                           <hr className="gray-line" />
                         )}
                       </>
                     ))}
                   </Box> */}
                  </>
                ) : (
                  <>
                    {/* <Box className="active-rental">
                      <Typography className="active-rental-heading">
                        No Active Rentals
                      </Typography>
                    </Box>
                    <hr className="gray-line" /> */}
                  </>
                )}
              </>
            )}

            {ActiveEquip ? (
              ActiveEquip.orders_requests >= 1 ||
              ActiveEquip.active_orders >= 1 ? (
                <Box sx={{ mt: 5, textAlign: "center" }}>
                  <Button
                    sx={{
                      border: "2px solid #faa61a",
                      background: "#008001",
                      textTransform: "capitalize",
                      color: "#fff",
                      fontFamily: "Montserrat !important",
                      borderRadius: "8px",
                      fontWeight: "800",
                      padding: "7px 15px",
                      fontSize: {
                        xs: "12px",
                        sm: "13px",
                        md: "14px",
                        lg: "16px",
                        xl: "18px",
                      },
                      "&:hover": {
                        background: "#006001",
                      },
                    }}
                    onClick={handleOrderTabNavigation}
                  >
                    View Requests/Active Orders
                  </Button>
                </Box>
              ) : null
            ) : // ActiveEquip.orders_requests >= 1 ? (
            //   <Box sx={{ mt: 5, textAlign: "center" }}>
            //     <Typography
            //       sx={{
            //         color: "#faa61a",
            //         textDecorationLine: "underline",
            //         textDecorationColor: "#faa61a",
            //         fontSize: {
            //           xs: "20px",
            //           sm: "25px",
            //           md: "30px",
            //           lg: "33px",
            //           xl: "35px",
            //         },
            //         fontFamily: "GothamBold !important",
            //         cursor: "pointer",
            //       }}
            //       onClick={handleOrderTabNavigation}
            //     >
            //       View Requests Orders
            //     </Typography>
            //   </Box>
            // ) : ActiveEquip.active_orders >= 1 ? (
            //   <Box sx={{ mt: 5, textAlign: "center" }}>
            //     <Typography
            //       sx={{
            //         color: "#faa61a",
            //         textDecorationLine: "underline",
            //         textDecorationColor: "#faa61a",
            //         fontSize: {
            //           xs: "20px",
            //           sm: "25px",
            //           md: "30px",
            //           lg: "33px",
            //           xl: "35px",
            //         },
            //         fontFamily: "GothamBold !important",
            //         cursor: "pointer",
            //       }}
            //       onClick={handleOrderTabNavigation}
            //     >
            //       View Active Orders
            //     </Typography>
            //   </Box>
            // ) :
            null}

            <Box className="active-rental edit-listing-heading">
              <Typography className="active-rental-heading">
                Edit YOUR Equipment Listing
              </Typography>
            </Box>

            <hr className="gray-line" />

            {ActiveEquip != null && (
              <EditEquipment
                Ref_EquipId={ActiveEquip["id"]}
                OnSuccess={(e) => {
                  Successres(e);
                  setEditClick(false);
                }}
                EditClick={EditClick}
                UpdateTab={(e) => {
                  console.log("new rental detail page", e);
                  UpdateTab(e);
                }}
                // by GD for hide and show saleit button
                equipType={ActiveEquip.equip_list_type}
                // for reload api on delete equiipment
                reloadTabUpdate={reloadTabUpdate}

                // end by GD
              />
            )}
          </Box>
        </Box>

        {/* Order Status Update Popup */}
        <Modal
          keepMounted
          open={OrderStatusChange}
          onClose={() => setOrderStatusChange(false)}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box
            className="download-popups"
            sx={{
              ...loginstyle,
              // width: matchesMobileX ? "calc(100vw - 100px)" : 800,
              padding: "0px",
            }}
          >
            <Box className="login__main">
              <Box
                className="login__main-div edit-eqp"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Box className="login__main-div__form pupup-scroll edit-eqp-inner">
                  <span onClick={() => setOrderStatusChange(false)}>
                    <CloseIcon className="modal-popup" />
                  </span>
                  <Typography variant="h5">
                    {OrderStatusObj.order_status == "payment_complete"
                      ? "Pickup"
                      : "Return"}
                  </Typography>

                  <Box>
                    {loading && (
                      <Box>
                        <Backdrop
                          sx={{
                            color: "#fff",
                            zIndex: (theme) => theme.zIndex.drawer + 1,
                          }}
                          open={loading}
                        >
                          <CircularProgress color="inherit" />
                        </Backdrop>
                      </Box>
                    )}

                    <Box
                      className="active-rentals-headings"
                      sx={{
                        fontSize: "7px !important",
                        alignItems: "center",
                        color: "#fff",
                      }}
                    >
                      <Typography>
                        {" "}
                        {OrderStatusObj.order_status == "payment_complete"
                          ? "Item picked up or delivered?"
                          : "Item Returned?"}
                      </Typography>
                    </Box>
                    <Box className="pagination-section">
                      <Box
                        className="btn-load-more"
                        sx={{
                          color: "#faa61a !important",
                          padding: "6px 50px 6px 50px",
                        }}
                        onClick={() => (
                          setOrderStatusChange(false),
                          setOrderStatusObj({
                            order_id: "",
                            renter_id: "",
                            order_status: "",
                          })
                        )}
                      >
                        No
                      </Box>
                      &nbsp;&nbsp;
                      <Box
                        className="btn-load-more"
                        sx={{
                          color: "#faa61a !important",
                          padding: "6px 50px 6px 50px",
                        }}
                        onClick={() => HandleorderStatus()}
                      >
                        Yes
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>

        {/* Order Detail Popup Section */}
        <Modal
          keepMounted
          open={OrderDetailpopup}
          onClose={() => setOrderDetailpopup(false)}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box
            sx={{
              position: "absolute" as "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: {
                xs: "78%",
                sm: "400px",
                md: "500px",
                lg: "500px",
                xl: "500px",
              },
              bgcolor: "#2e2e2e",
              border: "5px solid #faa61a",
              borderRadius: "10px",
              outline: "none !important",
              p: 3,
            }}
            // className="download-popups"
          >
            <IconButton
              onClick={() => setOrderDetailpopup(false)}
              sx={{
                position: "absolute",
                top: "-20px",
                right: "-20px",
                bgcolor: "#faa61a",
                color: "#000",
                "&:hover": {
                  bgcolor: "#faa61a",
                },
              }}
            >
              <CloseIcon />
            </IconButton>

            <Box
              sx={{ display: "flex", flexDirection: "column", width: "100%" }}
            >
              <Typography variant="h5" sx={{ color: "#fff", mb: 2 }}>
                <span style={{ color: "#faa61a" }}>Order</span>Detail
              </Typography>

              <Box className="extend-rental-od">
                {loading && (
                  <Box>
                    <Backdrop
                      sx={{
                        color: "#fff",
                        zIndex: (theme) => theme.zIndex.drawer - 1,
                      }}
                      open={loading}
                    >
                      <CircularProgress color="inherit" />
                    </Backdrop>
                  </Box>
                )}

                <Box
                  // className="vehicle-div"
                  sx={{ display: "flex", alignItems: "center", gap: "1rem" }}
                >
                  <Box
                    component={"img"}
                    src={EquipData["thumbnail"]}
                    alt=""
                    sx={{
                      border: "3px solid #fff",
                      width: {
                        xs: "110px",
                        sm: "130px",
                        md: "150px",
                        lg: "180px",
                        xl: "180px",
                      },
                      height: {
                        xs: "70px",
                        sm: "80px",
                        md: "100px",
                        lg: "120px",
                        xl: "120px",
                      },
                    }}
                  />
                  {/* <Box className="vehicle-img"> */}

                  {/* <img
                          src={EquipData["thumbnail"]}
                          alt=""
                          style={{ borderRadius: "0px !important" }}
                        /> */}
                  {/* </Box> */}
                  <Box sx={{ fontWeight: "bold" }}>
                    <Typography
                      title={
                        OrderDetailPopData.make
                          ? OrderDetailPopData.make
                          : "Not Available"
                      }
                      sx={{
                        display: "-webkit-box",
                        overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 1,
                        color: "#fff",
                      }}
                    >
                      {OrderDetailPopData.make
                        ? OrderDetailPopData.make
                        : "Not Available"}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#faa61a",
                        fontSize: {
                          xs: "13px",
                          sm: "14px",
                          md: "14px",
                          lg: "15px",
                          xl: "16px",
                        },
                      }}
                    >
                      {OrderDetailPopData.model
                        ? OrderDetailPopData.model
                        : "Not Available"}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#fff",
                        fontSize: {
                          xs: "13px",
                          sm: "14px",
                          md: "14px",
                          lg: "15px",
                          xl: "16px",
                        },
                      }}
                    >
                      {OrderDetailPopData.id}
                    </Typography>
                  </Box>
                </Box>

                <Box
                  // className="owner-div owner-div-new edit-eq-owner"
                  sx={{ display: "flex", alignItems: "center", mt: 2, mb: 2 }}
                >
                  <Box
                    sx={{ width: "50%" }}
                    // className="owner-edit-dates"
                  >
                    <Typography sx={{ color: "#fff !important" }}>
                      Rental Dates
                    </Typography>
                    <Typography
                      sx={{
                        color: "#fff !important",
                        fontSize: {
                          xs: "10px",
                          sm: "13px",
                          md: "13px",
                          lg: "14px",
                          xl: "14px",
                        },
                      }}
                      // className="modal-dates-grid"
                    >
                      <span>Start:</span>
                      <span>
                        {moment(OrderDetailPopData.start_date, [
                          "YYYY-MM-DD",
                        ]).format("MM/DD")}{" "}
                        {moment(OrderDetailPopData.start_time, [
                          "HH:mm:ii",
                        ]).format("hh:mm a")}
                      </span>
                    </Typography>
                    <Typography
                      sx={{
                        color: "#fff !important",
                        fontSize: {
                          xs: "10px",
                          sm: "13px",
                          md: "13px",
                          lg: "14px",
                          xl: "14px",
                        },
                      }}
                      // className="modal-dates-grid"
                    >
                      <span>End:</span>
                      <span>
                        {moment(OrderDetailPopData.end_date, [
                          "YYYY-MM-DD",
                        ]).format("MM/DD")}{" "}
                        {moment(OrderDetailPopData.end_time, [
                          "HH:mm:ii",
                        ]).format("hh:mm a")}
                      </span>
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "50%",
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                      gap: "1rem",
                    }}
                    // className="owner-img-grid"
                  >
                    <Box
                      component={"img"}
                      src={OrderDetailPopData.profile_pic}
                      alt=""
                      sx={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                        border: "2px solid #fff",
                      }}
                    />
                    {/* <Box className="owner-img">

                          <img
                            src={OrderDetailPopData.profile_pic}
                            alt=""
                            style={{ width: "20%" }}
                          />
                        </Box> */}

                    <Box className="ro-name">
                      <Typography
                        sx={{
                          color: "#fff !important",
                          display: "flex",
                          flexDirection: "column",
                          fontSize: {
                            xs: "13px",
                            sm: "14px",
                            md: "14px",
                            lg: "15px",
                            xl: "16px",
                          },
                        }}
                      >
                        RENTER :
                        <span className="gem-ro-name">
                          {OrderDetailPopData.first_name
                            .charAt(0)
                            .toUpperCase() +
                            OrderDetailPopData.first_name.slice(1)}
                        </span>
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <Divider
                  // className="yellow-line"
                  sx={{ borderColor: "#faa61a", borderWidth: "1px", mb: 2 }}
                />
                <Box className="equipment-rate-div">
                  <Box className="equipment-rate-inner">
                    <Box
                      className="rate-list-grid"
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#fff !important",
                          fontSize: {
                            xs: "14px",
                            sm: "14px",
                            md: "14px",
                            lg: "15px",
                            xl: "16px",
                          },
                        }}
                      >
                        Status :{" "}
                        <span
                          style={{
                            color: "#faa61a",
                            textTransform: "uppercase",
                          }}
                        >
                          {OrderDetailPopData.order_status == "completed" ||
                          OrderDetailPopData.order_status == "security_settled"
                            ? "COMPLETE"
                            : OrderDetailPopData.order_status ==
                              "order_canceled"
                            ? "Cancelled"
                            : OrderDetailPopData.order_status ==
                              "payment_complete"
                            ? "pending"
                            : "Active"}
                        </span>
                      </Typography>
                    </Box>
                    <Box
                      className="rate-list-grid"
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: {
                            xs: "14px",
                            sm: "14px",
                            md: "14px",
                            lg: "15px",
                            xl: "16px",
                          },
                        }}
                      >
                        Transport : {OrderDetailPopData.order_from}
                      </Typography>
                    </Box>
                    <Box
                      className="rate-list-grid"
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: {
                            xs: "13px",
                            sm: "14px",
                            md: "14px",
                            lg: "15px",
                            xl: "16px",
                          },
                        }}
                      >
                        Rental Price
                      </Typography>
                      <Typography
                        sx={{
                          color: "#fff",
                          flex: "1",
                          fontSize: {
                            xs: "13px",
                            sm: "14px",
                            md: "14px",
                            lg: "15px",
                            xl: "16px",
                          },
                        }}
                        className="dash-black-border"
                      ></Typography>
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: {
                            xs: "13px",
                            sm: "14px",
                            md: "14px",
                            lg: "15px",
                            xl: "16px",
                          },
                        }}
                      >
                        $
                        {new Intl.NumberFormat("en-US", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        }).format(OrderDetailPopData.rental_price)}
                      </Typography>
                    </Box>
                    <Box
                      className="rate-list-grid"
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: {
                            xs: "13px",
                            sm: "14px",
                            md: "14px",
                            lg: "15px",
                            xl: "16px",
                          },
                        }}
                      >
                        Security Deposit{" "}
                        {OrderDetailPopData.security_deposit_refund_txt}
                      </Typography>
                      <Typography
                        sx={{
                          color: "#fff",
                          flex: "1",
                          fontSize: {
                            xs: "13px",
                            sm: "14px",
                            md: "14px",
                            lg: "15px",
                            xl: "16px",
                          },
                        }}
                        className="dash-black-border"
                      ></Typography>
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: {
                            xs: "13px",
                            sm: "14px",
                            md: "14px",
                            lg: "15px",
                            xl: "16px",
                          },
                        }}
                      >
                        $
                        {new Intl.NumberFormat("en-US", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        }).format(OrderDetailPopData.security_deposit)}
                      </Typography>
                    </Box>

                    <Box
                      className="rate-list-grid"
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {OrderDetailPopData.order_from != "Renter" && (
                        <>
                          <Typography
                            sx={{
                              color: "#fff",
                              fontSize: {
                                xs: "13px",
                                sm: "14px",
                                md: "14px",
                                lg: "15px",
                                xl: "16px",
                              },
                            }}
                          >
                            Transport Charges
                          </Typography>
                          <Typography
                            sx={{
                              color: "#fff",
                              flex: "1",
                              fontSize: {
                                xs: "13px",
                                sm: "14px",
                                md: "14px",
                                lg: "15px",
                                xl: "16px",
                              },
                            }}
                            className="dash-black-border"
                          ></Typography>
                          <Typography
                            sx={{
                              color: "#fff",
                              fontSize: {
                                xs: "13px",
                                sm: "14px",
                                md: "14px",
                                lg: "15px",
                                xl: "16px",
                              },
                            }}
                          >
                            $
                            {new Intl.NumberFormat("en-US", {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                            }).format(OrderDetailPopData.transport_fee)}
                          </Typography>
                        </>
                      )}
                    </Box>
                    <Box
                      className="rate-list-grid"
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: {
                            xs: "13px",
                            sm: "14px",
                            md: "14px",
                            lg: "15px",
                            xl: "16px",
                          },
                        }}
                      >
                        Admin Fee
                      </Typography>
                      <Typography
                        sx={{
                          color: "#fff",
                          flex: "1",
                          fontSize: {
                            xs: "13px",
                            sm: "14px",
                            md: "14px",
                            lg: "15px",
                            xl: "16px",
                          },
                        }}
                        className="dash-black-border"
                      ></Typography>
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: {
                            xs: "13px",
                            sm: "14px",
                            md: "14px",
                            lg: "15px",
                            xl: "16px",
                          },
                        }}
                      >
                        -$
                        {new Intl.NumberFormat("en-US", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        }).format(OrderDetailPopData.admin_fee)}
                      </Typography>
                    </Box>
                    {OrderDetailPopData.order_status == "order_canceled" && (
                      <Box
                        className="rate-list-grid"
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#faa61a",
                            fontSize: {
                              xs: "13px",
                              sm: "14px",
                              md: "14px",
                              lg: "15px",
                              xl: "16px",
                            },
                          }}
                        >
                          Order has been Cancelled
                        </Typography>
                      </Box>
                    )}

                    <Box
                      className="rate-list-grid g-total"
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: {
                            xs: "13px",
                            sm: "14px",
                            md: "14px",
                            lg: "15px",
                            xl: "16px",
                          },
                        }}
                      >
                        TOTAL EARNED {OrderDetailPopData.grand_total_txt}
                      </Typography>
                      <Typography
                        sx={{
                          color: "#fff",
                          flex: "1",
                          fontSize: {
                            xs: "13px",
                            sm: "14px",
                            md: "14px",
                            lg: "15px",
                            xl: "16px",
                          },
                        }}
                        className="dash-black-border"
                      ></Typography>
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: {
                            xs: "13px",
                            sm: "14px",
                            md: "14px",
                            lg: "15px",
                            xl: "16px",
                          },
                        }}
                      >
                        $
                        {new Intl.NumberFormat("en-US", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        }).format(OrderDetailPopData.grand_total)}
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                {/* Extended Order section start */}
                {ActiveOrder.length > 0 && (
                  <ExtendedOrderDetail
                    ExtendedOrder={ActiveOrder}
                    UserInfo={UserInfo}
                    Success={(data) => {
                      if (data) {
                      }
                    }}
                  />
                )}
                {/* {ActiveOrder.length > 0 && (
                      <>
                        <hr className="yellow-line" />

                        <Box
                          className="rate-list-grid g-total"
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography sx={{ color: "#faa61a" }}>
                            EXTENDED ORDER DETAILS:
                          </Typography>
                        </Box>

                        {ActiveOrder.map((Ex_Order, i) => {

                          var security = Ex_Order.security_deposit;
                          var refund_security = Ex_Order.returnd_deposit != undefined &&
                            Ex_Order.returnd_deposit != null &&
                            Ex_Order.returnd_deposit != 0 ? Ex_Order.returnd_deposit : 0;

                          var epf = Ex_Order.equipment_protection_fees;
                          console.log('Ex_Order', Ex_Order);
                          var refund_epf = Ex_Order.refunded_protection_fee != undefined &&
                            Ex_Order.refunded_protection_fee != null &&
                            Ex_Order.refunded_protection_fee != 0 ? Ex_Order.refunded_protection_fee : 0;

                          var final_security = security - refund_security;

                          var admin_comm = Ex_Order.admin_commission != undefined &&
                            Ex_Order.admin_commission != null &&
                            Ex_Order.admin_commission != 0 ? Ex_Order.admin_commission : 0;;

                          var total_charges = (Ex_Order.price + final_security) - admin_comm;

                          return <>


                            <Box className="owner-div owner-div-new edit-eq-owner">
                              <Box className="owner-edit-dates">
                                <Typography>Rental Dates</Typography>
                                <Typography className="modal-dates-grid">
                                  <span>Start:</span>
                                  <span>
                                    {moment(Ex_Order.start_date, ["YYYY-MM-DD",]).format("MM/DD/YYYY")}{" "}
                                    {moment(Ex_Order.start_time, ["HH:mm:ii",]).format("hh:mm a")}
                                  </span>
                                </Typography>
                                <Typography className="modal-dates-grid">
                                  <span>End:</span>
                                  <span>
                                    {moment(Ex_Order.end_date, ["YYYY-MM-DD",]).format("MM/DD/YYY")}{" "}
                                    {moment(Ex_Order.end_time, ["HH:mm:ii",]).format("hh:mm a")}
                                  </span>
                                </Typography>
                              </Box>
                              <Box
                                sx={{ fontWeight: "bold" }}
                                className="owner-img-grid"
                              >
                                <Box className="owner-img">
                                  <img
                                    src={OrderDetailPopData.profile_pic}
                                    alt=""
                                    style={{ width: "20%" }}
                                  />
                                </Box>

                                <Box className="ro-name">
                                  <Typography
                                    sx={{ display: "flex", flexDirection: "column" }}
                                  >
                                    RENTER :
                                    <span className="gem-ro-name">
                                      {OrderDetailPopData.first_name
                                        .charAt(0)
                                        .toUpperCase() +
                                        OrderDetailPopData.first_name.slice(1)}
                                    </span>
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>


                            <Box className="equipment-rate-div">
                              <Box className="equipment-rate-inner">
                                <Box
                                  className="rate-list-grid"
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Typography sx={{ color: "#fff" }}>
                                    Rental Price
                                  </Typography>
                                  <Typography
                                    sx={{ color: "#fff", flex: "1" }}
                                    className="dash-black-border"
                                  ></Typography>
                                  <Typography sx={{ color: "#fff" }}>
                                    ${new Intl.NumberFormat("en-US", {
                                      maximumFractionDigits: 2,
                                      minimumFractionDigits: 2,
                                    }).format(Ex_Order.price)}
                                  </Typography>
                                </Box>
                                <Box
                                  className="rate-list-grid"
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Typography sx={{ color: "#fff" }}>
                                    Security Deposit{" "}
                                    {refund_security > 0 &&
                                      (
                                        <>
                                          (Refunded-$
                                          {new Intl.NumberFormat("en-US", {
                                            maximumFractionDigits: 2,
                                            minimumFractionDigits: 2,
                                          }).format(refund_security)})
                                        </>
                                      )}
                                  </Typography>
                                  <Typography
                                    sx={{ color: "#fff", flex: "1" }}
                                    className="dash-black-border"
                                  ></Typography>
                                  <Typography sx={{ color: "#fff" }}>
                                    $
                                    {new Intl.NumberFormat("en-US", {
                                      maximumFractionDigits: 2,
                                      minimumFractionDigits: 2,
                                    }).format(final_security)}
                                  </Typography>
                                </Box>


                                <Box
                                  className="rate-list-grid"
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Typography sx={{ color: "#fff" }}>
                                    Admin Fee
                                  </Typography>
                                  <Typography
                                    sx={{ color: "#fff", flex: "1" }}
                                    className="dash-black-border"
                                  ></Typography>
                                  <Typography sx={{ color: "#fff" }}>
                                    -$
                                    {new Intl.NumberFormat("en-US", {
                                      maximumFractionDigits: 2,
                                      minimumFractionDigits: 2,
                                    }).format(Ex_Order.admin_commission)}
                                  </Typography>
                                </Box>

                                {Ex_Order.order_status ==
                                  "order_canceled" && (
                                    <Box
                                      className="rate-list-grid"
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Typography sx={{ color: "#faa61a" }}>
                                        Order has been Cancelled
                                      </Typography>
                                    </Box>
                                  )}

                                <Box
                                  className="rate-list-grid g-total"
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Typography sx={{ color: "#fff" }}>
                                    Total Charges
                                  </Typography>
                                  <Typography
                                    sx={{ color: "#fff", flex: "1" }}
                                    className="dash-black-border"
                                  ></Typography>
                                  <Typography sx={{ color: "#fff" }}>
                                    ${new Intl.NumberFormat("en-US", {
                                      maximumFractionDigits: 2,
                                      minimumFractionDigits: 2,
                                    }).format(total_charges)}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>


                            <hr className="yellow-line" />
                          </>;

                        })}

                      </>
                    )} */}
                {/* Extended Order section end */}
              </Box>
            </Box>
          </Box>
        </Modal>

        {/* Refund Security Deposit Popup */}
        <Modal
          keepMounted
          open={RefundSecuritypopup}
          onClose={() => setRefundSecuritypopup(false)}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box
            className="download-popups"
            sx={{
              ...loginstyle,
              // width: matchesMobileX ? "calc(100vw - 100px)" : 800,
              padding: "0px",
            }}
          >
            <Box className="login__main">
              <Box
                className="login__main-div edit-eqp"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <IconButton
                  className="cross-btn"
                  onClick={() => setRefundSecuritypopup(false)}
                >
                  <CloseIcon />
                </IconButton>
                <Box className="login__main-div__form popup-cmmn pupup-scroll edit-eqp-inner">
                  {/* <span onClick={() => setRefundSecuritypopup(false)}>
                    <CloseIcon className="modal-popup" />
                  </span> */}
                  <Typography variant="h5" style={{ color: "#faa61a" }}>
                    Refund Security Deposit
                  </Typography>
                  <Typography variant="h6" sx={{ color: "#fff !important" }}>
                    You can return up to ${RefundSecurityamt}
                  </Typography>

                  <Box>
                    {loading && (
                      <Box>
                        <Backdrop
                          sx={{
                            color: "#fff",
                            zIndex: (theme) => theme.zIndex.drawer + 1,
                          }}
                          open={loading}
                        >
                          <CircularProgress color="inherit" />
                        </Backdrop>
                      </Box>
                    )}
                    <FormControl fullWidth className="form-fields">
                      {/* <NumericInput
                        name="example"
                        precision={0}
                        decimalChar="."
                        thousandChar=","
                        label=""
                        placeholder="$ 0"
                        onChange={HandleRefundAmount}
                        value={RefundSecurityamount}
                        variant="filled"
                      /> */}
                      <TextField
                        id="outlined-disabled"
                        hiddenLabel
                        onChange={HandleRefundAmount}
                        value={RefundSecurityamount}
                        variant="filled"
                        // placeholder="0"
                        // above line commented and next line added by pp on 9 Apr 2024 due to placeholder changes
                        placeholder="Enter Security Amount"
                        // end by pp
                        className="popup-input"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                          disableUnderline: true,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </FormControl>
                    <FormControl fullWidth className="form-fields">
                      <FormControl>
                        <TextField
                          id="outlined-disabled"
                          hiddenLabel
                          onChange={HandleSecurityReson}
                          value={RefundSecurityamtreson}
                          variant="filled"
                          placeholder="If not full refund, enter reason"
                          className="popup-input"
                          InputProps={{ disableUnderline: true }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </FormControl>
                    </FormControl>
                    <Button
                      className="signin-btn"
                      type="button"
                      variant="contained"
                      sx={{ mt: 3, mb: 2, backgroundColor: "#139246" }}
                      onClickCapture={() => {
                        securityRefund();
                      }}
                    >
                      Ok
                    </Button>{" "}
                    &nbsp;
                    <Button
                      className="signin-btn"
                      type="button"
                      variant="contained"
                      sx={{ mt: 3, mb: 2, backgroundColor: "#139246" }}
                      onClickCapture={() => {
                        setRefundSecuritypopup(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>

        {/* Handle Renter Pickup images */}
        <Modal
          keepMounted
          open={RenterPickupImages}
          onClose={() => setRenterPickupImages(false)}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box
            sx={{
              ...loginstyle,
              position: "absolute" as "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: {
                xs: "78%",
                sm: "400px",
                md: "500px",
                lg: "500px",
                xl: "500px",
              },
              bgcolor: "#2e2e2e",
              border: "5px solid #faa61a",
              borderRadius: "10px",
              outline: "none !important",
              p: 3,
            }}
            // className="download-popups"
            // sx={{
            //   ...loginstyle,
            //   // width: matchesMobileX ? "calc(100vw - 100px)" : 800,
            //   padding: "0px",
            // }}
          >
            {/* <IconButton
              className="cross-btn"
              onClick={() => setRenterPickupImages(false)}
            >
              <CloseIcon />
            </IconButton> */}

            <IconButton
              onClick={() => setRenterPickupImages(false)}
              sx={{
                position: "absolute",
                top: "-20px",
                right: "-20px",
                bgcolor: "#faa61a",
                color: "#000",
                "&:hover": {
                  bgcolor: "#faa61a",
                },
              }}
            >
              <CloseIcon />
            </IconButton>

            {/* <Box className="login__main"> */}
            <Box
              // className="login__main-div edit-eqp"
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                width: "100%",
              }}
            >
              {/* <Box
                  // className="login__main-div__form popup-cmmn edit-eqp-inner"
                  // style={{ border: "5px solid #faa61a", borderRadius: "10px" }}
                > */}
              <Typography variant="h5" style={{ color: "#faa61a" }}>
                {ReturnImageTitle}
              </Typography>

              <Box>
                {loading && (
                  <Box>
                    <Backdrop
                      sx={{
                        color: "#fff",
                        zIndex: (theme) => theme.zIndex.drawer + 1,
                      }}
                      open={loading}
                    >
                      <CircularProgress color="inherit" />
                    </Backdrop>
                  </Box>
                )}

                <Box className="img-box new-img-box">
                  <Box
                    // className="img-box-grid new-img-grid"
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "repeat(2, 1fr)",
                      gap: "1rem",
                      mt: 2,
                    }}
                  >
                    {RenterPickupImagesArr.map((item) => {
                      return (
                        // <Box
                        // className="img-box-item"
                        // >
                        <Box
                          component={"img"}
                          // className="main-img-box-item"
                          src={item}
                          alt=""
                          sx={{
                            width: "100%",
                            height: {
                              xs: "140px",
                              sm: "160px",
                              md: "180px",
                              lg: "200px",
                              xl: "200px",
                            },
                            border: "3.5px solid #faa61a",
                          }}
                        />
                        // </Box>
                      );
                    })}
                  </Box>
                </Box>
              </Box>
              {/* </Box> */}
            </Box>
            {/* </Box> */}
          </Box>
        </Modal>

        {/* Handle chat message owner to renter */}
        <Modal
          keepMounted
          open={ChatOwnerToRenter}
          onClose={() => setChatOwnerToRenter(false)}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box
            className="download-popups"
            sx={{
              ...loginstyle,
              // width: matchesMobileX ? "calc(100vw - 100px)" : 800,
              padding: "0px",
            }}
          >
            <span onClick={() => setChatOwnerToRenter(false)}>
              <CloseIcon className="modal-popup" />
            </span>
            <Box className="login__main">
              <Box
                className="login__main-div edit-eqp chat-popup"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Box
                  className="login__main-div__form popup-cmmn edit-eqp-inner chat-popup-inner"
                  style={{ border: "5px solid #faa61a", borderRadius: "10px" }}
                >
                  <Box className="chatpopup">
                    {ChatOwnerToRenter && (
                      <ChatWindow
                        ChatData={ChatData}
                        NewMsg={NewMsg}
                        pageNo={pageNo}
                      />
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>

        {/* Owner Info Popup */}
        <Modal
          keepMounted
          open={OwnerInfoPopup}
          onClose={() => setOwnerInfoPopup(false)}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box
            sx={{
              ...loginstyle,
              position: "absolute" as "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: {
                xs: "78%",
                sm: "360px",
                md: "380px",
                lg: "380px",
                xl: "380px",
              },
              bgcolor: "#2e2e2e",
              border: "5px solid #faa61a",
              borderRadius: "10px",
              outline: "none !important",
              p: 3,
            }}
            // className="download-popups"
            // sx={{
            //   ...loginstyle,
            //   padding: "0px",
            // }}
          >
            {/* <IconButton
              className="cross-btn"
              onClick={() => setOwnerInfoPopup(false)}
            >
              <CloseIcon />
            </IconButton> */}

            <IconButton
              onClick={() => setOwnerInfoPopup(false)}
              sx={{
                position: "absolute",
                top: "-20px",
                right: "-20px",
                bgcolor: "#faa61a",
                color: "#000",
                "&:hover": {
                  bgcolor: "#faa61a",
                },
              }}
            >
              <CloseIcon />
            </IconButton>

            {/* <Box className="login__main"> */}
            <Box
              // className="login__main-div edit-eqp"
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                width: "100%",
              }}
            >
              {/* <Box 
                
                className="login__main-div__form edit-eqp-inner pupup-scroll"
                > */}
              <Typography variant="h5" sx={{ color: "#faa61a", mb: 2 }}>
                Renter Info
              </Typography>
              {OwnerInfo && OwnerInfoPopup && (
                // <UserInfo userData={OwnerInfo} />

                <Box
                  // className="rentyrd-dates"
                  sx={{
                    display: "flex",
                    gap: "1rem",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* <Box className="rentyrd-dates-item rental-dates renter-imag-box"> */}
                  <Box
                    component={"img"}
                    src={
                      OwnerInfo["profile_pic"]
                        ? OwnerInfo["profile_pic"]
                        : ProfilePic
                    }
                    sx={{
                      width: "60px",
                      height: "60px",
                      borderRadius: "50%",
                      border: "2px solid #fff",
                    }}
                  />
                  {/* </Box> */}
                  <Box className="rentyrd-dates-item rental-dates">
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "13px",
                          sm: "14px",
                          md: "14px",
                          lg: "15px",
                          xl: "16px",
                        },
                      }}
                    >
                      <span>
                        Name: {OwnerInfo["first_name"]} {OwnerInfo["last_name"]}
                      </span>
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "13px",
                          sm: "14px",
                          md: "14px",
                          lg: "15px",
                          xl: "16px",
                        },
                      }}
                    >
                      <span>Email: {OwnerInfo["email"]}</span>
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "13px",
                          sm: "14px",
                          md: "14px",
                          lg: "15px",
                          xl: "16px",
                        },
                      }}
                    >
                      <span>Contact No: {OwnerInfo["phone_no"]}</span>
                    </Typography>
                  </Box>
                </Box>
              )}
              {/* </Box> */}
            </Box>
          </Box>
          {/* </Box> */}
        </Modal>

        {/* Order cancel popup */}
        <Modal
          keepMounted
          open={OrderCancelPopup}
          onClose={() => setOrderCancelPopup(false)}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box
            className="download-popups"
            sx={{
              ...loginstyle,
              padding: "0px",
            }}
          >
            <Box className="login__main">
              <Box
                className="login__main-div edit-eqp"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Box
                  className="login__main-div__form popup-cmmn popup-white edit-eqp-inner"
                  style={{ border: "5px solid #faa61a", borderRadius: "10px" }}
                >
                  <Typography
                    variant="h4"
                    style={{ color: "#faa61a", textAlign: "center" }}
                  >
                    Cancel
                  </Typography>

                  <Box>
                    {loading && (
                      <Box>
                        <Backdrop
                          sx={{
                            color: "#000",
                            zIndex: (theme) => theme.zIndex.drawer - 1,
                          }}
                          open={loading}
                        >
                          <CircularProgress color="inherit" />
                        </Backdrop>
                      </Box>
                    )}
                  </Box>

                  <Box>
                    <FormControl
                      fullWidth
                      className="form-fields"
                      sx={{ width: "100%" }}
                    >
                      <NewCssTextField
                        id="outlined-multiline-static"
                        multiline
                        placeholder={"Cancellation reason"}
                        // maxRows={2}
                        rows={2}
                        fullWidth
                        variant="filled"
                        //label="Instructions"
                        onChange={(value) => {
                          reason = value.target.value;
                        }}
                        // value={ExtendOrderCancelIdreason}
                        className="popup-input"
                      />
                    </FormControl>
                  </Box>

                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "1rem",
                    }}
                  >
                    <Button
                      className="signin-btn"
                      type="button"
                      fullWidth
                      variant="contained"
                      sx={{ backgroundColor: "#139246" }}
                      onClickCapture={CancelOrderByOwner}
                    >
                      Ok
                    </Button>
                    <Button
                      className="signin-btn"
                      type="button"
                      fullWidth
                      variant="contained"
                      sx={{ backgroundColor: "#faa61a !important" }}
                      onClickCapture={() => {
                        setOrderCancelPopup(false);
                        reason = "";
                      }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>
      </>
      {/* )} */}
    </>
  );
};

export default NewRentalDetail;
