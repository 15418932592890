import { Box, Autocomplete, TextField } from "@mui/material";
import React, { Dispatch, SetStateAction, useCallback } from "react";
import { ILoanEquipment } from "utils/types";

import "./styles.css";
import { styled } from "@mui/material";
import NumericInput from "material-ui-numeric-input";
const NewCssNumericInput = styled(NumericInput)({
  "& .MuiInput-underline:after": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiInput-underline:before": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiFilledInput-underline:after": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiFilledInput-underline:before": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },

  "& .MuiInputBase-root": {
    color: "#2d2d2d",
    background: "rgb(230, 231, 232)",
    fontFamily: "Gotham !important",
    fontSize: "clamp(12px, 3vw, 15px) !important",
    borderRadius: "3px !important",
    border: "3px solid #faa61a",
  },
  "& label.Mui-focused": {
    color: "#2d2d2d",
  },

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "2px solid #faa61a",
    },
    "&:hover fieldset": {
      borderColor: "#2d2d2d",
      border: "2px solid #faa61a",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#faa61a",
    },
  },
});

const loanTypes = [
  {
    label: "New purchase",
    value: 0,
  },
  {
    label: "Refinance",
    value: 1,
  },
  {
    label: "Cash out",
    value: 2,
  },
];

const searchInputProp = {
  disableUnderline: true,
  style: {
    border: "3px solid #faa61a",
    borderRadius: "3px",
    background: "#e6e7e8",
    height: "53px",
  },
};

const inputLabelProp = {
  style: {
    color: "#7f8084",
  },
};
interface IEquipmentTypeProps {
  equipment: ILoanEquipment;
  equipments: Array<ILoanEquipment>;
  updateEquipments: Dispatch<SetStateAction<ILoanEquipment[]>>;
}
const EquipmentType = ({
  equipment,
  equipments,
  updateEquipments,
}: IEquipmentTypeProps) => {
  const onLoanChanged = useCallback(
    (e) => {
      updateEquipments(
        equipments.map((equipmentItem) =>
          equipmentItem.id === equipment.id
            ? { ...equipment, amount: e.target.value }
            : equipmentItem
        )
      );
    },
    [equipment, equipments, updateEquipments]
  );
  const onLoanTypeChange = useCallback(
    (e, v) => {
      if (v) {
        updateEquipments(
          equipments.map((equipmentItem) =>
            equipmentItem.id === equipment.id
              ? { ...equipment, loanType: v.label }
              : equipmentItem
          )
        );
        // const indexToRemove = loanTypes.findIndex((types) =>
        //   types.label?.includes(v.label)
        // );
        // loanTypes.splice(indexToRemove,1);
        // console.log("indexToRemove",loanTypes,v)
      }
    },
    [equipment, equipments, updateEquipments]
  );
  const onEqTypeChange = useCallback(
    (e) => {
      updateEquipments(
        equipments.map((equipmentItem) =>
          equipmentItem.id === equipment.id
            ? { ...equipment, type: e.target.value }
            : equipmentItem
        )
      );
    },
    [equipment, equipments, updateEquipments]
  );
  return (
    <>
      <Box className="equipment-group">
        <span className="span-number">{equipment.id + 1}</span>
        <TextField
          label="Equipment Type"
          fullWidth
          size="small"
          InputProps={searchInputProp}
          InputLabelProps={inputLabelProp}
          variant="filled"
          onChange={onEqTypeChange}
        />
      </Box>
      <Box
        className="lending-input-box"
        sx={{
          display: "flex",
          mb: 4,
          ":last-child": {
            mb: 0,
          },
        }}
      >
        <Box
          sx={{
            flex: 1,
          }}
        >
          {/* <TextField
            label="Loan Amount $"
            fullWidth
            size='small'
            InputProps={searchInputProp}
            InputLabelProps={inputLabelProp}
            variant="filled"
            type={'number'}
            sx={{ mt: 2 }}
            onChange={onLoanChanged}
          /> */}
          <NewCssNumericInput
            prefix={"$"}
            name="example"
            precision={0}
            decimalChar="."
            thousandChar=","
            label="Loan Amount"
            fullWidth
            size="small"
            onChange={onLoanChanged}
            // value={priceperhour}
            variant="filled"
            className="input-lending"
            InputProps={searchInputProp}
          />
        </Box>
        <Box
          className="loan-box"
          sx={{
            ml: 2,
            flex: 1,
          }}
        >
          <Autocomplete
            id="loan_type"
            options={loanTypes}
            size="small"
            renderInput={(params) => (
              <TextField
                label="Loan Type"
                variant="filled"
                {...params}
                InputProps={{ ...params.InputProps, ...searchInputProp }}
                InputLabelProps={inputLabelProp}
              />
            )}
            sx={{
              mt: 2,
            }}
            onChange={onLoanTypeChange}
          />
        </Box>
      </Box>
    </>
  );
};

export default EquipmentType;
