import React, { useCallback, useEffect, useState, useRef } from "react";
import { EquipmentAPIs } from "../../service/api-service";
import {
  Box,
  CircularProgress,
  Backdrop,
  Button,
  TextField,
  FormControl,
  styled,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup
} from "@mui/material";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import InputAdornment from "@mui/material/InputAdornment";
import NumericInput from "material-ui-numeric-input";

import { useDispatch, useSelector } from "react-redux";
import {
  setalertMessagetype,
  setalertMessage,
  setSessionExpire
} from "redux/redux-slice";
import { IApiResponseRawData, IStoreValue } from "utils/types";
import "./styles.scss";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { checkCardExpiry } from "utils/commonFxns";

// stripeCode
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

let STRIPE_KEY = process.env.REACT_APP_STRIPE_PUBLISH_KEY;
let type = process.env.REACT_APP_PLATFORM;
if (type == "Live") {
  STRIPE_KEY = process.env.REACT_APP_STRIPE_PUBLISH_KEY_LIVE;
}

const stripePromise = loadStripe(STRIPE_KEY);

const loginstyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
  p: 4
};

const NewCssTextField = styled(TextField)({
    "& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl" : {
    // fontFamily:'Gotham !important'
  },
  "& .MuiInput-underline:after": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important"
  },
  "& .MuiInput-underline:before": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important"
  },
  "& .MuiFilledInput-underline:after": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important"
  },
  "& .MuiFilledInput-underline:before": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important"
  },

  "& .MuiInputLabel-shrink.MuiInputLabel-filled": {
    color: "#2d2d2d"
  },
  "& .MuiInputBase-root": {
    color: "#2d2d2d",
    background: "#fff",
    fontFamily: "Gotham !important",
    fontSize: "clamp(12px, 3vw, 15px) !important",
    borderRadius: "10px !important"
    // border: "2px solid #faa61a",
  },
  "&. MuiInputLabel-shrink": {
    color: "red !important"
  },

  "&. MuiInputLabel-shrink.Mui-focused": {
    color: "red !important"
  },

  "& label.Mui-focused": {
    color: "#2d2d2d !important"
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "2px solid #faa61a",
      backgroundColor: "#fff !important"
    },
    "&:hover fieldset": {
      borderColor: "#2d2d2d",
      border: "2px solid #faa61a",
      backgroundColor: "#fff !important"
    },
    "&.Mui-focused fieldset": {
      borderColor: "#faa61a"
    }
  },
  "& .MuiInputBase-root-MuiFilledInput-root:before": {
    borderBottom: "1px solid rgba(0, 0, 0, 0.42)"
  }
});

const NewCssNumericInput = styled(NumericInput)({
  "& .MuiInput-underline:after": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important"
  },
  "& .MuiInput-underline:before": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important"
  },
  "& .MuiFilledInput-underline:after": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important"
  },
  "& .MuiFilledInput-underline:before": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important"
  },

  "& .MuiInputBase-root": {
    color: "#2d2d2d",
    background: "#fff",
    fontFamily: "Gotham !important",
    fontSize: "clamp(12px, 3vw, 15px) !important",
    borderRadius: "10px !important"
    // border: "2px solid #faa61a",
  },
  "& label.Mui-focused": {
    color: "#2d2d2d"
  },

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "2px solid #faa61a"
    },
    "&:hover fieldset": {
      borderColor: "#2d2d2d",
      border: "2px solid #faa61a"
    },
    "&.Mui-focused fieldset": {
      borderColor: "#faa61a"
    }
  }
});

const MyWallet = ({ onSuccess }) => {
  const dispatch = useDispatch();

  const ref = useRef(null);


  const AuthToken = useSelector(
    (state: IStoreValue) => state.equipmentReducer.accessToken
  );
  const UserId = useSelector(
    (state: IStoreValue) => state.equipmentReducer.Authuser
  );
  const [loading, setloading] = React.useState(false);
  const [savedToken, setsavedToken] = React.useState(null);
  // const [balanceamount, setbalanceamount] = React.useState(null); // this line gives a warning message while updating input fields
  // above line is commented and next line is added by pp on 02/04/2024 due to decimal precision changes
  const [balanceamount, setbalanceamount] = React.useState("")
  const [CardHolder, setCardHolder] = React.useState(null);
  const [CardNumber, setCardNumber] = React.useState(null);
  const [ExpMonth, setExpMonth] = React.useState(null);
  const [ExpYear, setExpYear] = React.useState(null);
  const [Cvc, setCvc] = React.useState(null);
  const [SaveCrad, setSaveCrad] = React.useState('no');

  const [SaveCards, setSaveCards] = React.useState([]);


  const addBalanceAmount = (event) => {
    // console.log("addBalanceAmount",event.target.value)
    let val = event.target.value;
    if (val.length) {
      // let newval = val.toString();
      //val = val.replace(/\D/g, "");
      //val = val.replace(/[^0-9.]/g, '')
      // if (newval.length > 0) {
      //   newval = newval.replace(/(\..*?)\..*/g, "$1");
      //   setbalanceamount(newval);
      // } else {
      //   setbalanceamount(val);
      // }
      /**** above code commented by pp */
      /*** next line added by pp 01/04/2024 due to decimal precision changes in amount*/
      const regex = /\./g; //regular expression pattern for dot(.)
      const decimalMatches = val.match(regex); // matches the decimal appear how many times
      if(decimalMatches?.length>1) {
        return false;
      }
      let newVal = val.replace(/[^\d.]/g, '').replace(/(\.\d\d).*/, '$1'); //regular expression to ensure only number string and two digit after decimal
      newVal = newVal.replace(/\B(?=(\d{3})+(?!\d))/g, ','); // regular expression to add comma(,) after thousand places
      setbalanceamount(newVal);
    } else {
      setbalanceamount(null);
    }
  };

  const HandleCardHolder = (event) => {
    let name = event.target.value;
    var letters = name.replace(/[^A-Za-z ]+/g, "");
    console.log('letters', letters);
    setCardHolder(letters);
  };

  const HandleCardNumber = (event) => {
    let val = event.target.value;
    var number = val.replace(/[^0-9]+/g, "");
    // var match = number.match(/(\d{4})(\d{4})(\d{4})$/);
    // if (match) {
    //   var new_number = [match[1], "-", match[2], "-", match[3]].join("");
    //   setCardNumber(new_number);
    //   return;
    // }
    setCardNumber(number);
  };

  const HandleExpMonth = (event) => {
    let val = event.target.value;
    var letters = val.replace(/[^0-9]+/g, "");
    setExpMonth(letters);
  };

  const HandleExpYear = (event) => {
    let val = event.target.value;
    var letters = val.replace(/[^0-9]+/g, "");
    setExpYear(letters);
  };

  const HandleCvc = (event) => {
    let val = event.target.value;
    var letters = val.replace(/[^0-9]+/g, "");
    setCvc(letters);
  };

  const HandleSaveCard = (event) => {
    let val = event.target.checked
    if (val) {
      setSaveCrad('yes')
    } else {
      setSaveCrad('no')
    }
  }


  const SubmitHandler = () => {
    try {
      let name = CardHolder;
      let card_saved_status = SaveCrad;
      let is_card_saved = card_saved_status == 'yes' ? true : false;
      let card = CardNumber;
      let month = ExpMonth;
      let year = ExpYear;
      let cvc = Cvc;
      let token = savedToken;
      // let amount = balanceamount;
      // above line is commented and next line is added by pp on 2 Apr 2024
      let amount = balanceamount?.toString()?.replace(/,/g, "");

      if(amount !== ".") {
        amount = parseFloat(amount).toString()
      }
      // end by pp
      // if (amount == null || amount == "") {
      //   dispatch(setalertMessagetype("error"));
      //   dispatch(setalertMessage("Please add amount to proceed"));
      //   setloading(false);
      //   return false;
      // }
      // if (amount && parseFloat(amount) < 1) {
      //   dispatch(setalertMessagetype("error"));
      //   dispatch(setalertMessage("Amount should be greater than 1"));
      //   setloading(false);
      //   return false;
      // }
      //commented by pp on 2 Apr 2024 because the below line moves above
      // amount = balanceamount.toString().replace(/,/g, "");
      if (isNaN(parseFloat(amount))) {
        dispatch(setalertMessagetype("error"));
        dispatch(setalertMessage("Please enter valid amount to proceed"));
        setloading(false);
        return false;
      }
      if (token == null) {
        dispatch(setalertMessagetype("error"));
        dispatch(setalertMessage("Please select valid payment method to proceed"));
        setloading(false);
        return false;
      }

      if (token == 'other') {
        if (name == null || name == '') {
          dispatch(setalertMessage("Please fill card holder name to proceed"));
          dispatch(setalertMessagetype("error"));
          setloading(false);
          return false;
        }

        if (card == null) {
          dispatch(setalertMessage("Please fill card number to proceed"));
          dispatch(setalertMessagetype("error"));
          setloading(false);
          return false;
        }

        if (card.length != 16) {
          dispatch(setalertMessage("Please enter valid card number to proceed"));
          dispatch(setalertMessagetype("error"));
          setloading(false);
          return false;
        }

        if (month == null) {
          dispatch(setalertMessage("Please fill expire month to proceed"));
          dispatch(setalertMessagetype("error"));
          setloading(false);
          return false;
        }

        if (year == null) {
          dispatch(setalertMessage("Please fill expire year to proceed"));
          dispatch(setalertMessagetype("error"));
          setloading(false);
          return false;
        }

        if (year.length != 2) {
          dispatch(setalertMessage("Please enter valid expire year to proceed"));
          dispatch(setalertMessagetype("error"));
          setloading(false);
          return false;
        }
        var c_data = new Date();
        var shortYear = c_data.getFullYear();
        var CurrentYear = shortYear.toString().substr(-2);
        var CurrentMonth = c_data.getMonth();
        if (CurrentYear == year) {
          if (CurrentMonth + 1 > month) {
            dispatch(setalertMessage("Please enter valid card expire month to proceed"));
            dispatch(setalertMessagetype("error"));
            setloading(false);
            return false;
          }
        }

        if (CurrentYear > year) {
          dispatch(setalertMessage("Please enter valid card expire year to proceed"));
          dispatch(setalertMessagetype("error"));
          setloading(false);
          return false;
        }


        if (cvc == null) {
          dispatch(setalertMessage("Please fill cvv number to proceed"));
          dispatch(setalertMessagetype("error"));
          setloading(false);
          return false;
        }

        if (cvc.length == 0 || cvc.length == 1 || cvc.length == 2) {
          dispatch(setalertMessage("Please enter valid cvv number to proceed"));
          dispatch(setalertMessagetype("error"));
          setloading(false);
          return false;
        }

        token = null;
      }

      let dataObj = {
        user_id: UserId.toString(),
        balance: amount,
        token: token,
        description: UserId + ", added_wallet",
        is_card_saved: is_card_saved,
        // card: card,
        // month: month,
        // year: year,
        // cvc: cvc,
        is_saved: savedToken == 'other' ? false : true
      }
      console.log('dataObj', dataObj);

      // addBalance(token, amount, card, month, year, cvc, is_card_saved);
      // by GD 2 Feb
      setloading(true);
      addBalance(token, amount);

    } catch (err) {
      console.log(err);
      setloading(false);
    }
  };



  // const newAddBalanceAmount = (amount) => {
  //   let val = amount;
  //   if (val) {
  //     let newval = val.toString();
  //     //val = val.replace(/\D/g, "");
  //     //val = val.replace(/[^0-9.]/g, '')
  //     // console.log("amount length : " , newval.length);

  //     if (newval.length > 0) {
  //       newval = newval.replace(/(\..*?)\..*/g, "$1");
  //       newval = newval.replace(/,/g, "");
  //       setbalanceamount(newval);
  //       console.log("new Val : ", { newval });
  //       return newval;
  //     } else {
  //       setbalanceamount(val);
  //       return val;
  //     }
  //   } else {
  //     setbalanceamount(null);
  //     return null;
  //   }
  // };
  // const newAddBalanceCardholderName = (userName) => {
  //   let name = userName;
  //   var letters = name.replace(/[^A-Za-z ]+/g, "");
  //   setbalancecardholdername(letters);
  //   console.log("name condition : ", letters);

  //   return letters;
  // };

  // stripeCode
  // function StripeComp() {
  // above line commented and a useCallback hooks used in that function to manage re-rendering of the function due to precision changes
  // done by pp on 2 Apr 2024
  const StripeComp = useCallback(function StripeComp() {
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    //below lines added by pp on 2 Apr 2024 due to amount decimal precision issues
    const [balanceAmount,setBalanceAmount] = useState("");

    const handleBalanceAmountChange = (event) => {
      const val = event.target.value;
      // console.log("stripe amount",val);
      if(val.length) {
        const regex = /\./g;
        const decimalMatches = val.match(regex);
        if(decimalMatches?.length>1) {
          return false;
        }
        let newVal = val.replace(/[^\d.]/g, '').replace(/(\.\d\d).*/, '$1');
        newVal = newVal.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        setBalanceAmount(newVal)
      } else {
        setBalanceAmount(null);
      }
    }
    // end by pp

    const handleSubmitStripe = async (event) => {
      console.log("in form submit");

      event.preventDefault();

      const { currentTarget } = event;
      const fD = new FormData(currentTarget);
      // by GD
      // saveCardCheckbox
      // let saveCheck = fD.get("saveCardCheckbox")
      // console.log("save card checkbox:", saveCheck);
      // if (saveCheck) {
      //   setSaveCrad('yes');
      //   console.log("in save card if");

      // } else {
      //   console.log("in save card else");
      //   setSaveCrad('no');
      // }
      // return;
      let balanceamount = fD.get("amount");
      //let balancecardholdername = fD.get("name");
      let amount = balanceamount.toString().replace(/,/g, "");
      // let amount = newAmount;
      // if (amount == null || amount == "") {
      //   dispatch(setalertMessagetype("error"));
      //   dispatch(setalertMessage("Please add amount to proceed"));
        // setloading(false);
      //   return false;
      // }
      if (isNaN(parseFloat(amount))) {
        dispatch(setalertMessagetype("error"));
        dispatch(setalertMessage("Please enter valid amount to proceed"));
        // setloading(false);
        return false;
      }
      // if (!amount || parseFloat(amount) < 1) {
      //   dispatch(setalertMessagetype("error"));
      //   dispatch(setalertMessage("Amount should be greater than 1"));
      //   // setloading(false);
      //   return false;
      // }
      let balancecardholdername = fD.get("name");
      let name = balancecardholdername;
      // let name = newBalancecardholdername;
      if (name != null) {
        name = name;
        // name = name.toString();
        // name.replace(/^\s+/, '');
        // name.trim();
      }
      // console.log("name : " , name);

      if (name == null || name == "" || name.toString().trim() == "") {
        dispatch(setalertMessage("Please provide the card holder name"));
        dispatch(setalertMessagetype("error"));
        // setloading(false);
        return false;
      }

      if (!stripe || !elements) {
        dispatch(setalertMessagetype("error"));
        dispatch(setalertMessage("Stripe Error in elements error"));
        // setloading(false);
        console.log("Stripe Error in elements error");
        // Stripe.js has not loaded yet. Make sure to disable form submission or show an error.
        return;
      }
      // setLoading(true);
      const result = await stripe.createToken(
        elements.getElement(CardNumberElement)
      );

      const card = elements.getElement(CardNumberElement);
      // console.log("stripe result: ", result);
      // by GD
      // saveCardCheckbox
      let saveCheck = fD.get("saveCardCheckbox")
      console.log("save card checkbox:", saveCheck);
      if (saveCheck) {
        setSaveCrad('yes');
        console.log("in save card if");

      } else {
        console.log("in save card else");
        setSaveCrad('no');
      }
      // setLoading(false);
      if (result.error) {
        console.log("Stripe Error:", result);
        // setLoading(false);

        // setError(result.error.message);
        // setloading(false);
        dispatch(setalertMessagetype("error"));
        dispatch(setalertMessage(result.error.message));
        return false;
      } else {
        setloading(true);
        console.log("Stripe result in success:", result);
        // addBalance(result.token.id, amount);

        // by GD 2 Feb 2024 for token save and other
        // let dataObj = {
        //   user_id: UserId.toString(),
        //   balance: amount,
        //   token: result.token.id,
        //   description: UserId + ", added_wallet",
        //   // is_card_saved: is_card_saved,
        //   is_card_saved: false,
        //   // card: card,
        //   // month: month,
        //   // year: year,
        //   // cvc: cvc,
        //   is_saved: savedToken == 'other' ? false : true
        // }
        // console.log('dataObj in stripe', dataObj);
        addBalance(result.token.id, amount, saveCheck ? true : false);
        //console.log('result', result.token.id);
      }

    };

    const inputStyle = {
      base: {
        fontSize: "16px", // Adjust the font size
        color: "#000" // Set the text color
        // fontFamily: "Gotham",
      },
      invalid: {
        color: "#dc3545" // Change color for invalid input
      }
    };
    return (
      <>
        <form onSubmit={handleSubmitStripe}>
          <Box className="addmoney-modal-content modal-content wallet-modal-content">
            {setloading && (
              <Box>
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer - 1,
                  }}
                  open={loading}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              </Box>
            )}

            <FormControl>
              {/* <NewCssNumericInput
                prefix={"$"}
                fullWidth
                name="amount"
                precision={0}
                decimalChar="."
                thousandChar=","
                placeholder={"100"}
                label="Amount"
                //onChange={addBalanceAmount}
                // value={balanceamount}
                variant="filled"
                className="popup-input"
                InputProps={{ disableUnderline: true }}
                inputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      sx={{ color: "fff !important" }}
                    >
                      $
                    </InputAdornment>
                  ),
                  maxLength: 15
                }}
              /> */}
              {/* the above line commented next line added by pp on 02/04/2024 due to decimal value changes */}
              <NewCssTextField
                autoFocus
                id="amount-deposite"
                name="amount"
                label="Amount"
                type="text"
                fullWidth
                variant="filled"
                className="popup-input"
                onChange={handleBalanceAmountChange}
                value={balanceAmount}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                inputProps={{
                  maxLength: 15,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="100"
              />
            </FormControl>

            <FormControl>
              <NewCssTextField
                autoFocus
                name="name"
                id="name"
                label="Name"
                type="text"
                fullWidth
                variant="filled"
                className="popup-input"
                //onChange={addBalanceCardholderName}
                inputProps={{
                  maxLength: 60,
                  disableUnderline: true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                // value={balancecardholdername}
                placeholder={"Account holder name"}
              />
            </FormControl>

            <CardNumberElement
              className={"popup-input-strp"}
              options={{ style: inputStyle }}
            />
            <CardExpiryElement
              className={"popup-input-strp"}
              options={{ style: inputStyle }}
            />
            <CardCvcElement
              className={"popup-input-strp"}
              options={{ placeholder: "CVV", style: inputStyle }}
            />
            {/* by GD 2 Feb 2024 */}

            <FormControl sx={{ marginTop: "1rem" }}>
              <FormControlLabel
                name="saveCardCheckbox"
                id="saveCardCheckbox"
                control={
                  <Checkbox
                  // defaultChecked={SaveCrad == 'yes' ? true : false}
                  // onChange={HandleSaveCard}
                  />
                }
                label="Save card information to pay faster next time."
                sx={{
                  background: "#faa61a",
                  borderRadius: "8px",
                  color: "#fff !important",
                  padding: "5px 0 !important",
                  fontFamily: "Gotham !important",
                  // "& .MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label":
                  //   {
                  //     fontFamily: "Gotham !important",
                  //     fontSize:'14px !important'
                  //   },
                }}
              />
            </FormControl>

            <Button
              type="submit"
              variant="contained"
              className="reset-cal-btn addmoney--checkout-btn new-money-btn transfer-btn"
            >
              Checkout
            </Button>
          </Box>
        </form>
      </>
    );
  },[savedToken]);

  // const addBalance = async (token, amount, card = null, month = null, year = null, cvc = null, is_card_saved = false) => {
  const addBalance = async (token, amount, is_card_saved = false) => {
    let stripe_token = token == null ? 'null' : token;
    let headers = { Authorization: `${AuthToken}`, request_from: "Web" };

    const dataInApi = {
      user_id: UserId.toString(),
      balance: amount,
      token: stripe_token,
      description: UserId + ", added_wallet",

      is_card_saved: is_card_saved,
      // is_card_saved: SaveCrad == 'no' ? false : true,
      // card: card,
      // month: month,
      // year: year,
      // cvc: cvc,
      is_saved: savedToken == 'other' ? false : true
    }
    console.log("dataInApi: ", dataInApi);
    // setloading(false);
    // return;

    const balanceresult: IApiResponseRawData =
      await EquipmentAPIs.addWalletBalance(
        {
          user_id: UserId.toString(),
          balance: amount,
          token: stripe_token,
          description: UserId + ", added_wallet",
          is_card_saved: is_card_saved,
          // is_card_saved: SaveCrad == 'no' ? false : true,
          // card: card,
          // month: month,
          // year: year,
          // cvc: cvc,
          is_saved: savedToken == 'other' ? false : true
        },
        headers
      );

    if (balanceresult["type"] == "RXSUCCESS") {
      console.log("updated balance :  ", balanceresult);
      onSuccess("success");
      // viewWalletHistory(UserId, AuthToken, 0, "new");
      setloading(false);
      dispatch(setalertMessage(balanceresult["message"]));
      dispatch(setalertMessagetype("success"));
      setbalanceamount(null);
      // setbalancecardholdername(null);
    } else if (
      balanceresult["status"] == false &&
      balanceresult["slug"] == "Session Expired"
    ) {
      setloading(false);
      dispatch(setSessionExpire(true));
      return false;
    } else {
      console.log("error in add balance api call :", balanceresult["message"]);
      setloading(false);
      dispatch(setalertMessage(balanceresult["message"]));
      dispatch(setalertMessagetype("error"));
      return false;
    }
  };


  const getSavedCard = async (user_id) => {
    try {
      setloading(true);
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const Result = await EquipmentAPIs.SavedCardList(
        {
          user_id: user_id.toString(),
        },
        headers
      );
      console.log("results in getSavedCard: ", Result);

      if (Result['type'] == "RXSUCCESS") {
        let savedcards = Result.data.data;
        let othercardObj = [{
          id: "other",
          // label: "Another Crad",
          card: {
            last4: "Another Crad",
            brand: "",
          },
        }]

        let final_result = [...savedcards, ...othercardObj];
        // console.log("SaveCards final_result: ", final_result);
        // if added by GD for selecting card if user has no card saved
        if (final_result && final_result.length == 1 && final_result[0].id == "other") {
          setsavedToken("other");
        }
        final_result = final_result.map(cardObj =>{
          if (cardObj.card) {
            cardObj["cardExpiry"] = checkCardExpiry(cardObj.card.exp_year, cardObj.card.exp_month);
          }
          return cardObj;
        })
        // console.log("final_result", final_result);
        setSaveCards(final_result)
        //console.log(Result.data.data[0].card.last4)
        setloading(false);
      }
      else {
        dispatch(setalertMessage(Result['message']));
        dispatch(setalertMessagetype('error'));
        setloading(false);
        return false
      }
    } catch (err) {
      console.log(err);
      setloading(false);
    }
  };

  // commented by gd for live code on 29 Dec
  useEffect(() => {
    if (UserId) {
      getSavedCard(UserId)
    }

  }, [UserId]);

  return (
    <>
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}

      {/* For Staging stripe card save method */}
      <Box className="wallet-accordion" sx={{ background: "transparent !important" }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', margin: '1rem 0' }}>

          {savedToken !== 'other' && <FormControl>
            {/* <NewCssNumericInput
              prefix={"$"}
              fullWidth
              // name="amount"
              precision={0}
              decimalChar="."
              thousandChar=","
              placeholder={"100"}
              label="Amount"
              onChange={addBalanceAmount}
              value={balanceamount}
              variant="filled"
              className="popup-input"
              InputProps={{ disableUnderline: true }}
              inputProps={{
                startadornment: (
                  <InputAdornment
                    position="start"
                    sx={{ color: "fff !important" }}
                  >
                    $
                  </InputAdornment>
                ),
                maxLength: 15
              }}
            /> */}
            {/* the above line commented next line added by pp on 02/04/2024 due to decimal value changes */}
            <NewCssTextField
                autoFocus
                id="amount-deposite_2"
                // name="amount"
                label="Amount"
                type="text"
                fullWidth
                variant="filled"
                className='popup-input'
                onChange={addBalanceAmount}
                value={balanceamount}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                inputProps={{
                  maxLength: 15,
                }}
                InputLabelProps={{
                  shrink: true
                }}
                placeholder="100"

              />
          </FormControl>}


          {/* {SaveCards.length > 0 && SaveCards.map((CardsObj, index) => ( */}
          {(SaveCards.length > 0 && SaveCards.length != 1) && SaveCards.map((CardsObj, index) => (
            <Box key={index}>

              <Box sx={{ background: "#faa61a !important", borderRadius: '10px !important', boxShadow: "unset !important", padding: "0 10px !important", marginLeft: "0 !important", marginRight: "0 !important" }}>

              <div style={{ pointerEvents: CardsObj?.cardExpiry ? 'none' : 'auto', opacity: CardsObj?.cardExpiry ? 0.5 : 1 }}>

                  <FormControlLabel
                    checked={(savedToken == CardsObj.id)}
                    value={CardsObj.id}
                    aria-label="Acknowledge"
                    onClickCapture={(event) => {
                      setsavedToken(CardsObj.id)
                      //Handle card elements
                      // setCardHolder(null)
                      // setCardNumber(null)
                      // setExpMonth(null)
                      // setExpYear(null)
                      // setCvc(null)
                      // setSaveCrad('no')
                    }
                      //event.stopPropagation()
                    }
                    // onFocus={(event) => event.stopPropagation()}
                    control={<Checkbox />}
                    label={CardsObj.id == 'other' ? 'Use Another Card' : '************' + CardsObj.card.last4 + " (" + CardsObj.card.brand + ")"}
                    sx={{ color: "#fff !important", padding: "5px 0 !important", fontFamily: 'Gotham !important' }}
                  />
                  <span style={{color:"red",marginLeft:"0.5rem",fontSize:"12px"}}>{CardsObj?.cardExpiry?"This card is past its expiration date":""}</span>
                </div>
              </Box>
            </Box>
          ))}

          {/* {savedToken == 'other' && (<>
            <Box sx={{ paddingLeft: "0 !important", paddingRight: "0 !important" }}>
              <Box className="addmoney-modal-content modal-content wallet-modal-content">
                {setloading && (
                  <Box>
                    <Backdrop
                      sx={{
                        color: "#fff",
                        zIndex: (theme) => theme.zIndex.drawer - 1
                      }}
                      open={loading}
                    >
                      <CircularProgress color="inherit" />
                    </Backdrop>
                  </Box>
                )}

                <FormControl>
                  <NewCssTextField
                    autoFocus
                    name="name"
                    id="name"
                    label="Name"
                    type="text"
                    fullWidth
                    variant="filled"
                    className="popup-input"
                    onChange={HandleCardHolder}
                    inputProps={{
                      maxLength: 60,
                      disableUnderline: true
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={CardHolder}
                    placeholder={"Card holder name"}
                  />
                </FormControl>
                <FormControl>
                  <NewCssTextField
                    autoFocus
                    name="card"
                    id="card"
                    label="Card number"
                    type="text"
                    fullWidth
                    variant="filled"
                    className="popup-input"
                    onChange={HandleCardNumber}
                    inputProps={{
                      minLength: 16,
                      maxLength: 16,
                      disableUnderline: true
                    }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={CardNumber}
                    placeholder={"xxxx-xxxx-xxxx-xxxx"}
                  />
                </FormControl>
                <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '1rem' }}>
                  <FormControl>
                    <NewCssTextField
                      autoFocus
                      name="month"
                      id="month"
                      label="Exp. Month"
                      type="text"
                      fullWidth
                      variant="filled"
                      className="popup-input"
                      onChange={HandleExpMonth}
                      inputProps={{
                        maxLength: 2,
                        disableUnderline: true
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={ExpMonth}
                      placeholder={"MM"}
                    />
                  </FormControl>
                  <FormControl>
                    <NewCssTextField
                      autoFocus
                      name="year"
                      id="year"
                      label="Exp. Year"
                      type="text"
                      fullWidth
                      variant="filled"
                      className="popup-input"
                      onChange={HandleExpYear}
                      inputProps={{
                        minLength: 2,
                        maxLength: 2,
                        disableUnderline: true
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={ExpYear}
                      placeholder={"YY"}
                    />
                  </FormControl>


                </Box>
                <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '1rem' }}>
                  <FormControl>
                    <NewCssTextField
                      autoFocus
                      name="cvv"
                      id="cvv"
                      label="Cvv"
                      type="text"
                      fullWidth
                      variant="filled"
                      className="popup-input"
                      onChange={HandleCvc}
                      inputProps={{
                        minLength: 3,
                        maxLength: 4,
                        disableUnderline: true
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={Cvc}
                      placeholder={"XXX"}
                    />
                  </FormControl>
                </Box>
                <FormGroup sx={{ marginTop: '1rem' }}>
                  <FormControlLabel control={<Checkbox defaultChecked={SaveCrad == 'yes' ? true : false}
                    onChange={HandleSaveCard} />} label="Save card information to pay faster next time." sx={{ background: '#faa61a', borderRadius: '8px', color: "#fff !important", padding: "5px 0 !important", fontFamily: 'Gotham !important' }} />
                </FormGroup>

              </Box>
            </Box>

          </>)} */}

          {/*by GD 2 Feb 2024 for creating a token  */}
          {savedToken == 'other' &&
            <Elements stripe={stripePromise}>
              <StripeComp />
            </Elements>
          }
        </Box>
        {savedToken !== 'other' &&
          <Box className="wallet-accordion-footer">
            <Box>
              <Button
                type="submit"
                variant="contained"
                className="reset-cal-btn addmoney--checkout-btn new-money-btn transfer-btn"
                sx={{ width: '100%' }}
                onClickCapture={SubmitHandler}
              >
                Checkout
              </Button>
            </Box>
          </Box>
        }
      </Box>

      {/* For Live without stripe card save */}
      {/* <Elements stripe={stripePromise}>
        <StripeComp />
      </Elements> */}
    </>
  );
};
export default MyWallet; 
