import {
  Box,
  Container,
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import socialFB from "assets/images/social_media_FB.png";
import socialIG from "assets/images/social_media_IG.png";
import downloadAppstore from "assets/images/download_appstore.png";
import downloadGooglePlay from "assets/images/download_googleplay.png";
import liveHelp from "assets/images/gem-livehelp.png";
import ServiceItem from "../service-section/ServiceItem";
import iconLending from "assets/images/icons_lending-footer.png";
import iconService from "assets/images/icon-service-footer.png";
import iconDirt from "assets/images/icons_dirtradio-footer.png";
import iconLocation from "assets/images/GPS/gps-icon-footer.png";
import testimonial1 from "assets/images/testimonial_01.png";
import Tracking from "pages/equipment-tracking/Tracking";
import Testimonials from "components/howItWorks/Testimonials/Testimonials";
import "./styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { IStoreValue } from "utils/types";
import { EquipmentAPIs } from "../../service/api-service";
import {
  setLoginModalOpen,
  setLogInLoading,
  setalertMessagetype,
  setalertMessage,
  setSessionExpire,
  SetRedirectPage,
  setEquipAddType,
} from "redux/redux-slice";
import RentSteps from "components/user-signup/signupPopup/RentSteps";
const authorizedPage = [
  { name: "Find", url: "/" },
  { name: "List Equipment", url: "/listequipment" },
  { name: "How It Works", url: "/howItWorks" },
  { name: "App Download", url: "/appdownload" },
  { name: "Order GPS", url: "/equipment-tracking" },
  { name: "Equipment Lending", url: "/equipmentlending" },
  { name: "Contact Us", url: "/contactus" },
  { name: "Legal", url: "/terms_and_conditions" },
];

const unAuthorizedPage = [
  "/listequipment",
  // "/equipment-tracking",
  "/equipmentlending",
  // "/contactus",
];

const Footer = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoggedin = useSelector(
    (state: IStoreValue) => state.equipmentReducer.isLogin
  );
  const Token = useSelector(
    (state: IStoreValue) => state.equipmentReducer.accessToken
  );
  const UserID = useSelector(
    (state: IStoreValue) => state.equipmentReducer.Authuser
  );

  const ProfileStatus = useSelector(
    (state: IStoreValue) => state.equipmentReducer.ProfileStatus
  );

  const EmailVerify = useSelector(
    (state: IStoreValue) => state.equipmentReducer.EmailVerify
  );

  const [completeProfile, setCompleteProfile] = useState<boolean>(false);
  const handleCloseCompleteProfile = () => setCompleteProfile(false);
  // const handleListEquipment = (event) => {
  //     event.stopPropagation();

  // };

  const handlePageAccess = async (accessPage: string = "/") => {
    if (location.pathname === accessPage) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      return;
    }
    const isNotAccessible = unAuthorizedPage.some((url) => url === accessPage);
    if (!isNotAccessible) {
      // window.location.href = accessPage;
      navigate(accessPage);
      return;
    }
    if (!isLoggedin) {
      localStorage.setItem("RedirectLink", accessPage);
      dispatch(setLoginModalOpen(true));
      dispatch(setLogInLoading(false));
      return;
    }
    try {
      const profile_status = ProfileStatus;
      const email_status = EmailVerify;
      dispatch(setLoginModalOpen(false));
      if (email_status == 1) {
        dispatch(setalertMessage("Please verify your email first!"));
        dispatch(setalertMessagetype("error"));
        return;
      }

      if (!profile_status) {
        dispatch(setalertMessage("Please update your profile first!"));
        dispatch(setalertMessagetype("error"));
        return;
      }

      const headers = { Authorization: `${Token}`, request_from: "Web" };
      const profileData = await EquipmentAPIs.getProfile(
        {
          user_id: UserID.toString(),
          // from:"Web"
        },
        headers
      );

      if (profileData["type"] === "RXSUCCESS") {
        if (
          accessPage.includes("listequipment") &&
          parseFloat(profileData?.["data"]?.["is_profile_completed"]) === 0
        ) {
          dispatch(SetRedirectPage(accessPage));
          setCompleteProfile(true);
          return false;
        }
        if (accessPage.includes("listequipment")) {
          dispatch(setEquipAddType("rent"));
        }
        // window.location.href = accessPage;
        navigate(accessPage);
      } else if (
        !profileData["status"] &&
        profileData["slug"] === "Session Expired"
      ) {
        dispatch(setSessionExpire(true));
      } else {
        dispatch(setalertMessage(profileData["message"]));
        dispatch(setalertMessagetype("error"));
      }
    } catch (err) {
      console.log("error in profile fetch api");
      dispatch(setalertMessagetype("error"));
      dispatch(setalertMessage("something went wrong."));
    }
  };

  const PageLinks = () => {
    return (
      <>
        {authorizedPage.map((page: { name: string; url: string }, index) => (
          <ListItem
            key={index}
            sx={{
              width: "auto",
              paddingLeft: "5px",
              paddingRight: "5px",
              cursor: "pointer",
            }}
          >
            <Link
              //   href="/page1"
              underline="none"
              sx={{
                background: "#3330",
                padding: "5px 10px",
                borderRadius: "7px",
                color:
                  page?.url === location.pathname
                    ? "#faa61a !important"
                    : "#fff",
                fontFamily: "Montserrat !important",
                display: "block",
                width: "100%",
                textDecoration: "underline",
                textDecorationColor:
                  page?.url === location.pathname
                    ? "#faa61a !important"
                    : "#fff",
                cursor: "pointer",
                "&:hover": {
                  color: "#faa61a !important",
                  textDecorationColor: "#faa61a !important",
                },
              }}
              onClick={() => handlePageAccess(page?.url)}
            >
              <Typography sx={{ fontFamily: "Montserrat !important" }}>
                {page?.name}
              </Typography>
            </Link>
          </ListItem>
        ))}
      </>
    );
  };

  return (
    <Box>
      <Box>
        <Container>
          <Typography
            sx={{
              fontWeight: "700",
              fontFamily: "GothamBold !important",
              textAlign: "center",
              fontSize: {
                xs: "2rem",
                sm: "2rem",
                md: "2.4rem",
                lg: "2.7rem",
                xl: "3rem",
              },
            }}
          >
            <span style={{ color: "#faa61a" }}>Discover</span>{" "}
            <span style={{ color: "#fff" }}>MORE</span>{" "}
            <span style={{ color: "#faa61a" }}>with GoEquipMe</span>
          </Typography>
          <Box>
            <Box
              sx={{
                mt: 4,
                pb: 4,
                display: "grid",
                gridTemplateColumns: {
                  xs: "1fr",
                  sm: "1fr",
                  md: "repeat(2, 1fr)",
                  lg: "repeat(2, 1fr)",
                  xl: "repeat(2, 1fr)",
                },
                gap: { xs: "1rem", sm: "1.5rem", md: "2rem" },
                padding: { xs: "1rem", sm: "1rem" },
              }}
            >
              <Box
                sx={{
                  boxShadow: "0px 0px 30px 0px rgba(0,0,0,0.5)",
                  borderRadius: "10px",
                  padding: "1.5rem 1.2rem 2.5rem 1.2rem",
                  background: "#47473F",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    gap: "1rem",
                  }}
                >
                  <Box
                    component="img"
                    src={iconLending}
                    alt="location"
                    sx={{
                      width: "100%",
                      height: "auto",
                      maxWidth: { xs: "50px", sm: "75px", lg: "100px" },
                    }}
                  />
                  <Box>
                    <Typography
                      variant="h4"
                      sx={{
                        mb: 1,
                        color: "#faa61a !important",
                        fontFamily: "GothamBold !important",
                        fontSize: {
                          xs: "1.35rem",
                          sm: "1.6rem",
                          md: "1.8rem",
                          lg: "2.1rem",
                        },
                      }}
                    >
                      Equipment Lending
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { sm: "14px", md: "18px" },
                        marginBottom: "1rem",
                        color: "#fff",
                        fontFamily: "Montserrat !important",
                        fontWeight: "400",
                      }}
                    >
                      Our loan specialists will help you get equipment financing
                      for purchases, refinances, and cash-outs.
                    </Typography>
                    <Link
                      href="/equipmentlending"
                      sx={{
                        fontSize: { sm: "14px", md: "18px" },
                        textDecoration: "none",
                        color: "#faa61a !important",
                        textDecorationLine: "underline",
                        textDecorationColor: "#faa61a !important",
                      }}
                      target="_blank"
                    >
                      Tell Me More...
                    </Link>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  boxShadow: "0px 0px 30px 0px rgba(0,0,0,0.5)",
                  borderRadius: "10px",
                  padding: "1.5rem 1.2rem 2.5rem 1.2rem",
                  background: "#47473F",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    gap: "1rem",
                  }}
                >
                  <Box
                    component="img"
                    src={iconService}
                    alt="location"
                    sx={{
                      width: "100%",
                      height: "auto",
                      maxWidth: { xs: "50px", sm: "75px", lg: "100px" },
                    }}
                  />

                  <Box>
                    <Typography
                      variant="h4"
                      sx={{
                        mb: 1,
                        color: "#faa61a !important",
                        fontFamily: "GothamBold !important",
                        fontSize: {
                          xs: "1.35rem",
                          sm: "1.6rem",
                          md: "1.8rem",
                          lg: "2.1rem",
                        },
                      }}
                    >
                      Services Directory
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { sm: "14px", md: "18px" },
                        marginBottom: "1rem",
                        color: "#fff",
                        fontFamily: "Montserrat !important",
                        fontWeight: "400",
                      }}
                    >
                      List your company’s services for FREE on the app and reach
                      a wider audience in your area.
                    </Typography>
                    <Link
                      href="/service-directory"
                      sx={{
                        fontSize: { sm: "14px", md: "18px" },
                        textDecoration: "none",
                        color: "#faa61a !important",
                        textDecorationLine: "underline",
                        textDecorationColor: "#faa61a !important",
                      }}
                      target="_blank"
                    >
                      Tell Me More...
                    </Link>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  boxShadow: "0px 0px 30px 0px rgba(0,0,0,0.5)",
                  borderRadius: "10px",
                  padding: "1.5rem 1.2rem 2.5rem 1.2rem",
                  background: "#47473F",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    gap: "1rem",
                  }}
                >
                  <Box
                    component="img"
                    src={iconDirt}
                    alt="location"
                    sx={{
                      width: "100%",
                      height: "auto",
                      maxWidth: { xs: "50px", sm: "75px", lg: "100px" },
                    }}
                  />

                  <Box>
                    <Typography
                      variant="h4"
                      sx={{
                        mb: 1,
                        color: "#faa61a !important",
                        fontFamily: "GothamBold !important",
                        fontSize: {
                          xs: "1.35rem",
                          sm: "1.6rem",
                          md: "1.8rem",
                          lg: "2.1rem",
                        },
                      }}
                    >
                      Mine Your Music
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { sm: "14px", md: "18px" },
                        marginBottom: "1rem",
                        color: "#fff",
                        fontFamily: "Montserrat !important",
                        fontWeight: "400",
                      }}
                    >
                      We made a radio station just for you! Mine SHVL coin while
                      you listen to awesome music.
                    </Typography>
                    <Link
                      href="https://dirtradio.com"
                      sx={{
                        fontSize: { sm: "14px", md: "18px" },
                        textDecoration: "none",
                        color: "#faa61a !important",
                        textDecorationLine: "underline",
                        textDecorationColor: "#faa61a !important",
                      }}
                      target="_blank"
                    >
                      Get jammin!
                    </Link>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  boxShadow: "0px 0px 30px 0px rgba(0,0,0,0.5)",
                  borderRadius: "10px",
                  padding: "1.5rem 1.2rem 2.5rem 1.2rem",
                  background: "#47473F",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    gap: "1rem",
                  }}
                >
                  <Box
                    component="img"
                    src={iconLocation}
                    alt="location"
                    sx={{
                      width: "100%",
                      height: "auto",
                      maxWidth: { xs: "50px", sm: "75px", lg: "100px" },
                    }}
                  />

                  <Box>
                    <Typography
                      variant="h4"
                      sx={{
                        mb: 1,
                        color: "#faa61a !important",
                        fontFamily: "GothamBold !important",
                        fontSize: {
                          xs: "1.35rem",
                          sm: "1.6rem",
                          md: "1.8rem",
                          lg: "2.1rem",
                        },
                      }}
                    >
                      Order GPS
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { sm: "14px", md: "18px" },
                        marginBottom: "1rem",
                        color: "#fff",
                        fontFamily: "Montserrat !important",
                        fontWeight: "400",
                      }}
                    >
                      With equipment tracking, you know where your equipment and
                      hig-value assets are at all times.
                    </Typography>
                    <Link
                      href="/equipment-tracking"
                      sx={{
                        fontSize: { sm: "14px", md: "18px" },
                        textDecoration: "none",
                        color: "#faa61a !important",
                        textDecorationLine: "underline",
                        textDecorationColor: "#faa61a !important",
                      }}
                      target="_blank"
                    >
                      Tell Me More...
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box sx={{ mb: "1rem" }}>
              <Testimonials />
            </Box>
          </Box>
        </Container>

        <Box
          sx={{
            borderTop: "7px solid #faa61a !important",
            padding: "1rem 0rem",
          }}
        >
          <Box
            sx={{
              margin: "0 auto",
              width: { xs: "90%", sm: "90%", md: "80%", lg: "80%", xl: "80%" },
            }}
          >
            <Box
              sx={{
                mb: "2rem",
              }}
            >
              <Typography
                sx={{
                  background: "transparent",
                  color: "#faa61a !important",
                  fontFamily: "GothamBold !important",
                  fontSize: {
                    xs: "16px",
                    sm: "18px",
                    md: "20px",
                    lg: "24px",
                    xl: "26px",
                  },
                  textAlign: "center",
                }}
              >
                Quick Links
              </Typography>
              <List
                sx={{
                  display: {
                    xs: "flex",
                    sm: "flex",
                    md: "flex",
                    lg: "flex",
                    xl: "flex",
                  },
                  alignItems: "center",
                  justifyContent: "center",
                  flexWrap: "wrap",
                  gap: "0rem",
                }}
              >
                <PageLinks />
              </List>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "0rem",
                ...(location.pathname === "/service-directory" && {
                  padding: "1rem 0 9rem 0",
                }),
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: {
                    xs: "center",
                    sm: "center",
                    md: "space-between",
                    lg: "space-between",
                    xl: "space-between",
                  },
                  gap: "1rem",
                  flexDirection: {
                    xs: "column",
                    sm: "column",
                    md: "row",
                    lg: "row",
                    xl: "row",
                  },
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: { md: "380px", lg: "500px", xl: "500px" },
                    transform: "translate(-30%, -50%)",
                    backgroundColor: "gray",
                    width: "2px",
                    height: "60px",
                    display: {
                      xs: "none",
                      sm: "none",
                      md: "block",
                      lg: "block",
                      xl: "block",
                    },
                  }}
                ></Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: {
                      xs: "center",
                      sm: "center",
                      md: "center",
                      lg: "flex-start",
                    },
                    gap: "1rem",
                  }}
                >
                  <Typography variant="h5" className="find-us">
                    Find us on
                  </Typography>
                  <Link
                    href="https://www.facebook.com/Goequipme"
                    target="_blank"
                    className="social-icon"
                  >
                    <Box
                      component="img"
                      src={socialFB}
                      alt="Social Facebook"
                      sx={{
                        width: "100%",
                        height: "auto",
                        maxWidth: { xs: "35px", sm: "50px", lg: "60px" },
                      }}
                    ></Box>
                  </Link>
                  <Link
                    href="https://www.instagram.com/goequipme/"
                    target="_blank"
                    className="social-icon"
                  >
                    <Box
                      component="img"
                      src={socialIG}
                      alt="Social Instagram"
                      sx={{
                        width: "100%",
                        height: "auto",
                        maxWidth: { xs: "35px", sm: "50px", lg: "60px" },
                      }}
                    ></Box>
                  </Link>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: {
                      xs: "center",
                      sm: "center",
                      md: "center",
                      lg: "flex-end",
                    },
                    gap: "1rem",
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      color: "#faa61a",
                      fontSize: {
                        xs: "14px",
                        sm: "18px",
                        md: "23px",
                        lg: "30px",
                      },
                      width: "max-content",
                    }}
                  >
                    Get the App!
                  </Typography>
                  <Link
                    target="_blank"
                    href="https://apps.apple.com/us/app/goequipme-peer-to-peer/id1502954874"
                  >
                    <Box
                      component="img"
                      src={downloadAppstore}
                      alt="Download Appstore"
                      sx={{
                        width: "100%",
                        height: "auto",
                        maxWidth: { xs: "75px", sm: "100px", lg: "150px" },
                      }}
                    ></Box>
                  </Link>
                  <Link
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=com.goequipeme"
                  >
                    <Box
                      component="img"
                      src={downloadGooglePlay}
                      alt="Download GooglePlay"
                      sx={{
                        width: "100%",
                        height: "auto",
                        maxWidth: {
                          xs: "75px",
                          sm: "100px",
                          lg: "150px",
                        },
                      }}
                    ></Box>
                  </Link>
                </Box>
              </Box>

              <Box
                sx={{
                  width: "100%",
                  margin: "1rem 0",
                  display: "flex",
                  justifyContent: {
                    xs: "center",
                    sm: "center",
                    md: "flex-end",
                    lg: "flex-end",
                    xl: "flex-end",
                  },
                  alignItems: "center",
                }}
              >
                <Link
                  href="/terms_and_conditions"
                  target="_blank"
                  sx={{
                    color: "#faa61a",
                    textDecoration: "none !important",
                    cursor: "pointer",
                    margin: "0 1rem 0 0",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "12px",
                        sm: "14px",
                        md: "16px",
                        lg: "16px",
                        xl: "16px",
                      },
                    }}
                  >
                    Terms and Conditions
                  </Typography>
                </Link>
                <Link
                  href="/privacy_policy"
                  target="_blank"
                  sx={{
                    color: "#faa61a",
                    textDecoration: "none !important",
                    cursor: "pointer",
                    margin: "0 1rem 0 0",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "12px",
                        sm: "14px",
                        md: "16px",
                        lg: "16px",
                        xl: "16px",
                      },
                    }}
                  >
                    Privacy Policy
                  </Typography>
                </Link>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <RentSteps
        open={completeProfile}
        handleClose={handleCloseCompleteProfile}
      />
    </Box>
  );
};

export default Footer;
