import React, { useCallback, useEffect, useState } from "react";
import { User } from "./User"; // component display user (see detail on /example directory)
import {
  LoginSocialGoogle,
  LoginSocialFacebook,
  LoginSocialApple,
  IResolveParams,
} from "reactjs-social-login";

// CUSTOMIZE ANY UI BUTTON
import {
  FacebookLoginButton,
  GoogleLoginButton,
  AppleLoginButton,
} from "react-social-login-buttons";

import { EquipmentAPIs } from "../../service/api-service";
import { IApiResponseRawData } from "utils/types";
import { Backdrop, Box, CircularProgress, Typography } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import {
  setLoginStatus,
  setaccessToken,
  setLogInLoading,
  setalertMessagetype,
  setalertMessage,
  setSocialEmailVerifiedModalOpen,
} from "redux/redux-slice";
import { IStoreValue } from "utils/types";
import "./style.scss";

import appleLogo from "assets/images/Social-Icon/apple.png";
import googleLogo from "assets/images/Social-Icon/gmail.png";
import fbLogo from "assets/images/Social-Icon/facebook.png";

import SocialEmailVerifyModal from "./socialEmailVerificationModal";
// REDIRECT URL must be same with URL where the (reactjs-social-login) components is locate
// MAKE SURE the (reactjs-social-login) components aren't unmounted or destroyed before the ask permission dialog closes
const REDIRECT_URI = window.location.href;
const _window = window as any;
const SocialLogin = ({ onSucess, onLoading }) => {
  const [provider, setProvider] = useState("");
  const [profile, setProfile] = useState<any>();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const isLoggedin = useSelector(
    (state: IStoreValue) => state.equipmentReducer.isLogin
  );
  const [isUserVerified, setIsUserVerified] = useState(true);
  const [socialLoginData, setSocialLoginData] = useState({});
  const [emailVerifyModelOpen, setEmailVerifyModelOpen] = useState(false);
  let type = process.env.REACT_APP_PLATFORM;
  let app_redirect_url = "https://www.goequipmestaging.com/";
  // if (type == "Live") {
  //   app_redirect_url = "https://goequipme.com/";
  // }
  // if (type == "PreLive") {
  //   app_redirect_url = "https://goequipmepreliveweb.com/";
  // }
  // by GD on 2 jan 2024
  // let app_redirect_url = process.env.REACT_APP_STAGING_REDIRECT_URL;
  if (type == "Live") {
    app_redirect_url = process.env.REACT_APP_LIVE_REDIRECT_URL;
  }
  if (type == "PreLive") {
    app_redirect_url = process.env.REACT_APP_PRELIVE_REDIRECT_URL;
  }
  //console.log('app_redirect_url',app_redirect_url);

  const onLoginStart = useCallback(() => {
    onLoading("true");
  }, []);

  const onLogoutSuccess = useCallback(() => {
    setProfile(null);
    setProvider("");
    dispatch(setaccessToken(null));
    alert("logout success");
  }, []);
  // const emailVerifyModelOpen = useSelector((state: IStoreValue) => state.equipmentReducer.socialEmailVerifiedModalOpen);
  useEffect(() => {
    console.log("emailVerifyModelOpen in social: ", emailVerifyModelOpen);
  }, [emailVerifyModelOpen]);

  const login = async (body) => {
    try {
      setLoading(true);
      // const Auth_login: IApiResponseRawData = await EquipmentAPIs.socialLogin(
      //   body
      // );

      const Auth_login: IApiResponseRawData = await EquipmentAPIs.socialLoginV2(
        body
      );

      if (Auth_login["type"] == "RXSUCCESS") {
        console.log("login success: ", Auth_login);
        // if(Auth_login['data']['is_user_verify'] === '0'){
        //   console.log("in sos if: " , isUserVerified);

        //   setSocialLoginData(Auth_login['data']);
        //   dispatch(setSocialEmailVerifiedModalOpen(true));
        //   setEmailVerifyModelOpen(true);
        //   setIsUserVerified(false);
        // }
        // return;
        setProvider("success");
        setProfile(body);
        setLoading(false);
        let auth_user = Auth_login["data"];
        auth_user["first_name"] = body?.first_name ? body?.first_name : "";
        auth_user["last_name"] = body?.last_name ? body?.last_name : "";

        onSucess(auth_user);

        dispatch(setLoginStatus(true));
        dispatch(setLogInLoading(false));
      } else {
        // setLoading(false);
        // dispatch(setLogInLoading(false));
        // dispatch(setalertMessage(Auth_login["message"]));
        // dispatch(setalertMessagetype("error"));
        // dispatch(setaccessToken(null));
        // dispatch(setLoginStatus(true));
        throw new Error(Auth_login["message"]);
      }
    } catch (err) {
      console.log(err);
      setProvider("");
      setProfile(null);
      setLoading(false);
      dispatch(setalertMessage(err["message"]));
      dispatch(setalertMessagetype("error"));
      dispatch(setLogInLoading(false));
      dispatch(setaccessToken(null));
      dispatch(setLoginStatus(true));
    }
  };

  const LoadAppleProfile = (data) => {
    let token = data["authorization"]["id_token"];
    let decode_token = JSON.parse(atob(token.split(".")[1]));
    let apple_obj = {
      apple_id: decode_token.sub,
      login_type: "A",
      // email: decode_token.email,
      first_name: data.given_name,
    };
    login(apple_obj);
  };

  const LoadGoogleProfile = (data) => {
    let google_obj = {
      google_id: data.sub,
      login_type: "G",
      email: data.email,
      first_name: data.given_name,
      last_name: data.family_name,
    };
    // console.log('google_obj',data);
    login(google_obj);
  };
  const LoadFBProfile = (data) => {
    const xhr = new XMLHttpRequest();
    xhr.open(
      "GET",
      "https://graph.facebook.com/v2.5/me?fields=email,name,friends&access_token=" +
        data.accessToken
    );
    xhr.onload = function () {
      if (xhr.status === 200) {
        let FBresult = JSON.parse(xhr.response);
        if (FBresult) {
          let fb_obj = {
            facebook_id: FBresult.id,
            login_type: "F",
            email: FBresult.email,
            first_name: FBresult.name,
          };
          login(fb_obj);
        }
      }
    };
    xhr.send();
  };

  return (
    <>
      {/* <div style={{ textAlign: 'center' }}> */}

      {!provider && !profile && (
        <>
          {/* <div className={`App ${provider && profile ? 'hide' : ''}`}> */}
          {loading && (
            <Box>
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loading}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            </Box>
          )}

          <Typography sx={{ color: "#fff" }}>Login with</Typography>
          <Box className="login-social-icons">
            <LoginSocialApple
              client_id={process.env.REACT_APP_APPLE_ID || ""}
              scope={"name email"}
              // redirect_uri="https://www.goequipme.com/"
              redirect_uri={app_redirect_url}
              onLoginStart={onLoginStart}
              onResolve={({ provider, data }: IResolveParams) => {
                // console.log('success...');
                console.log("data and provider in apple login: ", {
                  data,
                  provider,
                });
                // return;
                // setProvider(provider)
                // setProfile(data)
                dispatch(setLogInLoading(true));
                LoadAppleProfile(data);
                onLoading("false");
              }}
              onReject={(err) => {
                onLoading("false");
                let str = err["err"].error;
                let msg = str.replace(/_/g, " ");
                dispatch(setalertMessage(msg));
                dispatch(setalertMessagetype("error"));
                console.log(err);
              }}
            >
              {/* <AppleLoginButton /> */}
              <Box className="login-icon-img">
                <img src={appleLogo} alt="GoEquipMe" />
              </Box>
            </LoginSocialApple>

            <LoginSocialFacebook
              isOnlyGetToken
              // appId={process.env.REACT_APP_FB_APP_ID || ''}
              appId={
                type == "PreLive"
                  ? process.env.REACT_APP_PRELIVE_FB_APP_ID
                  : process.env.REACT_APP_FB_APP_ID
              }
              onLoginStart={onLoginStart}
              onResolve={({ provider, data }: IResolveParams) => {
                console.log("data and provider in facebook login: ", {
                  data,
                  provider,
                });
                // return;
                // setProvider(provider)
                // setProfile(data)
                dispatch(setLogInLoading(true));
                LoadFBProfile(data);
                onLoading("false");
              }}
              onReject={(err) => {
                onLoading("false");
                dispatch(setalertMessage("Something went wrong"));
                dispatch(setalertMessagetype("error"));
                console.log("FB err", err);
              }}
              className="float social__login-link_btn"
            >
              {/* <FacebookLoginButton className='fb-btn' /> */}
              <Box className="login-icon-img">
                <img src={fbLogo} alt="GoEquipMe" />
              </Box>
            </LoginSocialFacebook>

            <LoginSocialGoogle
              //isOnlyGetToken
              scope="https://www.googleapis.com/auth/userinfo.profile"
              client_id={process.env.REACT_APP_GG_APP_ID || ""}
              onLoginStart={onLoginStart}
              onResolve={({ provider, data }: IResolveParams) => {
                console.log("data and provider in google login: ", {
                  data,
                  provider,
                });
                // return;
                // setProvider(provider)
                // setProfile(data)
                dispatch(setLogInLoading(true));
                dispatch(setLoginStatus(true));
                LoadGoogleProfile(data);
                // login(data, 'G')
                onLoading("false");
              }}
              onReject={(err) => {
                onLoading("false");
                dispatch(
                  setalertMessage("Failed to log in. Please try again.")
                );
                dispatch(setalertMessagetype("error"));
                console.log("err", err);
              }}
              className="float social__login-link_btn"
            >
              {/* <GoogleLoginButton className='google-btn' /> */}
              <Box className="login-icon-img">
                <img src={googleLogo} alt="goequipme" />
              </Box>
            </LoginSocialGoogle>
          </Box>

          {/* {(!isUserVerified) && 
          <SocialEmailVerifyModal
          data={socialLoginData}/>
          } */}

          {/* {emailVerifyModelOpen &&
              <SocialEmailVerifyModal
                emailVerifyModelOpen={emailVerifyModelOpen}
                setEmailVerifyModelOpen={setEmailVerifyModelOpen}
                data={socialLoginData}
              />
            } */}
        </>
      )}
    </>
  );
};
export default SocialLogin;
