import { Box, Button, Typography } from "@mui/material";
import React from "react";
import docIcon from "../../../assets/images/doc-icon.png";

const ProToolsCard = ({ data, openFile }) => {
  return (
    <Box
      sx={{
        borderRadius: "15px",
        padding: "15px",
        backgroundColor: "#484743",
        color: "white",
        textAlign: "center",
        width: "100%",
        margin: "auto",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        gap: "1rem",
        boxSizing: "border-box",
        height:'100%'
      }}
    >
      {/* Emoji Section */}
      <Box
        component={"img"}
        src={docIcon}
        sx={{
          display: "block",
          width: "60px",
          height: "auto",
          objectFit: "contain",
        }}
      />

      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          flexDirection: "column",
          gap: "5px",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontFamily: "GothamBold !important",
            fontWeight: "600",
            color: "#faa61a",
          }}
        >
          {data?.link_text}
        </Typography>

        <Typography
          variant="body2"
          sx={{
            color: "#d3d3d3",
            textAlign: "left",
            marginBottom: "10px",
            display: "-webkit-box", // Required for multiline truncation
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 1, // Limit to 2 lines
            overflow: "hidden", // Ensures the text is clipped
            textOverflow: "ellipsis",
            wordBreak: "break-word",
          }}
        >
          {data?.link}
          {/* {data?.link?.slice(0,50)}{data?.link?.length>50?"...":""} */}
        </Typography>

        <Button
          sx={{
            backgroundColor: "#2D9540",
            fontFamily: "Montserrat !important",
            fontWeight: "600",
            color: "#fff",
            borderRadius: "8px",
            textTransform: "capitalize",
            padding: "5px 14px",
            "&:hover": {
              backgroundColor: "#1E8130",
            },
          }}
          onClick={() => openFile(data?.link)}
        >
          Open File
        </Button>
      </Box>
    </Box>
  );
};

export default ProToolsCard;
