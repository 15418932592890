import {
  Box,
  Button,
  Container,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import MainLayout from "layouts/MainLayout";
import { useDispatch, useSelector } from "react-redux";
import { setalertMessagetype, setalertMessage } from "redux/redux-slice";
import { EquipmentAPIs } from "../../service/api-service";
import React, { useEffect, useState } from "react";
import AlertModal from "components/common/AlertModal";
import { useNavigate } from "react-router-dom";
import { IStoreValue } from "utils/types";
const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: "10px",
    "& fieldset": {
      border: "2px solid #faa61a",
      borderRadius: "10px",
    },
    "&:hover fieldset": {
      borderColor: "#faa61a",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#faa61a",
    },
  },
  "& .MuiInputBase-input": {
    color: "black",
    backgroundColor: "#fff",
    fontFamily: "Montserrat !important",
    fontSize: "14px",
    fontWeight: "500",
  },
});

const Contact = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const AuthUserId = useSelector(
    (state: IStoreValue) => state.equipmentReducer.Authuser
  );
  const [feedback, setFeedback] = useState<string>("");
  const [supportMsg, setSupportMsg] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [alertModalIsOpen, setAlertModalIsOpen] = useState<boolean>(false);
  const handleFeedbackChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFeedback(event.target.value);
  };
  const handleSupportMsgChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSupportMsg(event.target.value);
  };

  const handleAlertModalOpen = () => setAlertModalIsOpen(true);
  const handleAlertModalClose = () => setAlertModalIsOpen(false);

  const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    try {
      event.preventDefault();
      if (!feedback?.trim() && !supportMsg?.trim()) {
        dispatch(
          setalertMessage("Please Enter text in Contact support or App feed")
        );
        dispatch(setalertMessagetype("error"));
        return false;
      }

      const mailformat =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const params = {
        user_id: AuthUserId,
        feedback: feedback?.trim(),
        contact: supportMsg?.trim(),
      };
      const response = await EquipmentAPIs.sendContactMail(params);
      if (response["type"] === "RXSUCCESS") {
        setMessage(response["message"]);
        handleAlertModalOpen();
        setFeedback("");
        setSupportMsg("");
      } else {
        throw new Error(response["message"]);
      }
    } catch (err) {
      dispatch(setalertMessage(err?.message));
      dispatch(setalertMessagetype("error"));
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <MainLayout>
      <Box>
        <Container>
          <Box sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
            {/* <Box
              component={"img"}
              src=""
              sx={{
                width: {
                  xs: "2rem",
                  sm: "2rem",
                  md: "2.4rem",
                  lg: "2.6rem",
                  xl: "2.8rem",
                },
                height: {
                  xs: "2rem",
                  sm: "2rem",
                  md: "2.4rem",
                  lg: "2.6rem",
                  xl: "2.8rem",
                },
                objectFit: "contain",
              }}
            /> */}
            <Typography
              sx={{
                fontWeight: "700",
                fontFamily: "GothamBold !important",
                fontSize: {
                  xs: "2rem",
                  sm: "2rem",
                  md: "2.4rem",
                  lg: "2.6rem",
                  xl: "2.8rem",
                },
                color: "#fff",
              }}
            >
              Contact Us
            </Typography>
          </Box>

          <Box
            component={"form"}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              mt: 4,
            }}
          >
            <Box
              sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  sx={{
                    color: "#faa61a",
                    fontFamily: "GothamBold !important",
                    fontSize: "18px",
                  }}
                >
                  Contact Support
                </Typography>

                <Typography
                  sx={{ color: "#fff", fontFamily: "Gotham !important" }}
                >
                  Please tell us how we can help. We will answer as soon as
                  possible.
                </Typography>
              </Box>
              <CustomTextField
                multiline
                rows={4}
                variant="outlined"
                fullWidth
                InputProps={{
                  style: {
                    backgroundColor: "#fff",
                  },
                }}
                value={supportMsg}
                onChange={handleSupportMsgChange}
              />
            </Box>
            <Box
              sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  sx={{
                    color: "#faa61a",
                    fontFamily: "GothamBold !important",
                    fontSize: "18px",
                  }}
                >
                  Web Feedback
                </Typography>
                <Typography
                  sx={{ color: "#fff", fontFamily: "Gotham !important" }}
                >
                  We would love to hear back from you on how we are doing and
                  where we might be falling short. If there are things that dont
                  work or bugs you have encounted please tell us.
                </Typography>
              </Box>
              <CustomTextField
                multiline
                rows={4} // Number of rows for the multiline textarea
                variant="outlined"
                fullWidth
                InputProps={{
                  style: {
                    backgroundColor: "#fff", // Ensures background is white
                  },
                }}
                value={feedback}
                onChange={handleFeedbackChange}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                mt: 3,
              }}
            >
              <Button
                type="button"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "8px",
                  backgroundColor: "#149247",
                  color: "#fff",
                  fontSize: "20px",
                  fontFamily: "Montserrat !important",
                  fontWeight: "700",
                  minHeight: "unset",
                  minWidth: "unset",
                  padding: "0.4rem 1rem",
                  width: {
                    xs: "80%",
                    sm: "80%",
                    md: "40%",
                  },
                  textTransform: "capitalize",
                  "&:hover": {
                    backgroundColor: "#106f37",
                  },
                }}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Box>
          </Box>
          {alertModalIsOpen && (
            <AlertModal
              message={message}
              alertModalIsOpen={alertModalIsOpen}
              handleAlertModalClose={handleAlertModalClose}
            />
          )}
        </Container>
      </Box>
    </MainLayout>
  );
};

export default Contact;
