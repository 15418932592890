import React, { useCallback, useEffect, useState, useRef } from "react";
import { EquipmentAPIs } from "../../service/api-service";
import {
  Box,
  CircularProgress,
  Link,
  Typography,
  Backdrop,
  Container,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  useMediaQuery,
  Modal,
  IconButton,
  Divider,
} from "@mui/material";
import EquipmentItem from "../equipment-item/EquipmentItem";
import Cross from "assets/images/cross.png";
import { useDispatch, useSelector } from "react-redux";
import {
  setLoginStatus,
  setLoginModalOpen,
  setalertMessagetype,
  setalertMessage,
  setaccessToken,
  setLogInLoading,
  setSessionExpire,
} from "redux/redux-slice";
import { IApiResponseRawData, IStoreValue } from "utils/types";
import gemArrow from "assets/images/gem-arrow.png";
import CloseIcon from "@mui/icons-material/Close";
import "./styles.scss";
import { useParams } from "react-router-dom";

import moment from "moment";

import cross from "assets/images/Social-Icon/cross-delete.png";
import "./newstyles.scss";
import NewRentalDetail from "./NewRentalDetail";
import CurrentFormat from "../common/CurrenyFormat";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  TelegramShareButton,
  TelegramIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
  FacebookMessengerIcon,
} from "react-share";
//import Gps from './Gps/Gps';

var base_url = window.location.origin;
var base_url_link = "";
const loginstyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
  p: 4,
};

/**
 * Function: MyOfficeTrailer
 * Description:
 * this is the main rental yard component for listing details etc.
 * Fetches rental equipment data from the server, updates the state, and handles success or error scenarios.
 * If successful, sets the current page, updates the total results, and sets the default active sale equipment.
 * If an error occurs, dispatches relevant alert messages and handles session expiration.
 */
const MyOfficeTrailer = ({
  ChatClick = null,
  isLoad = false,
  UpdateTab,
  ReloadTab = 0,
  reloadTabUpdate,
  setActiveStep,
  handleListEquipment,
}) => {
  const dispatch = useDispatch();
  const equipId = useRef();
  const AuthToken = useSelector(
    (state: IStoreValue) => state.equipmentReducer.accessToken
  );
  const AuthUserID = useSelector(
    (state: IStoreValue) => state.equipmentReducer.Authuser
  );
  const AuthProfileData = useSelector(
    (state: IStoreValue) => state.equipmentReducer.AuthProfileData
  );
  const { uId, equipmentId } = useParams(); // Access route parameters
  const LoginPopup = () => {
    dispatch(setLoginModalOpen(true));
    dispatch(setLogInLoading(false));
  };
  const matchesMobileX = useMediaQuery("(max-width:425px)");

  const [NewEquipAdded, setNewEquipAdded] = React.useState(false);
  const [loading, setloading] = React.useState(false);
  // const [ActiveStep, setActiveStep] = React.useState(1);
  const [loadingdetail, setloadingdetail] = React.useState(true);
  const [Actionequip, setActionequip] = React.useState([]);
  const [AllEquips, setAllEquips] = React.useState([]);
  const [paginationPage, setpaginationPage] = React.useState(5);
  const [NewPageLen, setNewPageLen] = React.useState(5);
  const [Profile, setProfile] = React.useState(null);
  const [ActiveEquip, setActiveEquip] = React.useState(null);
  const [ActiveIndex, setActiveIndex] = React.useState(0);
  const [ActiveObj, setActiveObj] = React.useState(null);
  const [ShareYardPopup, setShareYardPopup] = React.useState(false);

  const [CurrentActiveEquip, setCurrentActiveEquip] = React.useState(null);
  const [UpdateCallback, setUpdateCallback] = React.useState(null);

  const today = moment().format("YYYY-MM-DD");

  /**
   * Function: getuserprofile
   * Description:
   * Fetches the user profile data based on the provided user ID and authorization token.
   * If successful, updates the profile state and triggers additional actions.
   *
   * @param {string} user_id - The user ID for which the profile data is to be fetched.
   * @param {string} Token - The authorization token used for the API request.
   */
  const getuserprofile = async (user_id, Token) => {
    try {
      // Call the API to get the user profile data
      const headers = { Authorization: `${Token}`, request_from: "Web" };
      const profileData: IApiResponseRawData = await EquipmentAPIs.getProfile(
        {
          user_id: user_id.toString(),
        },
        headers
      );

      // Check if the API request was successful
      if (profileData["type"] == "RXSUCCESS") {
        let lat = profileData["data"]["lat"];
        let lng = profileData["data"]["lng"];

        // Update the profile state with the fetched data
        setProfile(profileData["data"]);
        // ListHome(user_id, lat, lng)
        /*********/
        //fetching user's equipment's
        MyEquip(user_id, Token);
        /*********/
      } else if (
        profileData["status"] == false &&
        profileData["slug"] == "Session Expired"
      ) {
        // Handle session expiration scenario
        dispatch(setSessionExpire(true));
        return false;
      } else {
        // Handle other error scenarios
        dispatch(setalertMessage(profileData["message"]));
        dispatch(setalertMessagetype("error"));
        return false;
      }
    } catch (err) {
      // Handle errors that occur during the API request
    }
  };

  /**
   * Function: MyEquip
   * Description:
   * Fetches the user's listed equipment data based on the provided user ID and authorization token.
   * Handles pagination, filtering, and sorting of the equipment data.
   * If successful, updates various state variables for displaying equipment on the UI.
   *
   * @param {string} user_id - The user ID for which the listed equipment data is to be fetched.
   * @param {string} Token - The authorization token used for the API request.
   */
  const MyEquip = async (user_id, Token) => {
    try {
      console.log("loading.. myequip");
      setloading(true);

      // Call the API to get the user's listed equipment data
      const headers = { Authorization: `${Token}`, request_from: "Web" };
      const OfficeTrailerData = await EquipmentAPIs.MyListedEquip(
        {
          user_id: user_id.toString(),
          page: "0",
        },
        headers
      );
      // console.log("OfficeTrailerData :" , OfficeTrailerData);

      // Check if the API request was successful
      if (OfficeTrailerData["type"] == "RXSUCCESS") {
        //  console.log('loading.. OfficeTrailerData: ' , OfficeTrailerData);

        let equip = OfficeTrailerData["data"];
        // let _last = equip;

        // Note: The commented-out code appears to be related to filtering and sorting,
        // but it is currently disabled. Uncomment and modify as needed.
        /********************** */
        var uniqObjs = [];
        var dupeObjs = [];
        equip.forEach((obj) =>
          [uniqObjs, dupeObjs][
            +(
              equip.map((obj) => obj.id).filter((id) => id === obj.id).length >
              1
            )
          ].push(obj)
        );

        dupeObjs = dupeObjs.filter((item, index) => {
          return item.order_status != "completed";
        });
        dupeObjs = dupeObjs.filter((item, index) => {
          return item.order_status != "returned";
        });
        dupeObjs = dupeObjs.sort((item, item2) => {
          return (
            moment(item.order_start_date, "YYYY-MM-DD").valueOf() -
            moment(item2.order_start_date, "YYYY-MM-DD").valueOf()
          );
        });

        dupeObjs = dupeObjs.filter((item, index, a) => {
          return a.findIndex((v2) => v2.id === item.id) === index;
        });

        var _LastArray = [];
        var _newARRAY = [];
        _LastArray = dupeObjs;
        _newARRAY = dupeObjs;
        var _newDATA = [];
        equip.map((item, index) => {
          if (
            _LastArray.filter((item2) => {
              return item2.id == item.id;
            }).length == 0
          ) {
            _newDATA.push(item);
          }
        });

        if (_newDATA.length != 0) {
          _newDATA = _newDATA.filter((item, index, a) => {
            return a.findIndex((v2) => v2.id === item.id) === index;
          });
        }
        // console.log('_newDATA', _newDATA);
        // console.log('_newARRAY', _newARRAY);
        _LastArray = [..._newDATA, ..._newARRAY];

        var actionNeededDataEquip = [];
        var otherDataEquip = [];
        var compeltedDataEquip = [];
        var notDelivered = [];
        _LastArray.map((item, index) => {
          item.order_start_date == today &&
          item.order_status == "payment_complete"
            ? actionNeededDataEquip.push(item)
            : (item.order_end_date == today &&
                (item.order_status == "payment_complete" ||
                  item.order_status == "pickup" ||
                  item.order_status == "returned")) ||
              item.order_status == "returned"
            ? actionNeededDataEquip.push(item)
            : item.order_status == "completed"
            ? compeltedDataEquip.push(item)
            : item.order_status == "payment_complete" ||
              item.order_status == "pickup"
            ? otherDataEquip.push(item)
            : notDelivered.push(item);
        });
        // /********************** */
        let _last = [
          ...actionNeededDataEquip,
          ...otherDataEquip,
          ...compeltedDataEquip,
          ...notDelivered,
        ];

        let _new = [];

        // Process equipment data for display and set state variables
        let new_indx = null;
        if (_last.length > 0 && UpdateCallback != "success") {
          console.log("in not success if");

          // Check if there is a specific equipment to highlight (from local storage)
          let NewEquipe = localStorage.getItem("EquipID");
          let equipType = localStorage.getItem("EquipType");
          if (NewEquipe == null) {
            // setCurrentActiveEquip(_last[0].id)
            // setActiveEquip(_last[0])

            // by GD 15 Mar 2024 for email redirections
            console.log("uID befor if: ", uId, equipmentId);
            if (uId && equipmentId && uId == user_id) {
              _last.map((equipment, indexx) => {
                if (equipmentId == equipment["id"]) {
                  setActiveEquip(equipment);
                  setCurrentActiveEquip(equipment["id"]);
                  _new.push(equipment);
                  new_indx = indexx;
                }
                // if (uId && equipmentId) {
                //   console.log("in uid and equipmentId if");

                //   if (user_id == uId && equipment['id'] == equipmentId) {
                //     console.log("in uid second if");

                //     setActiveEquip(equipment);
                //     setCurrentActiveEquip(equipment['id']);
                //     _new.push(equipment)
                //     new_indx = indexx
                //   }
                // }
              });
            } else {
              setCurrentActiveEquip(_last[0].id);
              setActiveEquip(_last[0]);
            }
            // end by GD 15 March
          } else {
            _last.map((equipment, indexx) => {
              if (NewEquipe == equipment["id"]) {
                setActiveEquip(equipment);
                setCurrentActiveEquip(equipment["id"]);
                _new.push(equipment);
                new_indx = indexx;
              }
              // by GD 15 Mar 2024 for email redirections
              // console.log("uID befor if: " , uId , equipmentId);

              // if(uId && equipmentId){
              //   console.log("in uid and equipmentId if");

              //   if(user_id == uId && equipment['id'] == equipmentId){
              //   console.log("in uid second if");

              //     setActiveEquip(equipment);
              //     setCurrentActiveEquip(equipment['id']);
              //     _new.push(equipment)
              //     new_indx = indexx
              //   }
              // }
              // end by GD 15 March
            });

            if (
              equipType != "undefined" &&
              equipType != "sale" &&
              equipType != null
            ) {
              if (NewEquipe != "undefined" && NewEquipe != null) {
                localStorage.removeItem("EquipID");
                localStorage.removeItem("EquipType");
              }
            }

            //localStorage.removeItem("EquipID");
          }
          setloadingdetail(false);
        }

        if (new_indx != null) {
          _last.splice(new_indx, 1);
        }

        let finalArray = [..._new, ..._last];

        // Update state variables for displaying equipment on the UI
        setActionequip(finalArray);
        // console.log("final array outside conditions: ", finalArray);

        if (UpdateCallback == "success") {
          console.log("in success yes if");
          // by GD 4 jan 2024 due to error on delete an equiment
          if (finalArray[ActiveIndex]) {
            // console.log("in if update UpdateCallback");
            // console.log("in if finalArray: " , finalArray);

            setCurrentActiveEquip(finalArray[ActiveIndex].id);
            setActiveEquip(finalArray[ActiveIndex]);
            setAllEquips(finalArray.slice(0, NewPageLen));
          } else if (ActiveIndex != 0 && finalArray.length >= ActiveIndex) {
            // console.log("in else update UpdateCallback");
            // console.log("finalArray.length: " , finalArray.length);
            // console.log("active index in else: " ,ActiveIndex );
            // console.log("finalArray in else: " ,finalArray );

            setActiveEquip(finalArray[ActiveIndex - 1]);
            setCurrentActiveEquip(finalArray[ActiveIndex - 1].id);
            setActiveIndex(ActiveIndex - 1);
            setAllEquips(finalArray.slice(0, NewPageLen));
            // new line added on 5 jan

            // setActiveEquip(equipment);
            // setCurrentActiveEquip(equipment.id);
            // setActiveIndex(indx);
          } else {
            // console.log("in last else");

            setAllEquips(finalArray.slice(0, NewPageLen));
          }

          // end code by GD on 4 jan 2024 and commented the below three lines
          // setCurrentActiveEquip(finalArray[ActiveIndex].id)
          // setActiveEquip(finalArray[ActiveIndex])
        } else {
          console.log("in last last else else");
          // added if by GD 8 jan 2024 due to err in default selected on page loading
          // if(finalArray[0]){
          //   setActiveIndex(0);
          //   setCurrentActiveEquip(finalArray[0].id);
          //   setActiveEquip(finalArray[0]);
          //   setAllEquips(finalArray.slice(0, NewPageLen));
          // }else{
          // setAllEquips(finalArray.slice(0, paginationPage));
          // }
          // end by GD
          setAllEquips(finalArray.slice(0, paginationPage));

          // Added on 19 Jan 2024 for first equip active
          setActiveIndex(0);
          setCurrentActiveEquip(finalArray[0]?.id);
          setActiveEquip(finalArray[0]);
        }

        setloading(false);
      } else if (
        OfficeTrailerData["status"] == false &&
        OfficeTrailerData["slug"] == "Session Expired"
      ) {
        // Handle session expiration scenario
        dispatch(setSessionExpire(true));
        setloading(false);
        return false;
      } else {
        // Handle other error scenarios
        dispatch(setalertMessage(OfficeTrailerData["message"]));
        dispatch(setalertMessagetype("error"));
        setloading(false);
        return false;
      }
    } catch (err) {
      // Handle errors that occur during the API request
      console.log(err);
      setloading(false);
    }
  };

  /**
   * Function: HandleRentalYardPagination
   * Description:
   * Handles pagination for the rental yard equipment, updating the displayed equipment based on
   * the total available equipment, the current page length, and the pagination page size.
   * Sets loading state during the process.
   */
  const HandleRentalYardPagination = () => {
    setloading(true);

    // Calculate the total number of available equipment and current page length
    let total = Number(Actionequip.length);
    let pagerlen = Number(AllEquips.length);

    // Calculate the new length of the page after pagination
    let new_len = Number(pagerlen) + Number(paginationPage);
    setNewPageLen(new_len);

    // Update the displayed equipment based on the new length
    if (new_len > total) {
      setAllEquips(Actionequip.slice(0, total));
    } else {
      setAllEquips(Actionequip.slice(0, new_len));
    }

    // Reset loading state after completing the pagination process
    setloading(false);
  };

  // If no Equipments are listed for rent
  const HandleNoShare = () => {
    dispatch(setalertMessage("No Equipment Found."));
    dispatch(setalertMessagetype("error"));
  };

  /**
   * useEffect Hook:
   * Responsible for initializing user authentication status and fetching user profile data
   * when the component mounts or when the 'ReloadTab' dependency changes.
   */
  useEffect(() => {
    let auth_user = localStorage.getItem("token-info");
    if (auth_user != "undefined") {
      auth_user = JSON.parse(auth_user);
      if (auth_user != null && auth_user) {
        dispatch(setLoginStatus(true));
        dispatch(setaccessToken(auth_user["auth_token"]));
        getuserprofile(auth_user["user_id"], auth_user["auth_token"]);

        // base_url_link = base_url + '/share-rental-yard/rent/' + auth_user['user_id']
        base_url_link = `${base_url}/share-rental-yard/rent/${auth_user["user_id"]}/${auth_user["user_id"]}`;
      }
    }
  }, [ReloadTab]);
  // }, [ReloadTab , AuthUserID]);

  useEffect(() => {
    window.scrollTo(0, 0);
    // console.log('reload in rent', ReloadTab);
    // if (AuthProfileData && AuthProfileData != null && ReloadTab) {
    //   MyEquip(AuthProfileData['user_id'], AuthProfileData['auth_token'])
    //   base_url_link = base_url + '/share-rental-yard/rent/'+AuthProfileData['user_id']
    // }
  }, [ReloadTab, AuthProfileData]);

  // if(isLoad){
  //   let auth_user = localStorage.getItem('token-info');
  //   if (auth_user != 'undefined') {
  //     auth_user = JSON.parse(auth_user);
  //     if (auth_user != null && auth_user) {
  //       dispatch(setLoginStatus(true));
  //       dispatch(setaccessToken(auth_user['auth_token']))
  //       getuserprofile(auth_user['user_id'], auth_user['auth_token'])
  //     }
  //   }
  // }

  return (
    <>
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <Box
              sx={{
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="h5" sx={{ textAlign: "center" }}>
                Loading Your Rental Yard...
              </Typography>
            </Box>
            {/* <CircularProgress color="inherit" /> */}
          </Backdrop>
        </div>
      )}

      {/* share  rental yard if equipments available */}
      <Box className="rentyrd-heading">
        <Typography>My Rental Yard</Typography>
        {Actionequip.length > 0 ? (
          <Link
            className="rentyrd-share-link"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setShareYardPopup(true);
            }}
          >
            Share Yard
          </Link>
        ) : (
          <></>
          // <Link
          //   className="rentyrd-share-link"
          //   style={{ cursor: "pointer" }}
          //   onClick={HandleNoShare}
          // >
          //   Share Yard
          // </Link>
        )}
      </Box>

      <Box className="rentyrd-grid">
        <Box className="rentyrd-grid-item rentyrd-left">
          {/* loop to display rental equipments list */}
          {Actionequip.length > 0 ? (
            <>
              {AllEquips.map((equipment, indx) => (
                <Box key={equipment.id}>
                  <Box
                    className={
                      CurrentActiveEquip == equipment.id
                        ? "rentyrd-card rental-box-active"
                        : "rentyrd-card"
                    }
                    onClick={() => {
                      console.log("equipment.id:", equipment.id);
                      setActiveEquip(equipment);
                      setCurrentActiveEquip(equipment.id);
                      setActiveIndex(indx);
                    }}
                  >
                    <Box
                      // className="rentyrd-card-grid"
                      sx={{
                        display: "flex",
                        flexDirection: {
                          xs: "column",
                          sm: "row",
                          md: "row",
                          lg: "row",
                          xl: "row",
                        },
                        alignItems: "center",
                        gap: "1rem",
                      }}
                    >
                      <Box
                        // className="rentyrd-card-item-left"
                        sx={{
                          width: {
                            xs: "100%",
                            sm: "auto",
                            md: "auto",
                            lg: "auto",
                            xl: "auto",
                          },
                        }}
                      >
                        <Box
                          // className="rentyrd-card-img"
                          sx={{ position: "relative", width: "100%" }}
                        >
                          <Box
                            component={"img"}
                            src={equipment["thumbnail"]}
                            alt=""
                            sx={{
                              width: {
                                xs: "100%",
                                sm: "200px",
                                md: "250px",
                                lg: "180px",
                                xl: "200px",
                              },
                              height: {
                                xs: "195px",
                                sm: "150px",
                                md: "187px",
                                lg: "135px",
                                xl: "150px",
                              },
                              objectFit: "cover",
                            }}
                          />
                          {/* <img className='eqppmnt-img' src={equipment["thumbnail"]} alt="" /> */}

                          {equipment.order_request_status != 0 &&
                          ((equipment.order_status != null &&
                            equipment.order_status == "returned") ||
                            equipment.order_status == "pickup" ||
                            equipment.order_status == "payment_complete") ? (
                            <Typography
                              sx={{
                                border: "2px solid #000",
                                width: "auto",
                                height: "auto",
                                position: "absolute",
                                color: "#000",
                                background: "#faa61a",
                                padding: "0px 8px",
                                borderRadius: "12px",
                                top: "-14px",
                                right: "-10px",
                                textTransform: "uppercase",
                                fontFamily: "Montserrat !important",
                                fontWeight: "600",
                              }}
                            >
                              ACTIVE
                            </Typography>
                          ) : (
                            ""
                          )}
                          {Number(equipment.status) !== 1 && (
                            <Typography
                              sx={{
                                border: "2px solid #000",
                                width: "auto",
                                height: "auto",
                                position: "absolute",
                                color: "red",
                                background: "#000",
                                padding: "0px 8px",
                                borderRadius: "12px",
                                top: "-14px",
                                left: "-10px",
                                textTransform: "capitalize",
                                fontFamily: "Montserrat !important",
                                fontWeight: "600",
                                fontSize: "14px",
                              }}
                            >
                              Blocked
                            </Typography>
                          )}
                        </Box>
                      </Box>
                      <Box
                        // className="rentyrd-card-item-right"
                        sx={{ width: "100%" }}
                      >
                        <Box
                        // className="rentyrd-right"
                        >
                          <Typography
                            // className="vehicle-model"
                            title={equipment.make}
                            sx={{
                              display: "-webkit-box",
                              overflow: "hidden",
                              WebkitBoxOrient: "vertical",
                              WebkitLineClamp: 1,
                              color: "#fff",
                              fontSize: {
                                xs: "18px",
                                sm: "19.5px",
                                md: "21px",
                                lg: "23px",
                                xl: "25px",
                              },
                              fontFamily: "GothamBold !important",
                            }}
                          >
                            {equipment.make ? equipment.make : "NA"}

                            {/* by GD 24 Jan due to design error */}
                            {/* {equipment.make ? (equipment.make.length > 25 ? equipment.make.substring(0, 25) + "..." : equipment.make)
                            : "NA"} */}
                          </Typography>
                          <Typography
                            // className="vehicle-type"
                            title={equipment.model}
                            sx={{
                              display: "-webkit-box",
                              overflow: "hidden",
                              WebkitBoxOrient: "vertical",
                              WebkitLineClamp: 1,
                              color: "#faa61a",
                              fontSize: {
                                xs: "14px",
                                sm: "15px",
                                md: "17px",
                                lg: "18.5px",
                                xl: "20px",
                              },
                            }}
                          >
                            {equipment.model ? equipment.model : "NA"}
                          </Typography>
                          <Box
                            // className="rentyrd-address"
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              gap: "0.5rem",
                              padding: "0.5rem 0",
                              borderBottom: "2px solid gray",
                            }}
                          >
                            <Typography
                              title={equipment.city ? equipment.city : ""}
                              sx={{
                                color: "#fff",
                                fontSize: {
                                  xs: "12px",
                                  sm: "13px",
                                  md: "13px",
                                  lg: "14px",
                                  xl: "14px",
                                },
                              }}
                            >
                              {/* {equipment.city ? equipment.city : "NA"} */}
                              {equipment.city
                                ? equipment.city.length > 20
                                  ? equipment.city.substring(0, 20) + "... "
                                  : equipment.city
                                : "NA"}

                              {equipment.state
                                ? equipment.state.length > 20
                                  ? ", " +
                                    equipment.state.substring(0, 20) +
                                    "..."
                                  : ", " + equipment.state
                                : ""}
                            </Typography>
                            {equipment.owner_pickup_return == "1" ? (
                              <Typography
                                sx={{
                                  color: "#fff",
                                  fontSize: {
                                    xs: "12px",
                                    sm: "13px",
                                    md: "13px",
                                    lg: "14px",
                                    xl: "14px",
                                  },
                                }}
                              >
                                Delivery Available
                              </Typography>
                            ) : (
                              <Typography
                                sx={{
                                  color: "#fff",
                                  fontSize: {
                                    xs: "12px",
                                    sm: "13px",
                                    md: "13px",
                                    lg: "14px",
                                    xl: "14px",
                                  },
                                }}
                              ></Typography>
                            )}
                          </Box>
                          <Box
                            // className="rentyrd-rates"
                            sx={{
                              display: "grid",
                              gridTemplateColumns: "repeat(3, 1fr)",
                              padding: "0.5rem 0",
                              gap: "0",
                              width: "100%",
                              overflowX: "auto",
                              "&::-webkit-scrollbar": {
                                width: "8px",
                              },
                              "&::-webkit-scrollbar-thumb": {
                                backgroundColor: "#2d2d2d",
                                borderRadius: "4px",
                              },
                              "&::-webkit-scrollbar-thumb:hover": {
                                backgroundColor: "#2d2d2d",
                              },
                              "&::-webkit-scrollbar-track": {
                                backgroundColor: "#2d2d2d",
                                borderRadius: "4px",
                              },
                              scrollbarWidth: "thin",
                              scrollbarColor: "#149247 #2d2d2d",
                            }}
                          >
                            <Box
                              // className="rentyrd-rates-item"
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "#fff",
                                  fontSize: {
                                    xs: "14px",
                                    sm: "16px",
                                    md: "18px",
                                    lg: "20px",
                                    xl: "22px",
                                  },
                                  fontFamily: "GothamBold !important",
                                }}
                              >
                                $
                                <CurrentFormat
                                  Currency={equipment.price_month}
                                  trunc={true}
                                />
                                <Typography
                                  component={"span"}
                                  // className="rentyrd-rates-span"
                                  sx={{
                                    color: "#faa61a",
                                    fontFamily: "Gotham !important",
                                    fontSize: {
                                      xs: "10px",
                                      sm: "11px",
                                      md: "12px",
                                      lg: "13px",
                                      xl: "14px",
                                    },
                                  }}
                                >
                                  /m
                                </Typography>
                              </Typography>
                            </Box>
                            <Box
                              // className="rentyrd-rates-item"
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                borderLeft: "2px solid gray",
                                borderRight: "2px solid gray",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "#fff",
                                  fontSize: {
                                    xs: "14px",
                                    sm: "16px",
                                    md: "18px",
                                    lg: "20px",
                                    xl: "22px",
                                  },
                                  fontFamily: "GothamBold !important",
                                }}
                              >
                                $
                                <CurrentFormat
                                  Currency={equipment.price_per_week}
                                  trunc={true}
                                />
                                <Typography
                                  component={"span"}
                                  // className="rentyrd-rates-span"
                                  sx={{
                                    color: "#faa61a",
                                    fontFamily: "Gotham !important",
                                    fontSize: {
                                      xs: "10px",
                                      sm: "11px",
                                      md: "12px",
                                      lg: "13px",
                                      xl: "14px",
                                    },
                                  }}
                                >
                                  /w
                                </Typography>
                              </Typography>
                            </Box>
                            <Box
                              // className="rentyrd-rates-item"
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "#fff",
                                  fontSize: {
                                    xs: "14px",
                                    sm: "16px",
                                    md: "18px",
                                    lg: "20px",
                                    xl: "22px",
                                  },
                                  fontFamily: "GothamBold !important",
                                }}
                              >
                                $
                                <CurrentFormat
                                  Currency={equipment.price_day}
                                  trunc={true}
                                />
                                <Typography
                                  component={"span"}
                                  // className="rentyrd-rates-span"
                                  sx={{
                                    color: "#faa61a",
                                    fontFamily: "Gotham !important",
                                    fontSize: {
                                      xs: "10px",
                                      sm: "11px",
                                      md: "12px",
                                      lg: "13px",
                                      xl: "14px",
                                    },
                                  }}
                                >
                                  /d
                                </Typography>
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Typography
                      // className="card-uid"
                      sx={{
                        color: "#fff",
                        fontSize: {
                          xs: "14px",
                          sm: "14px",
                          md: "15px",
                          lg: "16.5px",
                          xl: "18px",
                        },
                      }}
                    >
                      EID{equipment.id}
                    </Typography>
                  </Box>

                  {AllEquips.length != indx + 1 && (
                    <Divider
                      sx={{ border: "1px solid #757575", mt: 1.5, mb: 1.5 }}
                    />
                    // <hr className="gray-line" />
                  )}
                </Box>
              ))}

              {Actionequip.length > AllEquips.length &&
                paginationPage < Actionequip.length && (
                  <Box className="pagination-section">
                    <Box
                      className="btn-load-more"
                      onClick={HandleRentalYardPagination}
                    >
                      {"See More"}
                      <img
                        src={gemArrow}
                        alt="GoEquipMe"
                        className="gem-arrow arrow-load-more"
                      />
                    </Box>
                  </Box>
                )}
            </>
          ) : (
            <>
              <Typography className="empty-rent">
                <span>You do not have any equipment listed yet.</span>
                <span>
                  To list your first item&nbsp;
                  <Link
                    className="rentyrd-menu-link"
                    // href="/listequipment"
                    onClick={(event) => {
                      if (!AuthUserID) return LoginPopup();
                      // window.location.href = "/listequipment";
                      handleListEquipment(event);
                    }}
                  >
                    click here
                  </Link>
                </span>
              </Typography>
            </>
          )}
        </Box>

        {/* for display selected equipment details */}
        {!loadingdetail && ActiveEquip != null && (
          // by GD added display conditions and Box wrap due to error when last equipment in list is deleted
          <Box
            className="rental-detail-div"
            sx={{ display: Actionequip.length > 0 ? "block" : "none" }}
          >
            <NewRentalDetail
              ActiveEquip={ActiveEquip}
              Successres={(e) => {
                if (e == "success") {
                  setUpdateCallback(e);
                  getuserprofile(AuthUserID, AuthToken);
                }
              }}
              ChatClick={ChatClick}
              UpdateTab={(e) => {
                console.log("office trailer page", e);
                UpdateTab(e);
              }}
              reloadTabUpdate={reloadTabUpdate}
              setActiveStep={setActiveStep}
            />
          </Box>
        )}
      </Box>

      {/* ShareYard Popup */}
      <Modal
        keepMounted
        open={ShareYardPopup}
        onClose={() => setShareYardPopup(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popups"
          sx={{
            ...loginstyle,
            // width: matchesMobileX ? 'calc(100vw - 100px)' : 800,
            padding: "0px",
          }}
        >
          <IconButton
            className="cross-btn"
            onClick={() => setShareYardPopup(false)}
          >
            <CloseIcon />
          </IconButton>

          <Box className="login__main">
            <Box
              className="login__main-div edit-eqp"
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box className="login__main-div__form edit-eqp-inner pupup-scroll">
                <Typography variant="h5" style={{ color: "#faa61a" }}>
                  Share Yard
                </Typography>

                <Box>
                  <FacebookShareButton url={base_url_link}>
                    <FacebookIcon size={32} round style={{ padding: "8px" }} />
                    <FacebookMessengerIcon
                      size={32}
                      round
                      style={{ padding: "8px" }}
                    />
                  </FacebookShareButton>

                  <TwitterShareButton url={base_url_link}>
                    <TwitterIcon size={32} round style={{ padding: "8px" }} />
                  </TwitterShareButton>

                  <TelegramShareButton url={base_url_link}>
                    <TelegramIcon size={32} round style={{ padding: "8px" }} />
                  </TelegramShareButton>

                  <WhatsappShareButton url={base_url_link}>
                    <WhatsappIcon size={32} round style={{ padding: "8px" }} />
                  </WhatsappShareButton>

                  <EmailShareButton url={base_url_link}>
                    <EmailIcon size={32} round style={{ padding: "8px" }} />
                  </EmailShareButton>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>

      {/* NewEquipAdded popup after completion */}
      <Modal
        keepMounted
        open={NewEquipAdded}
        onClose={() => setNewEquipAdded(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popups"
          sx={{
            ...loginstyle,
            width: matchesMobileX ? "calc(100vw - 100px)" : 800,
            padding: "0px",
          }}
        >
          <>
            <Box className="login__main">
              <Box className="login-main-inner">
                <Box className="login-fields-div">
                  <Box className="after-msg">
                    <Box className="after-msg-inner">
                      <Typography
                        variant="h4"
                        className="welcome-heading"
                        sx={{ textAlign: "center", color: "#fff" }}
                      >
                        Your item is listed
                      </Typography>

                      <Typography className="first-para">
                        Select your item to edit or enhance your listing with:
                      </Typography>

                      <Typography className="second-para">
                        Transport Pricing
                      </Typography>
                      <Typography className="second-para">
                        Transport Preference
                      </Typography>
                      <Typography className="second-para">
                        Calendar Availability
                      </Typography>
                      <Typography className="second-para">
                        Service & Maintenance
                      </Typography>
                      <Typography className="second-para">
                        Rental Instrctions
                      </Typography>
                      <Typography className="second-para">
                        Equipment Location
                      </Typography>
                      <Typography className="second-para">
                        Equipment Details
                      </Typography>
                      <Typography className="second-para">
                        Additional Photos
                      </Typography>

                      <Box className="close">
                        <Box
                          className="close-content"
                          onClick={() => setNewEquipAdded(false)}
                        >
                          <img src={cross} alt="" />
                          <Typography>Close</Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        </Box>
      </Modal>
    </>
  );
};
export default MyOfficeTrailer;
