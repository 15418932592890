import { Backdrop, Box, Button, CircularProgress, FormControl, IconButton, Modal, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close'

//photo tips
import Suggest1 from "assets/images/suggest1.png";
import Suggest2 from "assets/images/suggest2.png";
import Suggest3 from "assets/images/suggest3.png";
import Suggest4 from "assets/images/suggest4.png";
import Check from 'assets/images/check.png'


import { useState } from "react";
import { EquipmentAPIs } from "../../service/api-service";
import { IApiResponseRawData, IStoreValue } from 'utils/types';
import { useDispatch, useSelector } from 'react-redux';
import { setModalOpen, setLoginStatus, setaccessToken, setSessionExpire, setalertMessagetype, setalertMessage } from 'redux/redux-slice';

const loginstyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    p: 4,
};


const EditServicePhotosModal = ({ servicePhotosModalOpen, setServicePhotosModalOpen, serviceData, myServices }) => {
    const dispatch = useDispatch();
    const AuthToken = useSelector((state: IStoreValue) => state.equipmentReducer.accessToken);

    const [loading, setLoading] = useState(false);

    const [Thumbimage, setThumbimage] = useState(0);
    const [imagearr, setimagearr] = useState([]);
    const [uploadedpath, setUploadedpath] = useState(serviceData.service_photos ? serviceData.service_photos : []);
    const [file, setFile] = useState(serviceData.service_photos ? serviceData.service_photos : []);

    console.log("Uploadedpath: ", uploadedpath);
    console.log("file: ", file);
    console.log("service_id: ", serviceData.service_id);

    // fxn to remove an image
    const removeImage = (fileName, i) => {
        // Create a copy of the file and imagearr arrays.
        var myArray = [...file];
        var _myarray = [...imagearr];

        console.log("fileName: ", fileName);
        // by GD for remove image from upload array
        // setUploadedpath(uploadedpath.filter((item) => item != fileName));
        setFile(uploadedpath.filter((item) => item != fileName));
        // end upload image
        // Find the index of the file in the array.
        const index = myArray.indexOf(fileName);

        /******************/
        // Filter out local images from myArray.
        let new_A = []
        if (myArray.length > 0) {
            myArray.map((filename, i) => {
                if (filename.indexOf('blob:http') > -1) {
                } else {
                    new_A.push(filename);
                }
            });
        }
        // console.log('upload',_myarray);
        // console.log('File',myArray);

        // Handle removal based on the adjusted index.
        if (i >= new_A.length) {
            var new_indx = i - new_A.length;

            _myarray.splice(new_indx, 1);
            myArray.splice(i, 1);
        } else {
            myArray.splice(i, 1);
        }

        /*************/

        // Update state with the modified arrays.
        if (index > -1) {
            setThumbimage(i == Thumbimage ? 0 : Thumbimage > i ? 0 : 0)
            // _myarray.splice(index, 1);
            //  myArray.splice(index, 1);
        }
        setFile(myArray)
        setimagearr(_myarray)

    }

    // Select multiple images
    const handleImageChange = function (e: React.ChangeEvent<HTMLInputElement>) {
        const fileList = e.target.files;

        // Check if files are selected.
        if (fileList.length > 0) {
            setLoading(true)
            var result = Object.entries(fileList);

            // Check if the total number of images exceeds the limit.
            if (result.length > 6 || (file.length + result.length) > 6) {
                setLoading(false)
                dispatch(setalertMessage('you can upload only 6 images.'));
                dispatch(setalertMessagetype('error'));
                return false
            } else {
                // if (file.length > 0) {
                //   var arr = [...file, ...result]
                //   console.log('object=======>',arr);
                //   setFile(arr)
                // } else {
                //   console.log('=======>>>RESULT', result)
                //   console.log('objectPURANA++=======>',file);
                if (imagearr.length > 0) {
                    var arr = [...imagearr, ...result]
                    setimagearr(arr)
                } else {
                    setimagearr(result);
                }
                // }

                let upload_files = [...file];

                // Check if the file type is an image.
                for (let index = 0; index < result.length; index++) {
                    if (e.target.files[index].type == 'image/jpg' || e.target.files[index].type == 'image/jpeg' || e.target.files[index].type == 'image/png') {
                        var fileLoaded = URL.createObjectURL(e.target.files[index]);
                        upload_files.push(fileLoaded);
                    }
                }

                // Update state with the modified arrays.
                setFile(upload_files);
                setLoading(false)
            }
        }
    };

    // upload the files
    const uploadfiles = async () => {
        if (file.length > 0) {
            setLoading(true)
            let upload_files_path = [];

            // Filter out blob URLs from the file array
            if (file.length > 0) {
                file.map((filename, i) => {
                    if (filename.indexOf('blob:http') > -1) {
                    } else {
                        upload_files_path.push(filename);
                    }
                });
            }

            console.log('imagearr:', imagearr);
            if (imagearr.length > 0) {

                SaveFile(0, upload_files_path, imagearr.length, async (success) => {
                    console.log("Completed....... :", upload_files_path);
                    // uploadedpath
                    let _array = [...upload_files_path]

                    // Set the primary image as the thumbnail
                    var primaryImage = _array[Thumbimage];
                    _array.splice(Thumbimage, 1);
                    let equipImages = [primaryImage, ..._array]

                    console.log("equipImages :", equipImages);

                    let body = {
                        image: equipImages.toString(),
                        service_id: serviceData.id.toString()
                    }

                    console.log("data in update photos:", body);

                    const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
                    const updateServicePhotos = await EquipmentAPIs.updateServicePhotos(body, headers);
                    console.log("updateService: ", updateServicePhotos);
                    if (updateServicePhotos["type"] == "RXSUCCESS") {
                        console.log("service photos updated successfully");
                        dispatch(setalertMessage("Service photos updated successfully."));
                        dispatch(setalertMessagetype("success"));
                        setServicePhotosModalOpen(false);
                        // console.log('body', body);
                        setLoading(false);
                        setimagearr([]);
                        myServices();
                    } else {
                        // Display an error message if deletion fails
                        dispatch(setalertMessage("error while updating service photos."));
                        dispatch(setalertMessagetype("error"));
                        setLoading(false);
                        setServicePhotosModalOpen(false);
                        setimagearr([]);

                    }
                    // Reset Equip Gallery Popup
                    // setServicePhotosModalOpen(false);
                    // console.log('body', body);
                    // setLoading(false);
                    //}
                });
            } else {
                // No additional images, use the selected files
                var primaryImage = file[Thumbimage];
                file.splice(Thumbimage, 1);
                let equipImages = [primaryImage, ...file];
                console.log("files of default: ", file);

                let body = {
                    image: equipImages.toString(),
                    service_id: serviceData.id.toString()
                }

                console.log("body in else case no image selected: ", body);

                const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
                const updateServicePhotos = await EquipmentAPIs.updateServicePhotos(body, headers);
                console.log("updateService: ", updateServicePhotos);
                if (updateServicePhotos["type"] == "RXSUCCESS") {
                    console.log("service photos updated successfully");
                    dispatch(setalertMessage("service photos updated successfully."));
                    dispatch(setalertMessagetype("success"));
                    setServicePhotosModalOpen(false)
                    // console.log('body', body);
                    setLoading(false);
                    setimagearr([]);
                    myServices();

                } else {
                    // Display an error message if deletion fails
                    dispatch(setalertMessage("error while updating service photos."));
                    dispatch(setalertMessagetype("error"));
                    setLoading(false);
                    setServicePhotosModalOpen(false)
                }
                // Reset Equip Gallery Popup
                setServicePhotosModalOpen(false)
                setLoading(false)
            }
        }

        else {
            dispatch(setalertMessage('Please select image first for upload.'));
            dispatch(setalertMessagetype('error'));
            return false
        }
    }

    // save Files
    const SaveFile = async (i = 0, upload_files_path, total, completion) => {

        let imgObg = imagearr[i];
        let file_obj = imgObg[1];

        // Check if a file is present.
        if (file_obj) {
            try {
                const formData = new FormData();
                formData.append('image', file_obj);
                formData.append('name', file_obj.name);

                let access_token = AuthToken;
                const headers = {
                    "Authorization": `${access_token}`,
                    "Content-Type": "multipart/form-data",
                    Accept: "application/json",
                    request_from: "Web"
                };

                // Upload the image to the server.
                const upload_Equip_file: IApiResponseRawData = await EquipmentAPIs.uploadEquipImage(
                    formData,
                    headers
                );

                if (upload_Equip_file['status'] == 200) {
                    var uploaded_path = upload_Equip_file['data']['image'];
                    upload_files_path.push(uploaded_path);
                    let newImages = [...uploadedpath, uploaded_path]
                    setUploadedpath(newImages);
                    // Check if all images are uploaded.
                    if (total - 1 == i) {
                        completion(true)
                        return
                    } else {
                        // Recursively call SaveFile for the next image.
                        SaveFile(i + 1, upload_files_path, total, completion)
                    }
                    //return;
                } else if (upload_Equip_file['status'] == false) {
                    dispatch(setalertMessage(upload_Equip_file['slug']));
                    dispatch(setalertMessagetype('error'));
                    setLoading(false)
                    return false
                } else {
                    dispatch(setalertMessage(upload_Equip_file['message']));
                    dispatch(setalertMessagetype('error'));
                    //  return 'false'
                }
            } catch (err) {
                dispatch(setalertMessage(err));
                dispatch(setalertMessagetype('error'));
                setLoading(false)
                // return 'false'
            }
        }

    }

    return (
      <Modal
        keepMounted
        open={servicePhotosModalOpen}
        onClose={() => setServicePhotosModalOpen(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          className="download-popups"
          sx={{
            ...loginstyle,
            padding: "0px",
          }}
        >
          <IconButton
            className="cross-btn"
            onClick={() => setServicePhotosModalOpen(false)}
          >
            <CloseIcon />
          </IconButton>

          <Box className="login__main">
            <Box
              className="login__main-div edit-eqp"
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                className="login__main-div__form popup-cmmn popup-white edit-eqp-inner"
                style={{ border: "5px solid #faa61a", borderRadius: "10px" }}
              >
                <Typography variant="h5" style={{ color: "#faa61a" }}>
                  Edit EQUIPMENT PHOTOS
                </Typography>
                <Typography variant="h6">Let's show that you've got</Typography>

                <Box>
                  {loading && (
                    <Box>
                      <Backdrop
                        sx={{
                          color: "#000",
                          zIndex: (theme) => theme.zIndex.drawer - 1,
                        }}
                        open={loading}
                      >
                        <CircularProgress color="inherit" />
                      </Backdrop>
                    </Box>
                  )}

                  <Box className="img-box">
                    <Box className="img-box-grid">
                      {file.map((image, i) => (
                        <Box
                          className="img-box-item"
                          onClickCapture={() => {
                            // setThumbimage(i);
                          }}
                          key={i}
                        >
                          <img
                            className="main-img-box-item"
                            src={image}
                            alt=""
                          />
                          <IconButton
                            className="cross-btn-2"
                            onClickCapture={() => {
                              removeImage(image, i);
                            }}
                          >
                            <CloseIcon />
                          </IconButton>

                          {/* {Thumbimage == i && (
                                                    <img className="img-check" src={Check} alt="" />
                                                )} */}
                        </Box>
                      ))}
                    </Box>
                  </Box>

                  <FormControl fullWidth className="form-fields">
                    <Button
                      variant="contained"
                      component="label"
                      className="upload-btn"
                      sx={{ fontSize: "25px" }}
                    >
                      Choose
                      <input
                        id="photo"
                        name="photo"
                        type="file"
                        multiple={true}
                        onChange={handleImageChange}
                        hidden
                        accept="image/jpg, image/jpeg, image/png"
                      />
                    </Button>
                  </FormControl>
                  <Typography
                    variant="h6"
                    component="h6"
                    className="service-item__content-para"
                    sx={{ fontWeight: "bold" }}
                  >
                    PHOTO TIPS
                  </Typography>
                  <hr className="yellow-line" />
                  <Box className="photo-tips-main">
                    <Box className="photo-tips-main-item">
                      <Typography
                        variant="h6"
                        component="h6"
                        // className="service-item__content-para"
                        // sx={{ fontWeight: "bold", color: "#fff !important" }}
                        sx={{
                          fontSize: { xs: "16px", sm: "18px", md: "20px" },
                          color: "#fff",
                        }}
                      >
                        Horizontal Orientation
                      </Typography>
                      <img src={Suggest1} className="photo-tips" alt="" />
                    </Box>
                    <Box className="photo-tips-main-item">
                      <Typography
                        variant="h6"
                        component="h6"
                        className="service-item__content-para"
                        sx={{
                          fontSize: { xs: "16px", sm: "18px", md: "20px" },
                          color: "#fff",
                        }}
                      >
                        Well Lit
                      </Typography>
                      <img src={Suggest2} className="photo-tips" alt="" />
                    </Box>
                    <Box className="photo-tips-main-item">
                      <Typography
                        variant="h6"
                        component="h6"
                        className="service-item__content-para"
                        sx={{
                          fontSize: { xs: "16px", sm: "18px", md: "20px" },
                          color: "#fff",
                        }}
                      >
                        No Graphics/Text
                      </Typography>
                      <img src={Suggest3} className="photo-tips" alt="" />
                    </Box>
                    <Box className="photo-tips-main-item">
                      <Typography
                        variant="h6"
                        component="h6"
                        className="service-item__content-para"
                        sx={{
                          fontSize: { xs: "16px", sm: "18px", md: "20px" },
                          color:'#fff',
                        }}
                      >
                        Composition/Angle
                      </Typography>
                      <img src={Suggest4} className="photo-tips" alt="" />
                    </Box>
                  </Box>
                  <Button
                    className="signin-btn"
                    type="button"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, backgroundColor: "#139246" }}
                    onClickCapture={() => {
                      uploadfiles();
                    }}
                  >
                    Update
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    );
}

export default EditServicePhotosModal;