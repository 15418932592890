import {
  Modal,
  Box,
  IconButton,
  Typography,
  TextField,
  Button,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import checkIcon from "assets/images/check.png";
import crossIcon from "assets/images/cross.png";
import { setalertMessage, setalertMessagetype } from "redux/redux-slice";
import { useDispatch } from "react-redux";

const textfieldStyles = {
  backgroundColor: "#fff",
  border: "2px solid #faa61a",
  borderRadius: "10px",
  boxSizing: "border-box",
  "& .MuiInputBase-root": {
    fontFamily: "Gotham !important",
    color: "#000 !important",
    boxSizing: "border-box",
  },
  "& .MuiInputLabel-root": {
    fontFamily: "Gotham !important",
    color: "gray !important",
    boxSizing: "border-box",
  },
  "& .MuiInputBase-input": {
    fontFamily: "Gotham !important",
    color: "#000 !important",
  },
  "& .MuiInputBase-input::placeholder": {
    fontFamily: "Gotham !important",
    color: "#b7b7b7 !important",
    fontSize: "14px",
  },
  "& .MuiInput-underline:after": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiInput-underline:before": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiFilledInput-underline:after": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiFilledInput-underline:before": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
};

const buttonStyles = {
  width: "100%",
  backgroundColor: "#01682c",
  color: "#fff",
  fontFamily: "Montserrat !important",
  fontWeight: "700",
  borderRadius: "10px",
  fontSize: "clamp(1em, 3vw, 1.2em)",
  "&:hover": {
    background: "#025725",
  },
};

const AddressPopup = ({
  dialogOpen,
  handleCloseDialog,
  zipLoading,
  isValidZipcode,
  zipCode,
  handleZipChange,
  fullAddress,
  onConfirmSaveAddress,
  cityState,
  setCityState,
}) => {
  const dispatch = useDispatch();

  const handleSaveAddress = () => {
    const address = cityState?.address?.trim();
    if (!address) {
      dispatch(
        setalertMessage(
          // "Please fill Flat, House No., Building, Company, Appartment name to continue!"
          "Please fill address to continue!"
        )
      );
      dispatch(setalertMessagetype("error"));
      return false;
    }
    const city = cityState?.city?.trim();
    if (!city) {
      dispatch(setalertMessage("Please fill city to continue!"));
      dispatch(setalertMessagetype("error"));
      return false;
    }
    const state = cityState?.state?.trim();
    if (!state) {
      dispatch(setalertMessage("Please fill state to continue!"));
      dispatch(setalertMessagetype("error"));
      return false;
    }
    if (!zipCode || !isValidZipcode) {
      dispatch(
        setalertMessage("Please enter a valid postal code to continue!")
      );
      dispatch(setalertMessagetype("error"));
      return false;
    }
    // const addressLine = cityState?.addressLine?.trim();
    let completeAddress = address;
    const lowerAddress = completeAddress?.toLowerCase();
    if (!lowerAddress?.includes(city?.toLowerCase())) {
      completeAddress += `, ${cityState?.city}`;
    }
    if (!lowerAddress?.includes(state?.toLowerCase())) {
      completeAddress += `, ${cityState?.state}`;
    }
    // if (!lowerAddress?.includes(fullAddress?.country?.toLowerCase())) {
    //   completeAddress += `, ${fullAddress?.country}`;
    // }
    if (!lowerAddress?.includes(zipCode)) {
      completeAddress += `, ${zipCode}`;
    }
    onConfirmSaveAddress(completeAddress);
    handleCloseDialog();
  };
  const handleRemainingChanges = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    type: string
  ) => {
    const nameOfCity = event.target.value || "";
    if (type === "city") {
      setCityState((prev) => ({ ...prev, city: nameOfCity }));
    } else if (type === "state") {
      setCityState((prev) => ({ ...prev, state: nameOfCity }));
    }
  };

  // useEffect(() => {
  //   if (!cityState?.village?.trim()) {
  //     setCityState((prev) => ({
  //       ...prev,
  //       village: fullAddress?.formatted_address,
  //     }));
  //   }
  //   setCityState((prev) => ({
  //     ...prev,
  //     city: fullAddress.city,
  //     state: fullAddress.state,
  //   }));
  // }, [fullAddress]);

  return (
    <Modal keepMounted open={dialogOpen} onClose={handleCloseDialog}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "#2e2e2e",
          border: "5px solid #faa61a",
          borderRadius: "10px",
          outline: "none",
          width: { xs: "90%", sm: "90%", md: "500px" },
        }}
      >
        <IconButton
          sx={{
            position: "absolute",
            top: "-20px",
            right: "-20px",
            bgcolor: "#faa61a",
            color: "#000",
            "&:hover": {
              bgcolor: "#faa61a",
            },
          }}
          onClick={handleCloseDialog}
        >
          <CloseIcon />
        </IconButton>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            p: 3,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "flex-start" }}>
            <Typography
              variant="h5"
              sx={{
                color: "#faa61a",
                fontFamily: "GothamBold !important",
              }}
              gutterBottom
            >
              Fill Shipping Address
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" gap={2}>
            <TextField
              label={
                <Box
                  component={"span"}
                  sx={{ display: "inline-flex", alignItems: "center" }}
                >
                  {/* Flat, House No., Building, Company, Appartment */}
                  Address
                  <Box
                    component={"span"}
                    sx={{
                      color: "#faa61a !important",
                      fontSize: "22px",
                      marginLeft: "0",
                    }}
                  >
                    {" "}
                    *
                  </Box>
                </Box>
              }
              name="flat"
              value={cityState?.address}
              onChange={(event) => {
                setCityState((prev) => ({
                  ...prev,
                  address: event.target.value || "",
                }));
              }}
              // placeholder="e.g 826, TCS, Quba"
              fullWidth
              variant="filled"
              InputLabelProps={{ shrink: true }}
              sx={textfieldStyles}
            />
            <TextField
              // label="Area/Street/Sector/Village"
              label={
                <Box
                  component={"span"}
                  sx={{ display: "inline-flex", alignItems: "center" }}
                >
                  {/* Area, Street, Sector, Village */}
                  Address (Line 2)
                  {/* <Box
                    component={"span"}
                    sx={{
                      color: "#faa61a !important",
                      fontSize: "22px",
                      marginLeft: "0",
                    }}
                  >
                    {" "}
                    *
                  </Box> */}
                </Box>
              }
              name="area"
              value={cityState?.addressLine}
              onChange={(event) => {
                setCityState((prev) => ({
                  ...prev,
                  addressLine: event.target.value || "",
                }));
              }}
              // placeholder="e.g sector 59, Quba"
              fullWidth
              variant="filled"
              InputLabelProps={{ shrink: true }}
              sx={textfieldStyles}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <TextField
                label={
                  <Box
                    component={"span"}
                    sx={{ display: "inline-flex", alignItems: "center" }}
                  >
                    City
                    <Box
                      component={"span"}
                      sx={{
                        color: "#faa61a !important",
                        fontSize: "22px",
                        marginLeft: "0",
                      }}
                    >
                      {" "}
                      *
                    </Box>
                  </Box>
                }
                name="city"
                value={cityState?.city}
                onChange={(event) => handleRemainingChanges(event, "city")}
                // placeholder="e.g Eagle Mountain"
                fullWidth
                variant="filled"
                InputLabelProps={{ shrink: true }}
                sx={textfieldStyles}
                // InputProps={{
                //   readOnly: fullAddress?.city,
                // }}
                // disabled={fullAddress?.city}
              />
              <TextField
                label={
                  <Box
                    component={"span"}
                    sx={{ display: "inline-flex", alignItems: "center" }}
                  >
                    State
                    <Box
                      component={"span"}
                      sx={{
                        color: "#faa61a !important",
                        fontSize: "22px",
                        marginLeft: "0",
                      }}
                    >
                      {" "}
                      *
                    </Box>
                  </Box>
                }
                name="state"
                value={cityState?.state}
                onChange={(event) => handleRemainingChanges(event, "state")}
                // placeholder="e.g Utah"
                fullWidth
                variant="filled"
                InputLabelProps={{ shrink: true }}
                sx={textfieldStyles}
                // InputProps={{
                //   readOnly: fullAddress?.state,
                // }}
                // disabled={fullAddress?.state}
              />
            </Box>

            {/* <TextField
              label="Country"
              name="country"
              // value={fullAddress?.country}
              value={"United States"}
              placeholder="e.g United States"
              fullWidth
              variant="filled"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                readOnly: true,
              }}
              sx={textfieldStyles}
            /> */}
            <TextField
              // label="Pincode"
              label={
                <Box
                  component={"span"}
                  sx={{ display: "inline-flex", alignItems: "center" }}
                >
                  Postal Code
                  <Box
                    component={"span"}
                    sx={{
                      color: "#faa61a !important",
                      fontSize: "22px",
                      marginLeft: "0",
                    }}
                  >
                    {" "}
                    *
                  </Box>
                </Box>
              }
              name="pincode"
              value={zipCode}
              onChange={handleZipChange}
              // placeholder="e.g 84005"
              fullWidth
              variant="filled"
              InputLabelProps={{ shrink: true }}
              inputProps={{ maxLength: 6 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                      className="eye-icon"
                    >
                      {zipLoading ? (
                        <CircularProgress size={"25px"} color="warning" />
                      ) : isValidZipcode && zipCode ? (
                        <Box
                          component={"img"}
                          src={checkIcon}
                          sx={{ width: "25px", height: "auto" }}
                        />
                      ) : !isValidZipcode && zipCode ? (
                        <Box
                          component={"img"}
                          src={crossIcon}
                          sx={{ width: "25px", height: "auto" }}
                        />
                      ) : (
                        <></>
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={textfieldStyles}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <Button
              onClick={handleSaveAddress}
              variant="contained"
              color="primary"
              sx={buttonStyles}
            >
              Continue
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddressPopup;
