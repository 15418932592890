import { Box, Button, Divider, Typography } from "@mui/material";
import React from "react";
import NewGPSCard from "./NewGPSCard";
import dummyImg from "../../../assets/images/jcb.jpg";
import { Map } from "@mui/icons-material";
import SeeMoreBtn from "components/common/SeeMoreBtn";

const NewGps = ({
  equipments,
  handleAssignment,
  handleShareLocation,
  handleGetDirection,
  handleClick,
  currentActiveEquip,
  deviceStatusLabel,
  isLoactionDisable,
  HandlePagination,
}) => {
  return (
    <Box sx={{ mb: 4 }}>
      <Box sx={{ textAlign: "left", mb: 2 }}>
        <Typography
          sx={{
            color: "#faa61a",
            fontWeight: "700",
            fontFamily: "Montserrat !important",
            fontSize: { xs: "14px", md: "20px" },
            textTransform: "unset !important",
          }}
        >
          <Typography
            component={"span"}
            sx={{
              fontWeight: "700",
              fontFamily: "Montserrat !important",
              fontSize: { xs: "14px", md: "20px" },
              color: "#faa61a",
            }}
          >
            {equipments?.total}
          </Typography>{" "}
          {equipments?.title}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          mb: "2rem",
        }}
      >
        {equipments?.data?.map((equipment) => (
          <>
            <Box key={equipment.id}>
              <NewGPSCard
                image={equipment.thumbnail}
                price={equipment.price_day}
                priceUnit={"day"}
                title={equipment.make || "N/A"}
                subTitle={equipment.model || "N/A"}
                location={`${
                  equipment.city
                    ? equipment.city.length > 20
                      ? equipment.city.substring(0, 20) + "... "
                      : equipment.city
                    : "N/A"
                }

          ${
            equipment.state
              ? equipment.state.length > 20
                ? ", " + equipment.state.substring(0, 20) + "..."
                : ", " + equipment.state
              : "N/A"
          }`}
                serialNumber={equipment.device_imei || " N/A"}
                onUnassign={() => handleAssignment(equipment)}
                onShareLocation={() => handleShareLocation(equipment)}
                onGetDirections={() => handleGetDirection(equipment)}
                onClickHandle={(event: React.MouseEvent<HTMLAnchorElement>) => {
                  handleClick(event, equipment);
                }}
                deviceStatusLabel={deviceStatusLabel}
                isBorder={equipment.id === currentActiveEquip}
                isLoactionDisable={isLoactionDisable}
              />
            </Box>
            <Divider
              sx={{
                borderWidth: "1px",
                borderColor: "gray",
                borderStyle: "solid",
                width: "100%",
                p: "0",
                m: "0",
              }}
            />
          </>
        ))}
        {equipments?.total > equipments?.data?.length && (
          <SeeMoreBtn HandlePagination={HandlePagination} />
        )}
        {/* Map button */}
        {/* <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Button
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "0.5rem",
            padding: "8px 15px",
            borderRadius: "10px",
            backgroundColor: "#149247",
            "&:hover": {
              backgroundColor: "#0e7b3a",
            },
            color: "#fff",
            fontFamily: "Montserrat !important",
            fontWeight: "700",
            fontSize: {
              xs: "16px",
              md: "20px",
            },
          }}
        >
          <Map sx={{ fontSize: "24px" }} />
          Map
        </Button>
      </Box> */}
      </Box>
    </Box>
  );
};

export default NewGps;
