import { Box } from "@mui/material";
import React, { useState, memo } from "react";
import placeholderImage from "assets/images/placeholder.png";

// Define prop types for ImageWithPlaceholder
interface ImageWithPlaceholderProps {
  fileData: {
    id: number;
    file: string;
    file_type: string;
    thumbnail?: string;
  };
  openLightbox: (fileUrl: string, fileType: string, index: number) => void;
  index: number;
}

const ImageWithPlaceholder: React.FC<ImageWithPlaceholderProps> = memo(
  ({ openLightbox, fileData, index }) => {
    const [isLoaded, setIsLoaded] = useState(false);
    return (
      <>
        {fileData.file_type.startsWith("photo") ? (
          <>
            {/* Placeholder Image */}
            {!isLoaded && (
              <Box
                component={"img"}
                src={placeholderImage}
                alt="Placeholder"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "8px",
                }}
              />
            )}

            {/* Actual Image */}
            <Box
              component={"img"}
              src={fileData.file}
              alt={`Preview ${fileData.id}`}
              onLoad={() => setIsLoaded(true)}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "8px",
                cursor: "pointer",
                display: isLoaded ? "block" : "none", // Hide the image until fully loaded
              }}
              onClick={() =>
                openLightbox(fileData.file, fileData.file_type, index)
              }
            />
          </>
        ) : (
          <Box
            component={"video"}
            controls
            poster={fileData.thumbnail}
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: "8px",
              cursor: "pointer",
            }}
            onClick={() =>
              openLightbox(fileData.file, fileData.file_type, index)
            }
          />
        )}
      </>
    );
  },
  (prevProps, nextProps) =>
    prevProps.fileData.file === nextProps.fileData.file &&
    prevProps.fileData.file_type === nextProps.fileData.file_type &&
    prevProps.fileData.thumbnail === nextProps.fileData.thumbnail &&
    prevProps.index === nextProps.index &&
    prevProps.openLightbox === nextProps.openLightbox
);

export default ImageWithPlaceholder;
