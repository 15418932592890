import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setalertMessagetype, setalertMessage } from "redux/redux-slice";
import { IStoreValue } from "utils/types";
import { EquipmentAPIs } from "../../../service/api-service";
import {
  Modal,
  Box,
  IconButton,
  Backdrop,
  CircularProgress,
  Button,
  Typography,
  Dialog,
  DialogContent,
  RadioGroup,
  FormControlLabel,
  Radio,
  LinearProgress,
  Stack,
  Tabs,
  Tab,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import gemArrow from "assets/images/gem-arrow.png";
import cameraIcon from "assets/images/camera-icon.png";
import colors from "react-multi-date-picker/plugins/colors";
import { ArrowBack, ArrowForward, Download, Upload } from "@mui/icons-material";
import moment from "moment";
import momentTimeZone from "moment-timezone";
import { convertTimeZoneShortName } from "utils/commonFxns";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import ImageWithPlaceholder from "./ImageWithPlaceholder";

const radioStyles = {
  "& .MuiSvgIcon-root": {
    fontSize: "1.5rem",
    color: "#fff",
  },
  "& .MuiTypography-root": {
    fontFamily: "Gotham, sans-serif", // Explicitly target Typography within the label
  },
};
const radiolabelStyles = {
  color: "#fff",
  fontFamily: "Gotham !important", // Ensuring the Gotham font is applied
  "& .MuiTypography-root": {
    fontFamily: "Gotham, sans-serif", // Explicitly target Typography within the label
  },
};

interface ActiveFile {
  src: string;
  type: string;
}

interface MediaTypes {
  id: number;
  file: string;
  thumbnail: string;
  file_type: string;
  createdAt: string;
}

const limit = 20;

const FilesViewerPopup = ({
  isOpen,
  onClose,
  orderDetails,
  isPastRental = false,
}) => {
  const dispatch = useDispatch();
  const AuthToken = useSelector(
    (state: IStoreValue) => state.equipmentReducer.accessToken
  );
  const AuthUserId = useSelector(
    (state: IStoreValue) => state.equipmentReducer.Authuser
  );
  //   const [files, setFiles] = useState<File[]>([]);
  //   const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [downloadLoaer, setDownloadLoader] = useState<boolean>(false);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [currentFile, setCurrentFile] = useState<ActiveFile>({
    src: "",
    type: "photo",
  });
  const [pageNo, setPageNo] = useState<number>(2);
  const [loadMore, setLoadMore] = useState<boolean>(false);
  const [mediaFileData, setMediaFileData] = useState<MediaTypes[]>([]);
  const [filter, setFilter] = useState<string>("all");
  const [totalFiles, setTotalFiles] = useState<number>(0);
  const [uploadModalOpen, setUploadModalOpen] = useState<boolean>(false);
  const handleuploadModalClose = () => setUploadModalOpen(false);
  const handleuploadModalOpen = () => setUploadModalOpen(true);
  const [activeMediaIndex, setActiveMediaIndex] = useState<number>(0);
  //   const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //     const selectedFiles = event.target.files;
  //     if (selectedFiles) {
  //       const validFiles: File[] = [];
  //       const invalidFiles: string[] = [];
  //       Array.from(selectedFiles).forEach((file) => {
  //         if (
  //           (file.type.startsWith("image/") &&
  //             ["image/jpeg", "image/png", "image/jpg"].includes(file.type)) ||
  //           file.type === "video/mp4"
  //         ) {
  //           validFiles.push(file);
  //         } else {
  //           invalidFiles.push(file.name);
  //         }
  //       });
  //       if (invalidFiles.length > 0) {
  //         setError(`Invalid file types: ${invalidFiles.join(", ")}`);
  //       } else {
  //         setError(null);
  //         setFiles([...files, ...validFiles]);
  //       }
  //     }
  //   };

  // ACtive Tabs
  const [activeTab, setActiveTab] = useState(0);

  const getMediaFiles = async ({
    orderId,
    userId,
    mediaType = "all",
    page = 1,
  }) => {
    try {
      if (page > 1) {
        setLoadMore(true);
      } else {
        setLoading(true);
      }
      const params = {
        order_id: orderId,
        user_id: userId,
        media_type: mediaType,
        limit,
        page,
      };
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const response = await EquipmentAPIs.fetchMediaDetails(params, headers);
      if (response["type"] === "RXSUCCESS") {
        if (page > 1) {
          setMediaFileData((prev) => [...prev, ...response.data]);
        } else {
          setMediaFileData(response.data);
        }
        setTotalFiles(response.meta.total);
      } else {
        throw new Error(response["message"]);
      }
    } catch (error) {
      dispatch(setalertMessage(error["message"]));
      dispatch(setalertMessagetype("error"));
      console.log("getMediaFiles Error", error);
    } finally {
      setLoading(false);
      setLoadMore(false);
    }
  };

  const openLightbox = useCallback(
    (fileUrl: string, fileType: string, index: number) => {
      setCurrentFile({ src: fileUrl, type: fileType });
      setLightboxOpen(true);
      setActiveMediaIndex(index);
    },
    []
  );
  const closeLightbox = () => {
    setLightboxOpen(false);
    setCurrentFile(null);
  };

  const handleFetchMedia = ({
    openAction,
    mediaType,
    page = 1,
  }: {
    openAction: number;
    mediaType: string;
    page?: number;
  }): void => {
    let userId = AuthUserId;
    if (openAction === 1) {
      userId = orderDetails?.["renter_id"];
    } else if (openAction === 0) {
      userId = orderDetails?.["owner_id"];
    }
    getMediaFiles({
      orderId: orderDetails?.["order_id"],
      userId,
      mediaType: mediaType,
      page,
    });
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
    handleFetchMedia({ openAction: activeTab, mediaType: event.target.value });
  };
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    handleFetchMedia({ openAction: newValue, mediaType: filter });
  };

  const handlePagination = (page: number) => {
    handleFetchMedia({ openAction: activeTab, mediaType: filter, page });
  };

  const handleDownload = async () => {
    try {
      setDownloadLoader(true);
      const orderId = orderDetails["order_id"];
      const response = await EquipmentAPIs.downloadZipFile(orderId);
      // console.log("response ==================>", response);
      if (response["type"] === "RXSUCCESS") {
        const fileUrl = response?.data?.path;

        if (!fileUrl) {
          throw new Error("File URL not provided in the response.");
        }

        const link = document.createElement("a");
        link.href = fileUrl;
        // link.target = "_blank"; // Open in a new tab if the download attribute fails
        link.download = `OrderId-${orderId}.zip`; // Suggested file name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        // dispatch(setalertMessage("Download started!"));
        // dispatch(setalertMessagetype("success"));
      } else {
        throw new Error(response["message"]);
      }
    } catch (error) {
      let message = error["message"];
      if (error?.response?.data) {
        message = error?.response?.data?.message;
      }
      dispatch(setalertMessage(message));
      dispatch(setalertMessagetype("error"));
    } finally {
      setDownloadLoader(false);
    }
  };

  const handleArrowClick = (clickedArrow) => {
    const totalMedia = mediaFileData?.length - 1;
    let activeIndex = activeMediaIndex;
    if (clickedArrow === "forward") {
      activeIndex = activeIndex === totalMedia ? 0 : activeIndex + 1;
    } else if (clickedArrow === "backward") {
      activeIndex = activeIndex === 0 ? totalMedia : activeIndex - 1;
    }
    const { file, file_type } = mediaFileData[activeIndex];
    openLightbox(file, file_type, activeIndex);
  };

  useEffect(() => {
    getMediaFiles({
      orderId: orderDetails?.["order_id"],
      userId: orderDetails?.["owner_id"],
    });
  }, []);

  const isOwner = AuthUserId === orderDetails?.["owner_id"];
  // const RadioBtnTitle = [
  //   { title: "Images by you", actionType: "photo" },
  //   {
  //     title: `Images by ${isOwner ? "renter" : "owner"}`,
  //     actionType: `photo${isOwner ? "R" : "O"}`,
  //   },
  //   { title: "Videos by you", actionType: "video" },
  //   {
  //     title: `Videos by ${isOwner ? "renter" : "owner"}`,
  //     actionType: `video${isOwner ? "R" : "O"}`,
  //   },
  // ];

  return (
    <>
      <Modal
        keepMounted
        open={isOpen}
        onClose={onClose}
        aria-labelledby="video-popup-title"
        aria-describedby="video-popup-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "#2e2e2e",
            border: "5px solid #faa61a",
            borderRadius: "10px",
            outline: "none",
            width: { xs: "90%", sm: "90%", md: "500px" },
          }}
        >
          <IconButton
            sx={{
              position: "absolute",
              top: "-20px",
              right: "-20px",
              bgcolor: "#faa61a",
              color: "#000",
              "&:hover": {
                bgcolor: "#faa61a",
              },
            }}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
          <Box>
            <Box
              sx={{
                // maxHeight: { xs: "500px", sm: "500px", md: "700px" },
                // overflowY: "auto",
                // overflowX: "hidden",
                overflow: "hidden",
                p: 3,
                width: "100%",
                boxSizing: "border-box",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "20px",
                }}
              >
                <Typography
                  variant="h5"
                  color="#faa61a"
                  gutterBottom
                  sx={{
                    fontSize: { xs: "20px", sm: "20px", md: "24px" },
                    fontFamily: "GothamBold !important",
                  }}
                >
                  Photos/Videos
                </Typography>
                <Button
                  variant="text"
                  sx={{
                    background: "#008001",
                    border: "2px solid #008001",
                    color: "#fff",
                    padding: "5px",
                    width: "auto",
                    borderRadius: "5px",
                    "&:hover": {
                      background: "#008001",
                      color: "#fff",
                    },
                    position: "relative",
                    textTransform: "unset",
                  }}
                  onClick={handleDownload}
                  disabled={downloadLoaer}
                >
                  <Download
                    sx={{ fontSize: "24px", marginRight: 1, fill: "#fff" }}
                  />
                  <Box
                    sx={{
                      fontFamily: "GothamBold !important",
                      color: "#fff",
                      textTransform: "unset",
                    }}
                  >
                    {downloadLoaer ? "Downloading..." : "Download"}
                  </Box>
                  {downloadLoaer && (
                    <Stack
                      sx={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        width: "100%",
                      }}
                    >
                      <LinearProgress color="success" />
                    </Stack>
                  )}
                </Button>
              </Box>

              {!isPastRental && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: { xs: "column", sm: "column", md: "row" },
                    gap: "1rem",
                    mb: 2,
                  }}
                >
                  <Button
                    component="label"
                    sx={{
                      width: "100%",
                      border: "1px solid #fff",
                      backgroundColor: "transparent",
                      color: "#fff",
                      borderRadius: "7px",
                      padding: "8px",
                      textAlign: "center",
                      fontFamily: "GothamBold !important",

                      display: "flex",
                      alignItems: "center",
                      gap: "1rem",
                      textTransform: "capitalize",
                    }}
                    onClick={handleuploadModalOpen}
                  >
                    {/* <Upload
                    sx={{ fontSize: "24px", marginRight: 1, fill: "#fff" }}
                  /> */}
                    {/* <Box
                    component={"img"}
                    src={cameraIcon}
                    alt="camera"
                    sx={{
                      width: "24px",
                      height: "auto",
                    }}
                  /> */}
                    <CameraAltIcon
                      sx={{ color: "#faa61a", fontSize: "34px" }}
                    />
                    Upload Photo
                    {/* <input
                    type="file"
                    accept="image/jpeg, image/png, image/jpg, video/mp4"
                    multiple
                    hidden
                    onChange={handleFileChange}
                  /> */}
                  </Button>
                  <Button
                    component="label"
                    sx={{
                      width: "100%",
                      border: "1px solid #fff",
                      backgroundColor: "transparent",
                      color: "#fff",
                      borderRadius: "7px",
                      padding: "8px",
                      textAlign: "center",
                      fontFamily: "GothamBold !important",

                      display: "flex",
                      alignItems: "center",
                      gap: "1rem",
                      textTransform: "capitalize",
                    }}
                    onClick={handleuploadModalOpen}
                  >
                    {/* <Upload
                    sx={{ fontSize: "24px", marginRight: 1, fill: "#fff" }}
                  /> */}
                    {/* <Box
                    component={"img"}
                    src={cameraIcon}
                    alt="camera"
                    sx={{
                      width: "24px",
                      height: "auto",
                    }}
                  /> */}
                    <CameraAltIcon
                      sx={{ color: "#faa61a", fontSize: "34px" }}
                    />
                    Upload Video
                    {/* <input
                    type="file"
                    accept="image/jpeg, image/png, image/jpg, video/mp4"
                    multiple
                    hidden
                    onChange={handleFileChange}
                  /> */}
                  </Button>
                </Box>
              )}

              <Tabs
                value={activeTab}
                onChange={handleTabChange}
                textColor="primary"
                centered
                variant="fullWidth"
                sx={{
                  boxSizing: "border-box",
                  background: "#484840",
                  padding: "5px",
                  borderRadius: "5px",
                  width: "100%",
                  "& .MuiTabs-indicator": {
                    display: "none", // Hides the bottom indicator
                  },
                }}
              >
                <Tab
                  label={`Owner ${isOwner ? "(Me)" : ""}`}
                  sx={{
                    marginRight: "5px",
                    minHeight: "unset !important",
                    padding: "10px !important",
                    fontSize: "15px",
                    color: "#fff",
                    fontFamily: "Montserrat !important",
                    backgroundColor:
                      activeTab === 0 ? "#faa61a" : "transparent",
                    borderRadius: "5px",
                    fontWeight: activeTab === 0 ? "700" : "500",
                    "&.Mui-selected": {
                      color: "#fff",
                    },
                    "&:hover": {
                      backgroundColor: "#faa61a",
                      opacity: 0.8,
                    },
                    textTransform: "capitalize",
                  }}
                />
                <Tab
                  label={`Renter ${isOwner ? "" : "(Me)"}`}
                  sx={{
                    color: "#fff",
                    minHeight: "unset !important",
                    padding: "10px !important",
                    fontSize: "15px",
                    fontFamily: "Montserrat !important",
                    backgroundColor:
                      activeTab === 1 ? "#faa61a" : "transparent",
                    borderRadius: "5px",
                    fontWeight: activeTab === 1 ? "700" : "500",
                    "&.Mui-selected": {
                      color: "#fff",
                    },
                    "&:hover": {
                      backgroundColor: "#faa61a",
                      opacity: 0.8,
                    },
                    textTransform: "capitalize",
                  }}
                />
              </Tabs>

              <Box sx={{ padding: 1 }}>
                <Typography variant="body1">
                  <Box sx={{ mb: 1 }}>
                    <RadioGroup
                      aria-labelledby="file-filter-group-label"
                      value={filter}
                      onChange={handleFilterChange}
                      row
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "1rem",
                      }}
                    >
                      <FormControlLabel
                        value="all"
                        checked={filter === "all"}
                        control={<Radio sx={radioStyles} />}
                        sx={radiolabelStyles}
                        label="All"
                      />
                      <FormControlLabel
                        value="photo"
                        checked={filter === "photo"}
                        control={<Radio sx={radioStyles} />}
                        sx={radiolabelStyles}
                        label="Photos"
                      />
                      <FormControlLabel
                        value="video"
                        checked={filter === "video"}
                        control={<Radio sx={radioStyles} />}
                        sx={radiolabelStyles}
                        label="Videos"
                      />
                      {/* {RadioBtnTitle.map((ele, index) => (
                          <FormControlLabel
                            key={index}
                            value={ele.actionType}
                            checked={filter === ele.actionType}
                            control={<Radio sx={radioStyles} />}
                            sx={radiolabelStyles}
                            label={ele.title}
                          />
                        ))} */}
                    </RadioGroup>
                  </Box>
                </Typography>
                {!loading && mediaFileData.length > 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.5rem",
                      height: { xs: "230px", sm: "300px", md: "400px" },
                      overflowY: "auto",
                    }}
                  >
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        gap: "2rem",
                      }}
                    >
                      {mediaFileData.map((fileData, index) => (
                        <Box
                          key={fileData.id}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                          }}
                        >
                          <Box
                            sx={{
                              position: "relative",
                              borderRadius: "8px",
                              overflow: "hidden",
                              width: "100%",
                              height: 0,
                              paddingBottom: "100%",
                            }}
                          >
                            {/* {fileData.file_type.startsWith("photo") ? (
                              <Box
                                component={"img"}
                                src={fileData.file}
                                alt={`Preview ${fileData.id}`}
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                  borderRadius: "8px",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  openLightbox(
                                    fileData.file,
                                    fileData.file_type,
                                    index
                                  )
                                }
                              />
                            ) : (
                              <Box
                                component={"video"}
                                // src={fileData.file}
                                controls
                                poster={fileData.thumbnail}
                                sx={{
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                  borderRadius: "8px",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  openLightbox(
                                    fileData.file,
                                    fileData.file_type,
                                    index
                                  )
                                }
                              />
                            )} */}
                            <ImageWithPlaceholder
                              fileData={fileData}
                              openLightbox={openLightbox}
                              index={index}
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              gap: "1rem",
                            }}
                          >
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              sx={{
                                color: "#faa61a",
                                fontFamily: "Montserrat !important",
                                fontSize: "12px",
                                fontWeight: "600",
                              }}
                            >
                              {orderDetails?.["timezone"]
                                ? convertTimeZoneShortName(
                                    momentTimeZone
                                      .tz(
                                        fileData.createdAt,
                                        orderDetails["timezone"]
                                      )
                                      .format("hh:mm A (z) MM/DD/YY")
                                  )
                                : moment(fileData.createdAt).format(
                                    "hh:mm A (UTC) MM/DD/YY"
                                  )}
                            </Typography>
                            {/* <Button
                            variant="text"
                            sx={{
                              background: "#faa61a",
                              color: "#fff",
                              minWidth: "unset",
                              borderRadius: "5px",
                              "&:hover": {
                                background: "#faa61a",
                                color: "#fff",
                              },
                            }}
                            onClick={() => handleDownload(file)}
                          >
                            <Download sx={{ fontSize: "24px" }} />
                          </Button> */}
                          </Box>
                        </Box>
                      ))}
                    </Box>
                    {totalFiles > mediaFileData.length && (
                      <Box className="pagination-section">
                        {loadMore ? (
                          <CircularProgress />
                        ) : (
                          <Box
                            className="btn-load-more"
                            onClick={() => {
                              setPageNo((prev) => prev + 1);
                              handlePagination(pageNo);
                            }}
                          >
                            {"Load More"}
                            <img
                              src={gemArrow}
                              alt="GoEquipMe"
                              className="gem-arrow arrow-load-more"
                            />
                          </Box>
                        )}
                      </Box>
                    )}
                  </Box>
                )}

                {!loading && mediaFileData.length === 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      // p: 4,
                      color: "#fff",
                      fontFamily: "Montserrat !important",
                      fontWeight: "600",
                      height: { xs: "230px", sm: "300px", md: "400px" },
                    }}
                  >
                    No Records Found!
                  </Box>
                )}
                {loading && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      // p: 4,
                      height: { xs: "230px", sm: "300px", md: "400px" },
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}
              </Box>

              {/* <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: { xs: "column", sm: "column", md: "row" },
                  gap: "1rem",
                }}
              >
                <Button
                  component="label"
                  sx={{
                    width: "100%",
                    border: "2px dotted #faa61a",
                    backgroundColor: "#faa61a",
                    color: "#fff",

                    padding: "10px",
                    textAlign: "center",
                    fontFamily: "GothamBold !important",
                  }}
                  onClick={handleuploadModalOpen}
                >
                  <Upload
                    sx={{ fontSize: "24px", marginRight: 1, fill: "#fff" }}
                  />
                  Upload Files
                </Button>

                <Button
                  variant="text"
                  sx={{
                    background: "#008001",
                    border: "2px solid #008001",
                    color: "#fff",
                    padding: "10px",
                    width: "100%",
                    borderRadius: "5px",
                    "&:hover": {
                      background: "#008001",
                      color: "#fff",
                    },
                    position: "relative", // Allows proper positioning of child elements
                    textTransform: "unset",
                  }}
                  onClick={handleDownload}
                  disabled={downloadLoaer} // Disable the button while loading
                >
                  <Download
                    sx={{ fontSize: "24px", marginRight: 1, fill: "#fff" }}
                  />
                  <Box
                    sx={{
                      fontFamily: "GothamBold !important",
                      color: "#fff",
                      textTransform: "unset",
                    }}
                  >
                    {downloadLoaer ? "Downloading..." : "Download"}
                  </Box>
                  {downloadLoaer && (
                    <Stack
                      sx={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        width: "100%",
                      }}
                    >
                      <LinearProgress color="success" />
                    </Stack>
                  )}
                </Button>
              </Box> */}
            </Box>
          </Box>
        </Box>
      </Modal>
      {/* Lightbox Modal for Full-Screen View */}
      <Dialog
        open={lightboxOpen}
        onClose={closeLightbox}
        maxWidth="xl"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0,0,0,0.5)",
        }}
        PaperProps={{
          sx: {
            backgroundColor: "transparent !important",
            borderRadius: "8px",
            overflow: "visible", // Allow content to overflow
          },
        }}
      >
        <DialogContent
          sx={{
            padding: 0,
            backgroundColor: "#2d2d2d",
            border: "4px solid #faa61a",
            borderRadius: "8px",
            position: "relative",
            textAlign: "center",
            width: {
              xs: "300px",
              sm: "350px",
              md: "500px",
              lg: "700px",
            },
            maxWidth: "1000px",
            overflow: "visible", // Ensure child elements can overflow
          }}
        >
          <IconButton
            sx={{
              position: "absolute",
              top: "-20px", // Position above the dialog
              right: "-20px", // Position to the right of the dialog
              zIndex: 2, // Ensure it appears on top
              bgcolor: "#faa61a",
              color: "#000",
              "&:hover": {
                bgcolor: "#faa61a",
              },
            }}
            onClick={closeLightbox}
          >
            <CloseIcon />
          </IconButton>

          <Box
            sx={{
              width: {
                xs: "40px",
                md: "40px",
              },
              height: {
                xs: "40px",
                md: "40px",
              },
              borderRadius: "50%",
              background: "rgba(0,0,0,0.7)",
              position: "absolute",
              left: "1%",
              top: "50%",
              transform: "translate(-1%,-50%)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              zIndex: 1000,
            }}
            onClick={() => handleArrowClick("backward")}
          >
            <ArrowBack sx={{ color: "#fff" }} />
          </Box>

          <Box
            sx={{
              width: {
                xs: "40px",
                md: "40px",
              },
              height: {
                xs: "40px",
                md: "40px",
              },
              borderRadius: "50%",
              background: "rgba(0,0,0,0.7)",
              position: "absolute",
              right: "1%",
              top: "50%",
              transform: "translate(-1%,-50%)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              zIndex: 1000,
            }}
            onClick={() => handleArrowClick("forward")}
          >
            <ArrowForward sx={{ color: "#fff" }} />
          </Box>

          {currentFile && currentFile.type.startsWith("photo") ? (
            <Box
              component="img"
              src={currentFile.src}
              alt="Full View"
              sx={{
                width: "100%",
                // height: "100%",
                height: {
                  xs: "350px",
                  sm: "500px",
                  md: "500px",
                  lg: "600px",
                  xl: "700px",
                },
                objectFit: "contain",
                margin: "auto",
                display: "block",
              }}
            />
          ) : currentFile ? (
            <Box
              component="video"
              src={currentFile.src}
              controls
              autoPlay
              sx={{
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "100%",
                },
                height: {
                  xs: "350px",
                  sm: "500px",
                  md: "500px",
                  lg: "600px",
                  xl: "700px",
                },
                objectFit: "contain",
                margin: "auto",
                display: "block",
              }}
            />
          ) : null}
        </DialogContent>
      </Dialog>

      {/* simple text message dialoge */}
      <Dialog
        open={uploadModalOpen}
        onClose={handleuploadModalClose}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0,0,0,0.5)",
        }}
        PaperProps={{
          sx: {
            backgroundColor: "transparent !important",
            borderRadius: "8px",
            overflow: "visible", // Allow content to overflow
          },
        }}
      >
        <DialogContent
          sx={{
            padding: 5,
            backgroundColor: "#2d2d2d",
            border: "4px solid #faa61a",
            borderRadius: "8px",
            position: "relative",
            textAlign: "center",
            width: "auto",
            maxWidth: "400px",
            overflow: "visible", // Ensure child elements can overflow
          }}
        >
          <IconButton
            sx={{
              position: "absolute",
              top: "-20px", // Position above the dialog
              right: "-20px", // Position to the right of the dialog
              zIndex: 2, // Ensure it appears on top
              bgcolor: "#faa61a",
              color: "#000",
              "&:hover": {
                bgcolor: "#faa61a",
              },
            }}
            onClick={handleuploadModalClose}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            variant="body1"
            sx={{ color: "#fff", fontFamily: "Gotham !important" }}
          >
            Media can only be added using the GoEquipMe App.
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default FilesViewerPopup;
