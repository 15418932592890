import React from "react";
import { useState, useEffect } from "react";
import MainLayout from "../../layouts/MainLayout";
import { Box, Grid, Typography } from "@mui/material";
import { EquipmentAPIs } from "../../service/api-service";
import { httpClient } from "../../service/http-client";
import "./styles.scss";
import { IApiResponseRawData, IEquipment } from "utils/types";

/**
 * Main About page.
 */
const TermsAndConditions: React.FC = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <MainLayout>
        <Box>
          <div className="wrapper">
            <div className="">
              <div className="div-para">
                <div>
                  <h1 className="heading">
                    GoEquipMe Inc. <br></br>Terms and Conditions
                  </h1>
                  <p className="p2 ">Last revised: January 5, 2022</p>
                  <h2 className="p3">Equipment Rental Terms and Conditions</h2>
                  <p className="para-1">
                    This contract is entered into between the owner (“Owner”) of
                    the equipment (“Equipment”) making it available for rent to
                    the renter (“Renter”) through the GoEquipMe Inc. (GEM or
                    GoEquipMe) application platform (the App). Owner, Renter and
                    GEM are referred to as the parties. The parties agree to
                    treat each other with mutual respect and take full
                    responsibility for their actions which probably makes the
                    rest of this superfluous but we’ll spell out the rest anyway
                    hoping no one ever has to read it 😉.
                  </p>
                  <ol className="ol">
                    <li className="li">
                      <u className="u">General</u>. GEM makes various Owners’
                      equipment available for rent through its platform in “as
                      is” condition for a period of time (“Rental Period”) set
                      forth at the time of reservation. Renter agrees to return
                      the equipment prior to expiration of the Rental Period, or
                      extend the Rental Period should the equipment be made
                      available by the Owner.{" "}
                    </li>
                    <li className="li">
                      <u>Payment</u>. Renter is responsible for all charges due
                      in full at the commencement of the Rental Period including
                      the applicable security deposit (“Deposit”).
                    </li>
                    <li className="li">
                      <u>Permitted Use / Restrictions / Maintenance</u>. The
                      parties agree GEM has no control over the equipment at any
                      time including transport of the equipment or how it is
                      operated or utilized. Renter promises that:
                      <ol className="lower-alpha">
                        <li className="li">
                          Renter will inspect the Equipment to confirm that it
                          is in good working condition, without defects and is
                          suitable for Renter’s intended use;
                        </li>
                        <li className="li">
                          Renter has experience operating the particular type of
                          Equipment being rented and has received all
                          information requested and deemed necessary regarding
                          the operation of the Equipment;
                        </li>
                        <li className="li">
                          Renter will use the Equipment for its customary
                          purpose, in compliance with all operating and safety
                          instructions and immediately discontinue use of the
                          Equipment if it becomes unsafe or in a state of
                          disrepair;
                        </li>
                        <li className="li">
                          Equipment will be kept in a secure location;
                        </li>
                        <li className="li">
                          Renter will maintain possession of the Equipment and
                          will not sublease or sell the Equipment or assign this
                          Agreement;
                        </li>
                        <li className="li">
                          Renter will keep the Equipment free and clear of all
                          liens, charges and encumbrances;
                        </li>
                        <li className="li">
                          Renter will use the Equipment in compliance with all
                          applicable laws and regulations;
                        </li>
                        <li className="li">
                          Renter will not alter or cover up any decal or
                          insignia on the Equipment or remove any operational or
                          safety instructions;
                        </li>
                        <li className="li">
                          Renter will not aid in the vandalism or theft of the
                          Equipment;
                        </li>
                        <li className="li">
                          Renter will not rent the Equipment upon the basis of
                          false or misleading information;
                        </li>
                        <li className="li">
                          Renter will not operate the Equipment while
                          intoxicated or under the influence of any substance
                          that impairs Renter’s ability;
                        </li>
                        <li className="li">
                          Renter will not use the Equipment in a negligent,
                          reckless, illegal, unauthorized or abusive manner, or
                          in any publication;
                        </li>
                        <li className="li">
                          Renter will not remove, operate, or utilize the
                          Equipment outside of the United States
                        </li>
                        <li className="li">
                          Renter will maintain proper, fuel, oil or lubrication
                          levels and will operate the equipment according to
                          manufacturer’s specifications and safety requirements;
                        </li>
                        <li className="li">
                          Renter agrees to perform routine inspections including
                          inspections on leaks, cooling systems, water
                          batteries, cutting edges, and cleaning in accordance
                          with the manufacturer’s specifications;
                        </li>
                        <li className="li">
                          Renter will correspond directly with the Equipment
                          Owner, and work in good faith with the Owner, should
                          any question arise pertaining to the operation,
                          maintenance, and transport of the Equipment.
                        </li>
                        <li className="li">
                          Should Equipment be transported by Renter, Renter will
                          ensure compliance with all laws pertaining to the
                          transport of Equipment.{" "}
                        </li>
                        <li className="li">
                          Renter will ensure any third-party utilized for
                          transport of Equipment will have all applicable
                          licenses and insurances necessary to transport the
                          Equipment.
                        </li>
                      </ol>
                    </li>

                    <li className="li">
                      <u>Fuel and Cleaning</u>. Renter will return the Equipment
                      with the same fuel level and as clean as when Renter
                      received the Equipment, otherwise Owner may charge a fee
                      for the cost of refueling or cleaning.
                    </li>
                    <li className="li">
                      <u>Repossession</u>. The Equipment may be repossessed by
                      Owner or GEM, without notice to Renter and at Renter’s
                      expense if the Equipment is not returned at the expiration
                      of the Rental Period, is illegally operated, parked or
                      stored, or used in any way in violation of this Agreement,
                      or appears to be abandoned,
                    </li>
                    <li className="li">
                      <u>Cancellation Policy</u>.
                      <ol className="lower-alpha">
                        <li className="li">
                          <u>Renter Cancellation</u>. Renter may cancel their
                          equipment rental through our mobile App, and the
                          cancellation is effective immediately. The total
                          amount refunded will depend on when the Renter cancels
                          the rental. The cancellation period is based on the
                          equipment location’s time zone.
                          <ol className="upper-roman">
                            <li className="li">
                              <u>Free Cancellation Period</u>. Renters may
                              cancel free of charge up to 24 hours before the
                              rental period starts. Renters who book within 24
                              hours of their rental period start time have one
                              hour after booking to cancel for free. If a Renter
                              cancels a booking after the free cancellation
                              period has expired their 10% Deposit is forfeited.
                            </li>
                            <li className="li">
                              <u>Early Returns</u>. There are no credits/refunds
                              issued for early returns except when the Renter
                              has requested to shorten their Rental Period from
                              the equipment Owner and the Owner has accepted
                              such request in writing.{" "}
                            </li>
                          </ol>
                        </li>
                        <li className="li">
                          <u>Owner Cancellation</u>. If an Owner cancels a
                          booking after the free cancellation period has expired
                          the Owner must pay the Deposit amount as a late
                          cancellation fee. GEM may disclose to other App users
                          regarding Owner’s previous cancellation details
                          including how long before the Rental Period start time
                          that the Owner has previously cancelled reservations.
                          Owners who repeatedly cancel rental bookings may be
                          subject to additional penalties, including removal
                          from the marketplace.
                        </li>
                        <li className="li">
                          <u>GEM Cancellations</u>. In some cases, GoEquipMe may
                          need to cancel a booked rental to protect our users
                          against fraud or for trust and safety considerations.
                          GoEquipMe will notify both Owner and Renter in such an
                          event. When GoEquipMe must cancel a booking, Renter
                          will receive a full refund and Owners are generally
                          not eligible for earnings.
                        </li>

                        <h4 className="p3">FREE CANCELLATION PERIOD</h4>

                        <table id="t1">
                          <tr>
                            <th>BOOKING TIME</th>
                            <th>FREE CANCELLATION PERIOD</th>
                          </tr>
                          <tr>
                            <td>25 hours or more before rental start</td>
                            <td>
                              Until 24 hours{" "}
                              <b className="b">before rental start</b>
                            </td>
                          </tr>
                          <tr>
                            <td>Less than 25 hours before rental start</td>
                            <td>
                              1 hour <b className="b">after booking</b>
                            </td>
                          </tr>
                        </table>
                        <li className="li">
                          <u>Rental Modifications</u>. Any rental modifications
                          must be processed through App with applicable costs
                          and fees paid.
                        </li>
                        <li className="li">
                          <u>Renter No-Shows</u>. If a Renter fails to cancel
                          and doesn’t show up after 1 hour of the Rental Period
                          start time time, it is a Renter no-show and is
                          considered a late cancellation.{" "}
                        </li>
                      </ol>
                    </li>
                    <li className="li">
                      <u>Consent to Communication via Email and Texting</u>. The
                      parties consent to receive GEM communications via email,
                      text message and push and other notifications through
                      their device.
                    </li>
                    <li className="li">
                      <u>Independent Status</u>. The relationship between the
                      parties is independent contractors and creates no agency
                      or employment relationship between the parties.
                    </li>
                    <li className="li">
                      <u>Governing Law</u>. This Agreement will be construed
                      according to the laws of the State of Utah.
                    </li>
                    <li className="li">
                      <u>Entire Agreement</u>. This Agreement represents the
                      entire agreement between the parties and may be amended or
                      modified only in writing signed by the parties.{" "}
                    </li>
                  </ol>
                </div>
              </div>

              <div className="div-para">
                <h3 className="h3">Liability Insurance Requirement </h3>
                <p className="p">
                  The person in possession of the Equipment at any time is
                  responsible to procure and maintain adequate and industry
                  customary insurance on the Equipment for liability coverage
                  for damage to people or property. The person in possession of
                  the Equipment, therefore is responsible for damage to any
                  other person or property while the Equipment is in their
                  possession. You must inspect the Equipment in your possession
                  prior to use to ensure it is safe and ready for proper use. By
                  utilizing GoEquipMe App you represent and warrant that you
                  have liability coverage at your home, worksite and to
                  transport equipment, as applicable. All parties utilizing the
                  App agree to indemnify and hold harmless GoEquipMe Inc from
                  any and all damages including consequential damages or any
                  kind including lost profits.
                </p>

                <h2 className="h2">Equipment Damage Protection </h2>
                <h2 className="h2"> Policy and Security Deposit</h2>

                <p className="p">
                  Regardless of fault, the Renter is responsible for all loss
                  and damage to the Equipment once Renter takes possession of
                  the Equipment until possession is released to Owner. Under our
                  GoEquipMe (GEM) Equipment Protection program Renter will not
                  be responsible for most causes of damage that may occur to the
                  equipment while being rented and GEM Protection will cover
                  repair or replacement of the damaged equipment. Renters may
                  opt out of this protection if proof of full coverage insurance
                  is provided prior to the Rental Period.
                </p>
                <p className="p">
                  <b className="b">Advantages of GEM Equipment Protection:</b>
                  Provides higher certainty of coverage, hassle free claims in
                  the event of damage to the equipment, a good option for
                  customers who can’t provide proof of insurance coverage,
                  claims will not affect customer’s other insurance premiums, no
                  waiting for coverage through other insurance company.
                </p>
                <p className="p">
                  <b className="b">Cost:</b> 15% of the rental charges
                </p>
                <p className="p">
                  <b className="b">Deductible:</b> $1,000 for equipment valued
                  less than $25,000, and $2,500 for equipment valued at $25,000
                  or more.
                </p>
                <p className="p">
                  <b className="b">What’s Covered:</b> Accidental damage,
                  collision damage (to the rented equipment only) up to a total
                  of $50,000. In the event GoEquipMe covers the cost of
                  equipment damage the equipment Owner agrees that rights to
                  pursue the responsible or otherwise liable parties is
                  transferred to GoEquipMe with full subrogation rights up to
                  the amount paid by GoEquipMe plus reasonable costs and
                  attorney’s fees.
                </p>
                <p className="p">
                  <b className="b">Exclusions:</b> Acts of God including
                  earthquake, fire, falling objects, flood, hail, overturns,
                  theft, tornado, vandalism, and wind. Civil authority,
                  contamination or deterioration, criminal, fraudulent,
                  dishonest, or illegal acts, loss of use, mechanical breakdown,
                  missing property in the case of unexplained or mysterious
                  disappearance, nuclear hazard, pollutants,
                  temperature/humidity, voluntary parting with title, war and
                  military action, and wear and tear. In no circumstances is
                  GoEquipMe liable for any consequential loss or financial
                  damages of any kind or any amounts in excess of $50,000 total,
                  whether to Renter, Owner or any party associated, affiliated
                  or otherwise connected in any way to this contract.
                </p>

                <p className="p">
                  <b className="b">Requirements for Opting Out:</b> Customers
                  have the option of waiving coverage by providing proof of
                  insurance including a certificate of property insurance
                  showing specific or blanket rented equipment coverage and must
                  be emailed to admin@goequipme.com before the equipment rental
                  commences. Any amounts already charged for GEM Equipment
                  Protection will be refundable upon GoEquipMe receiving the
                  proof of insurance prior to commencement of the rental.
                </p>

                <p className="p">
                  <b className="b">Security Deposit:</b> In some cases we
                  require a security deposit before you may operate the
                  Equipment in the amount of 20% of the amount of the equipment
                  reservation. The deposit amount is collected in full before
                  the start of the equipment rental. The deposit will be
                  refunded 48 hours after the equipment rental is completed
                  unless there is a dispute regarding the security deposit
                  amount being refunded in which case the appropriate portion
                  will be refunded upon ending the dispute.
                </p>
              </div>

              <div className="div-para ">
                <h2 className="h2">Payments Outside GoEquipMe</h2>
                <h2 className="h2">App Policy</h2>

                <p className="p">
                  By utilizing the GoEquipMe App you agree not to circumvent
                  GoEquipMe for the payment of any goods or services comparable
                  to the services facilitated by GoEquipMe including that you
                  agree not to make or accept payments for the rental of
                  Equipment outside the App including by PayPal, cash, check,
                  credit card, Square, Cash App, Bitcoin, Venmo or any other
                  method of payment. Should either party circumvent or attempt
                  to circumvent the App then GEM’s obligations if any to that
                  party will be void and GEM reserves the right to remove the
                  circumventing party at GEM’s sole discretion.
                </p>
                <p className="p">
                  If any party asks you to make or accept a payment outside the
                  App please contact us at{" "}
                  <a className="a-link-gem" href="mailto:admin@goequipme.com">
                    {" "}
                    admin@goequipme.com{" "}
                  </a>
                </p>

                <h2 className="h2">Privacy policy</h2>
                <p className="p">
                  GoEquipMe Inc. (GEM “we”, or “us”) cares about privacy and
                  wants you to be familiar with how we collect, use, process and
                  disclose your information. This Privacy Policy covers your
                  access or use of our application and website (“Platform”),
                  when communicating with us or other GEM users on any device
                  (collectively “Services”), or when you otherwise provide us
                  with information. GEM acts as the data controller for the
                  purpose of this Privacy Policy.
                </p>
                <p style={{ textAlign: "center" }}>
                  <b className="b">What We Collect</b>
                </p>
                <p className="p">
                  We collect three categories of information - information you
                  give us; information automatically collected from your use of
                  the Services; and information from third party sources.
                </p>
              </div>

              <div className="div-para">
                <b className="b">Information you give us</b>
                <p className="p">
                  <b className="b">Account information.</b> When you register
                  for an account with us, we require certain identifying
                  information such as your name and email address.
                </p>
                <p className="p">
                  <b className="b">Profile information.</b> We may also ask you
                  to provide additional profile information to use certain
                  features of the GoEquipMe App which may include street
                  addresses, phone numbers, driver’s license number and issuing
                  country and/or state and date of birth. Certain parts of your
                  profile (like your profile photos, company, city) are part of
                  your public profile page and will be publicly visible to
                  others.
                </p>
                <p className="p">
                  <b className="b">Equipment Rental information.</b> We collect
                  information you provide in relation to equipment rental such
                  as equipment details, vehicle identification number (VIN) and
                  other equipment identifying information, availability dates,
                  reviews and uploaded photos of the rented equipment.
                </p>
                <p className="p">
                  <b className="b">Payment information.</b> We collect your
                  financial information (like your bank account or payment card
                  numbers) in connection with a potential or actual transaction,
                  which may be stored by one or more third party payment
                  services providers or digital payments companies that may be
                  located inside or outside of your country of residence
                  including outside the European Economic Area (EEA).
                </p>
                <p className="p">
                  <b className="b">Authentication information.</b> In some
                  instances, we may ask you to provide us a photograph and/or
                  other demographic information, such as a scanned copy of a
                  driver’s license, passport, or credit/debit card, last four
                  digits of your Social Security number, social insurance
                  number, social media account information, driver’s/motor
                  vehicle record, insurance information, and other forms of
                  identification. Where we request that you withhold certain
                  information when providing us with personally identifiable
                  information (such as obscuring or redacting all but the last
                  four digits of your payment card number), please do so.
                </p>
                <p className="p">
                  <b className="b">Communications with GoEquipMe.</b> When you
                  communicate with us whether via phone, email or chat, or use
                  the Services to communicate with other users, we collect
                  information about your communication and any information you
                  choose to provide.
                </p>
              </div>

              <div className="div-para">
                <b className="b">
                  Information we automatically collect when you use or access
                  our Services.
                </b>

                <p className="p">
                  <b className="b">Usage data.</b>We collect information about
                  your interactions with the Platform, such as the pages or
                  other content you view, your searches, bookings you have made
                  and other actions on the Platform.
                </p>
                <p className="p">
                  <b className="b">Location information.</b> When you use
                  certain features of the Platform, including if you opt in to
                  use our Location Sharing feature, we may collect information
                  about your location (e.g., IP address) and the location of
                  your equipment or more specific location information (e.g.,
                  more precise location information from your mobile device).
                  Keep in mind that most mobile devices allow you to control or
                  disable the use of location services by any application on
                  your mobile device in the device’s settings menu.
                </p>
                <p className="p">
                  <b className="b">Log data.</b> We may automatically collect
                  log information when you use the Platform, even if you have
                  not created an GoEquipMe App account or logged in. That
                  information includes, among other things: details about how
                  you’ve used the Platform, IP address, length of stay,
                  frequency of visits, hardware and software information, device
                  information, device event information (e.g., crashes, browser
                  type) and the page you’ve viewed or engaged with before [or
                  after] you’ve used the Platform.
                </p>
                <p className="p">
                  <b className="b">Equipment Rental Information.</b>We collect
                  information related to your transactions on the Platform,
                  including the date and time, amounts charged and other related
                  trip details.
                </p>
                <p className="p">
                  <b className="b">Cookies and similar technology.</b> When you
                  access our Platform, we (including companies we work with) may
                  place small data files on your computer or other device. These
                  data files may be cookies, clear gifs, pixel tags, e-tags,
                  “Flash cookies”, or other local storage provided by your
                  browser or associated applications (collectively “Cookies”).
                </p>

                <b className="b">
                  Information we collect from third party sources.
                </b>
                <p className="p">
                  <b className="b">Third party services.</b> If you choose to
                  log in to our Services through social media sites or apps
                  (e.g., Facebook or Google), the third-party service may send
                  us information such as your registration and profile
                  information from that service. The information we may receive
                  varies by app and is controlled by that company. By
                  associating an account managed by a third party with your
                  GoEquipMe account and authorizing us to have access to this
                  information, you agree that we may collect, store, and use
                  this information in accordance with this Privacy Policy. We
                  are not responsible for how those third parties use and share
                  your information. Please refer to those third parties’ privacy
                  policies to understand how they use and share your
                  information.
                </p>
              </div>

              <div className="div-para">
                <p className="p">
                  <b className="b">Background information.</b> To the extent
                  permitted by applicable laws, we may obtain reports from
                  public records of criminal convictions or local versions of
                  background or registered sex offenders checks. We may use your
                  information, including your full name and date of birth, to
                  obtain such reports.
                </p>
                <p className="p">
                  <b className="b">Other sources.</b> To the extent permitted by
                  applicable law, we may receive additional information about
                  you, such as demographic data, fraud detection information or
                  data from credit bureaus from third party service providers
                  and/or partners and combine it with information we have about
                  you.
                </p>

                <h5 className="h5">How we use what we collect</h5>
                <p className="p">
                  We use, store and process your information to provide and
                  improve our Services and ensure a secure, safe and trusted
                  experience. For example, we may use your information to:
                </p>
                <b className="b">Provide and improve our Services</b>

                <ul>
                  <li className="li">
                    enable you to access and use the GoEquipMe App Services
                  </li>
                  <li className="li">provide customer support</li>
                  <li className="li">
                    send you service, support and administrative messages,
                    reminders, technical notices, updates, security alerts, and
                    information requested by you and contact you at any
                    telephone number, by placing a voice call or through text
                    (SMS) or email messaging
                  </li>
                  <li className="li">
                    enable the processing of transactions and send notices about
                    your transactions
                  </li>
                  <li className="li">
                    improve the Services, including by personalizing or
                    customizing your user experience
                  </li>
                  <li className="li">
                    measure the performance of the Services and improve our
                    content and layout
                  </li>
                  <li className="li">
                    enable you to communicate with other EquipMe App users,
                    including by sending them messages or other information
                    during the booking process
                  </li>
                  <li className="li">
                    if you provide us or our service providers any information
                    relating to other people in connection with the Services, we
                    may use and store this information to facilitate your
                    referral invitations; send your requests for reviews, for
                    fraud detection and prevention and for any purpose you
                    authorize at the time of collection
                  </li>
                </ul>
              </div>

              <div className="div-para">
                <b className="b">
                  Ensure a secure, safe and trusted experience
                </b>
                <ul>
                  <li className="li">
                    verify your identity or authenticate information provided by
                    you, including during account creation and password reset
                    processes
                  </li>
                  <li className="li">
                    resolve disputes, collect fees, and troubleshoot problems
                  </li>
                  <li className="li">
                    detect, prevent, and/or remediate fraud, abuse, security
                    incidents or other potentially harmful, prohibited or
                    illegal activities
                  </li>
                  <li className="li">
                    determine your likelihood of getting into an Equipment
                    accident or of making an insurance claim, such by checking
                    your auto insurance score and equipment operating history
                  </li>
                  <li className="li">
                    detect, prevent or remediate violations of and enforce our
                    Terms and Policies
                  </li>
                  <li className="li">
                    manage and protect our information technology infrastructure
                  </li>
                  <li className="li">
                    conducting investigations and risk assessments
                  </li>
                  <li className="li">
                    conduct checks against databases and information sources
                    (such as but not limited to public government databases)
                  </li>
                </ul>
                <p className="p">
                  In this regard, we may do any or all of the foregoing with or
                  without further notifying you as permitted by applicable law;
                </p>
                <b className="b">Provide and improve our Services</b>
                <ul>
                  <li className="li">
                    provide targeted marketing, provide service update notices,
                    and deliver promotional offers based on your communication
                    preferences
                  </li>
                  <li className="li">
                    perform creditworthiness and solvency checks, compare
                    information for accuracy, and verify it with third parties
                  </li>
                  <li className="li">
                    administer referral programs, rewards, surveys, contests, or
                    other promotional activities or sponsored events
                  </li>
                </ul>
                <p className="p">
                  We retain your information as long as it is reasonably
                  necessary and relevant for our operations, legal obligations
                  or other purposes.
                </p>
              </div>

              <div className="div-para">
                <h5 className="h5">Disclosure</h5>
                <b className="b">With consent</b>
                <p className="p">
                  You may direct us to share your information or consent to it
                  as described at the time of sharing, such as when you
                  authorize us to send to a third-party marketing partner.
                </p>
                <b className="b">
                  Profiles, listings and other public information
                </b>
                <p className="p">
                  Your public listing page will always include some basic
                  information, such as your user ID/name associated with your
                  account, your public profile photo, and for equipment owners,
                  the city where your equipment is located, your listing
                  description, your calendar availability, transaction related
                  information to allow our community to evaluate your
                  reliability and responsiveness, and any reviews/feedback. Your
                  public listing page may also include aggregate demand
                  information (such as number of page views over a period of
                  time) and information about your cancellations. Our Services
                  may also display the approximate geographic pick-up location
                  on a map of the owner’s equipment.
                </p>
                <p className="p">
                  Our Services may allow your public profile and public listing
                  pages to be included in search engines, in which case your
                  public profile and public Listing pages may be indexed by
                  search engines and may be published as search results.
                </p>
                <b className="b">
                  Sharing between equipment Owners and Renters
                </b>
                <p className="p">
                  GoEquipMe enables equipment owners to offer and share their
                  equipment with other individuals. If you agree to a booking
                  through the Services, we may provide your information to the
                  other party in that transaction as reasonably necessary to
                  facilitate the transaction. For example, GoEquipMe App may
                  provide your mobile phone number and/or driver’s license
                  information to facilitate communication or your photograph to
                  facilitate identification. We will provide the address of the
                  equipment and/or the proposed delivery location. We may also
                  share user-uploaded “Equipment Photos” with captions with the
                  other party involved in the reservation if that feature is
                  selected. GoEquipMe App users engaged in a booking with you
                  may send you text message (such as to confirm pickup or
                  delivery location).
                </p>
              </div>

              <div className="div-para">
                <b className="b">Service providers</b>
                <p className="p">
                  We may share Information with vendors and service providers
                  who are engaged by, or working with, us in connection with the
                  processing of payments, operation of our Services and customer
                  support functions and who need access to such information to
                  carry out their work for us. In some cases, the service
                  provider may be directly collecting the information from you
                  on our behalf. These service providers may be located inside
                  or outside your country of residence, including outside the
                  EEA. For hosts who choose to use our photography program, your
                  contact information will be shared with the photographer
                  assigned to shoot your vehicle. These providers have limited
                  access to your information to perform these tasks on our
                  behalf and are contractually obligated to use it consistent
                  with this Privacy Policy.
                </p>

                <b className="b">Aggregated data</b>
                <p className="p">
                  We may compile aggregate information about the use of the
                  Services. In other words, information about how you use a
                  service may be collected and combined with information about
                  how others use the same service. Aggregate data helps us
                  understand trends and our users’ needs so that we can better
                  consider new features or otherwise tailor our Services. We may
                  also share aggregate demographic information with advertisers
                  and partners. This policy in no way restricts or limits our
                  collection and use of aggregate information that does not
                  identify any individual.
                </p>

                <b className="b">
                  Responding to Legal Requests, Preventing Harm, and Protecting
                  our Rights
                </b>
                <p className="p">
                  We may disclose your information to courts, law enforcement,
                  governmental or tax authorities, or authorized third parties.
                  We will make such disclosure to the extent we are required or
                  permitted to do so by applicable law or if such disclosure is
                  reasonably necessary to comply with our legal obligations or
                  legal process and to respond to claims asserted against us. We
                  may also disclose your information in response to valid legal
                  requests relating to criminal investigations or alleged or
                  suspected illegal activity or any other activity that may
                  expose us, you, or any other user to legal liability or to
                  protect the rights, property, or personal safety our
                  employees, our users, or members of the public.
                </p>
              </div>

              <div className="div-para">
                <h5 className="h5">Your preferences and choices</h5>
                <b className="b">Communication preferences</b>
                <p className="p">
                  You can control the methods by which we may contact you about
                  your account, your booking and listing activities, promotions,
                  and announcements in the Notifications section within your
                  GoEquipMe account.
                </p>
                <b className="b">Correct and update</b>
                <p className="p">
                  You can review, correct, update, and edit certain information
                  that has been previously provided to us by you at any time by
                  logging in to your account and reviewing your account settings
                  and profile. You can also access or request a correction of
                  your information by contacting us at{" "}
                  <a className="a-link-gem" href="mailto:admin@goequipme.com">
                    admin@goequipme.com{" "}
                  </a>
                  , we may need to verify your identity before implementing your
                  request.
                </p>
                <b className="b">Account closure</b>
                <p className="p">
                  If you wish to close your account and request deletion of your
                  personal information, please send an email to{" "}
                  <a className="a-link-gem" href="mailto:admin@goequipme.com">
                    admin@goequipme.com
                  </a>
                  .
                </p>
                <p className="p">
                  Please note that we may be unable to delete information to
                  comply with applicable laws, detect or prevent fraud, collect
                  any fees owed, resolve disputes, assist with or process
                  claims, troubleshoot problems, assist with any investigation,
                  comply with audits and investigation, to enforce our Terms of
                  Service and take other actions reasonably necessary,
                  permitted, or required by applicable law. There may also be
                  residual information that will remain within our databases and
                  other records, which will not be removed.
                </p>
                <b className="b">Access</b>
                <p className="p">
                  You may request a copy of your personal information provided
                  to us by visiting your account page on the app. We will send
                  you with a copy of the personal information to the email
                  address provided in your account as soon reasonably
                  practicable. We may request proof of identification or
                  re-authentication to verify your access request.
                </p>
              </div>

              <div className="div-para">
                <b className="b">Lodging a complaint</b>
                <p className="p">
                  Subject to applicable law, you also have the right to lodge a
                  complaint with your local data protection authority or the
                  European Data Protection Supervisor.
                </p>
                <p style={{ textAlign: "center" }}>
                  <b className="b">Security</b>
                </p>
                <p className="p">
                  We have endeavored to put into place reasonable security
                  measures in an effort to protect information against
                  unauthorized access, destruction, or alteration while it is
                  under our control. However, no method of transmission over the
                  Internet, and no method of storing information, can be 100%
                  secure. So, we cannot guarantee the absolute security of your
                  transmissions to us and of your information that we store.
                </p>
                <p style={{ textAlign: "center" }}>
                  <b className="b">Important information</b>
                </p>
                <p className="p">
                  This section describes some additional important privacy
                  information related to your use of our Services.
                </p>
                <b className="b">Change of ownership</b>
                <p className="p">
                  If we merge with or are acquired by another company, we may
                  share information with them in accordance with our privacy
                  standards and applicable law.
                </p>
                <b className="b">Cross border transfer</b>
                <p className="p">
                  The Services are controlled and operated by us from the United
                  States, and are not intended to subject us to the laws or
                  jurisdiction of any state, country, or territory other than
                  that of the United States. Your information may be used,
                  stored and processed in any country where we have facilities
                  or in which we engage service providers, and by using the
                  Services you consent to the transfer of information to
                  countries outside of your country of residence, including the
                  United States, which may have different data protection rules
                  than those of your country. As a result, this information may
                  be subject to access requests from governments, courts,
                  regulatory agencies, security authorities or law enforcement
                  in those jurisdictions according to the laws in those
                  jurisdictions.
                </p>
              </div>

              <div className="div-para">
                <p className="p"></p>
                <b className="b">Sensitive information</b>
                <p className="p">
                  We ask that you not send us, and you not disclose, any
                  sensitive information unless specifically requested (e.g.,
                  Social Security numbers, social insurance numbers, passports,
                  information related to racial or ethnic origin, political
                  opinions, religion, trade union membership or other beliefs,
                  health, biometrics, or genetic characteristics) and subject to
                  applicable law on or through the Services or otherwise to us.
                </p>
              </div>

              <div className="div-para">
                <b className="b">Children’s privacy</b>
                <p className="p">
                  Our websites are general audience websites, and our Services
                  are not intended for anyone under the age of 21. We do not
                  knowingly collect information from users under the age of 21.
                  If a child has already provided us with information, his or
                  her parent or guardian may contact us for the purpose of
                  deleting this information or closing an account.
                </p>
              </div>

              <div className="div-para">
                <b className="b">Third party privacy practices</b>
                <p className="p">
                  This Privacy Policy addresses only the use and disclosure of
                  information collected by Us. This Privacy Policy does not
                  address, and we are not responsible for, the privacy,
                  information, or other practices of any third parties,
                  including any third party operating any site or service to
                  which the Services link. The inclusion of a link on the
                  Services does not imply endorsement of the linked site or
                  service by us or by our affiliates. If you disclose your
                  information to others, or if you are directed to a third party
                  website, their privacy notices and practices will apply.
                </p>
                <br></br>
                <p className="p">
                  This Privacy Policy addresses only the use and disclosure of
                  information collected by Us. This Privacy Policy does not
                  address, and we are not responsible for, the privacy,
                  information, or other practices of any third parties,
                  including any third party operating any site or service to
                  which the Services link. The inclusion of a link on the
                  Services does not imply endorsement of the linked site or
                  service by us or by our affiliates. If you disclose your
                  information to others, or if you are directed to a third party
                  website, their privacy notices and practices will apply.
                </p>
              </div>

              <div className="div-para">
                <b className="b">Translations</b>
                <p className="p">
                  If we have provided you with a translation of the English
                  language version of this Privacy Policy, in case of any
                  wording discrepancies between the English and any other
                  versions of the Privacy Policy, the English wording takes
                  precedence.
                </p>
                <p style={{ textAlign: "center" }}>
                  <b className="b">Changes to this privacy policy</b>
                </p>
                <p className="p">
                  We may change this Privacy Policy. Please take a look at the
                  “Last revised” legend at the top of this page to see when this
                  Privacy Policy was last revised. Any changes to this Privacy
                  Policy will become effective when we post the revised Privacy
                  Policy on the Services. Your use of the Services following
                  these changes means that you accept the revised Privacy
                  Policy. If you don’t agree to these changes, you can contact
                  us to close your account and you will only be bound by the
                  prior version of the Privacy Policy.
                </p>
                <p style={{ textAlign: "center" }}>
                  <b className="b">Contact</b>
                </p>
                <p className="p">
                  We welcome your questions and comments about privacy, and what
                  we do. Please feel free to contact us at{" "}
                  <a className="a-link-gem" href="mailto:admin@goequipme.com">
                    admin@goequipme.com
                  </a>
                  .
                </p>
                <p className="p">Thanks for using GoEquipMe App!</p>
              </div>
            </div>
          </div>
        </Box>
      </MainLayout>
    </>
  );
};

export default TermsAndConditions;
