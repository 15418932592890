import { Box, Button, Link } from "@mui/material";
import MainLayout from "layouts/MainLayout";
import { useEffect, useState } from "react";
import { useNavigate, Link as RLink } from "react-router-dom";

// redux
import { IStoreValue } from "utils/types";
import { useDispatch, useSelector } from "react-redux";
import {
  setLogInLoading,
  setLoginModalOpen,
  SetRedirectPage,
  setEquipAddType,
} from "redux/redux-slice";

// components
import Testimonials from "components/howItWorks/Testimonials/Testimonials";
import ListEqpQuesRent from "components/howItWorks/GotQuestions/ListEqpQuesRent";
import ListForRentTab from "components/howItWorks/TabLikeSec/ListForRentTab";

// images
import Jcb from "../../../assets/images/howitworks/tractor.png";
import keepEqpImg from "../../../assets/images/howitworks/keep-your-eqp.png";
import edpImg from "../../../assets/images/howitworks/protection.png";
import liveHelp from "../../../assets/images/gem-livehelp.png";
import gpsIcon from "../../../assets/images/howitworks/GPS_satellite.png";
import phoneImg from "../../../assets/images/howitworks/office_trailer.png";
import laptopImg from "../../../assets/images/howitworks/laptop.png";
import mobileImg from "../../../assets/images/howitworks/mobile.png";

// styles
import "./styles.scss";
import RentSteps from "components/user-signup/signupPopup/RentSteps";

const ListForRent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const UserID = useSelector(
    (state: IStoreValue) => state.equipmentReducer.Authuser
  );
  const AuthProfileData = useSelector(
    (state: IStoreValue) => state.equipmentReducer.AuthProfileData
  );

  const LoginPopup = () => {
    localStorage.setItem("RedirectLink", "/listequipment");
    dispatch(setLoginModalOpen(true));
    dispatch(setLogInLoading(false));
  };

  const [completeProfile, setCompleteProfile] = useState<boolean>(false);
  const handleCloseCompleteProfile = () => setCompleteProfile(false);

  const handleListEquipment = (event) => {
    event.stopPropagation();
    if (parseFloat(AuthProfileData?.["is_profile_completed"]) === 0) {
      dispatch(SetRedirectPage("/listequipment"));
      setCompleteProfile(true);
      return false;
    }
    // window.location.href = "/listequipment";
    navigate("/listequipment");
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  // useEffect(() => {
  //     console.log("User ID: ", UserID);
  //     if (!UserID) {
  //         console.log("UserID not true");
  //     }
  // }, [UserID]);

  return (
    <MainLayout>
      <>
        <Box className="listeqp-bg-img">
          <img src={Jcb} alt="" />
        </Box>
        <Box className="hw">
          <Box>
            <Box className="bac-top-main">
              <Box className="bac-top">
                <Box className="bac-L">
                  <h1 className="bac-heading">
                    LIST <span>equipment</span>
                  </h1>
                </Box>
                <Box className="bac-R">
                  <Button
                    className="bac-btn"
                    onClick={(event) => {
                      if (UserID) {
                        dispatch(setEquipAddType("rent"));
                        // navigate("/listequipment");
                        handleListEquipment(event);
                      } else {
                        // dispatch(setEquipAddType("rent"));
                        // dispatch(SetRedirectPage("listequipment"));
                        LoginPopup();
                      }
                    }}
                  >
                    List for Rent
                  </Button>
                </Box>
              </Box>
              <p className="bac-subheading">
                Build a rental yard or simply offset your existing equipment
                cost.
              </p>
            </Box>

            <ListForRentTab />

            {/* Keep Your Equipment Working for You! */}
            <Box className="lgt">
              <Box className="lgt-content">
                <p className="lgt-heading">
                  Keep Your Equipment <br />
                  Working for You!
                </p>
                <p className="lgt-text">
                  As the U.S. leader in equipment sharing, GoEquipMe’s easy to
                  use peer-to-peer platform maximizes your equipment’s profit
                  potential by keeping it working, even when you aren’t!
                </p>
              </Box>
              <Box className="lgt-img">
                <img src={keepEqpImg} alt="" />
              </Box>
            </Box>

            {/* Equip damage protection */}
            <Box className="edp">
              <Box className="edp-img">
                <img src={edpImg} alt="" />
              </Box>
              <Box className="edp-content">
                <p className="edp-heading">
                  EQUIPMENT DAMAGE PROTECTION AND LIABILITY INDEMNIFICATION
                </p>
                <p className="edp-text">
                  Rent worry free knowing every rental comes with{" "}
                  <span>GoEquipMe’s Equipment Damage Protection</span> and
                  liability indemnification.
                </p>
              </Box>
            </Box>

            {/* Get your own office trailer */}
            <Box className="get-ot">
              <Box className="get-ot-in">
                <Box className="get-ot-head">
                  <p className="get-ot-heading">Get Your Very Own Owners</p>
                  <p className="get-ot-subheading">
                    So many owner tools that managing your rentals is a breeze.
                  </p>
                </Box>
                <Box className="get-ot-img">
                  <img src={phoneImg} alt="" />
                </Box>

                <hr
                  className="divider"
                  style={{
                    border: "none",
                    backgroundColor: "#221F1F",
                    width: "100%",
                    height: "3px",
                    margin: "1rem 0 0.5rem 0",
                  }}
                />

                <Box className="get-ot-btm">
                  <Box className="get-ot-btm-img">
                    <img src={laptopImg} alt="" />
                  </Box>
                  <Box>
                    <p className="get-ot-btm-text">
                      Web or app solutions to manage your rentals from wherever
                      you are.
                    </p>
                  </Box>
                  <Box className="get-ot-btm-img">
                    <img src={mobileImg} alt="" />
                  </Box>
                </Box>
              </Box>
            </Box>

            {/* Testimonials */}
            <Testimonials />

            {/* 24/7 */}
            <Box className="mipt-3">
              <Box className="mipt-item">
                <Box className="mipt-item-img">
                  <img src={gpsIcon} alt="" />
                </Box>
                <p className="mipt-item-heading">
                  EQUIPMENT TRACKING SOLUTIONS
                </p>
                <p className="mipt-item-text">
                  Yep, we have that too. As a user you will have access to the
                  cheapest <RLink to="/equipment-tracking">GPS solution</RLink>{" "}
                  on the market. Choose between battery only and wired in
                  solutions
                </p>
              </Box>

              <Box className="mipt-item">
                <Box className="mipt-item-img">
                  <img src={liveHelp} alt="" />
                </Box>
                <p className="mipt-item-heading">
                  24/7 LIVE OWNER SUPPORT CHAT
                </p>
                <p className="mipt-item-text">
                  Have questions? Need Help? Simply click on &nbsp;
                  <Link
                    onClick={() => {
                      console.log("intercom working");
                      window["Intercom"]?.("show");
                    }}
                    sx={{ cursor: "pointer" }}
                  >
                    GoEquipMe’s 24/7 Live Chat
                  </Link>
                  &nbsp; for anything you might need.
                </p>
              </Box>
            </Box>

            {/* Got questions */}
            <ListEqpQuesRent />
          </Box>
        </Box>
        <RentSteps open={completeProfile} handleClose={handleCloseCompleteProfile}/>
      </>
    </MainLayout>
  );
};

export default ListForRent;
