import React, { useCallback, useEffect, useState, useRef } from "react";
import { EquipmentAPIs } from "../../service/api-service";
import {
  Box,
  CircularProgress,
  Backdrop,
  Button,
  TextField,
  FormControl,
  styled,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import InputAdornment from "@mui/material/InputAdornment";
import NumericInput from "material-ui-numeric-input";

import { useDispatch, useSelector } from "react-redux";
import {
  setalertMessagetype,
  setalertMessage,
  setSessionExpire,
} from "redux/redux-slice";
import { IApiResponseRawData, IStoreValue } from "utils/types";
import "./styles.scss";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// stripeCode
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { checkCardExpiry } from "utils/commonFxns";

let STRIPE_KEY = process.env.REACT_APP_STRIPE_PUBLISH_KEY;
let type = process.env.REACT_APP_PLATFORM;
if (type == "Live") {
  STRIPE_KEY = process.env.REACT_APP_STRIPE_PUBLISH_KEY_LIVE;
}

const stripePromise = loadStripe(STRIPE_KEY);

const loginstyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
  p: 4,
};

const NewCssTextField = styled(TextField)({
  "& .MuiInput-underline:after": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiInput-underline:before": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiFilledInput-underline:after": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiFilledInput-underline:before": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },

  "& .MuiInputLabel-shrink.MuiInputLabel-filled": {
    color: "#2d2d2d",
  },
  "& .MuiInputBase-root": {
    color: "#2d2d2d",
    background: "#fff",
    fontFamily: "Gotham !important",
    fontSize: "clamp(12px, 3vw, 15px) !important",
    borderRadius: "10px !important",
    // border: "2px solid #faa61a",
  },
  "&. MuiInputLabel-shrink": {
    color: "red !important",
    fontWeight: "Montserrat !important",
  },

  "&. MuiInputLabel-shrink.Mui-focused": {
    color: "red !important",
  },

  "& label.Mui-focused": {
    color: "#2d2d2d !important",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "2px solid #faa61a",
      backgroundColor: "#fff !important",
    },
    "&:hover fieldset": {
      borderColor: "#2d2d2d",
      border: "2px solid #faa61a",
      backgroundColor: "#fff !important",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#faa61a",
    },
  },
  "& .MuiInputBase-root-MuiFilledInput-root:before": {
    borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
  },
  "& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl": {
    // fontFamily:'Gotham !important'
  },
});

const NewCssNumericInput = styled(NumericInput)({
  "& .MuiInput-underline:after": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiInput-underline:before": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiFilledInput-underline:after": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },
  "& .MuiFilledInput-underline:before": {
    borderBottom: "0 !important",
    borderBottomColor: "transparent !important",
  },

  "& .MuiInputBase-root": {
    color: "#2d2d2d",
    background: "#fff",
    fontFamily: "Gotham !important",
    fontSize: "clamp(12px, 3vw, 15px) !important",
    borderRadius: "10px !important",
    // border: "2px solid #faa61a",
  },
  "& label.Mui-focused": {
    color: "#2d2d2d",
  },

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "2px solid #faa61a",
    },
    "&:hover fieldset": {
      borderColor: "#2d2d2d",
      border: "2px solid #faa61a",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#faa61a",
    },
  },
});

const PaymentHold = ({ amount, onSuccess, gpsPayment = false }) => {
  const dispatch = useDispatch();
  const ref = useRef(null);

  const AuthToken = useSelector(
    (state: IStoreValue) => state.equipmentReducer.accessToken
  );
  const UserId = useSelector(
    (state: IStoreValue) => state.equipmentReducer.Authuser
  );
  const [loading, setloading] = React.useState(false);
  const [savedToken, setsavedToken] = React.useState(null);

  const [balanceamount, setbalanceamount] = React.useState("");
  const [CardHolder, setCardHolder] = React.useState(null);
  const [CardNumber, setCardNumber] = React.useState(null);
  const [ExpMonth, setExpMonth] = React.useState(null);
  const [ExpYear, setExpYear] = React.useState(null);
  const [Cvc, setCvc] = React.useState(null);
  const [SaveCrad, setSaveCrad] = React.useState("no");

  const [SaveCards, setSaveCards] = React.useState([]);

  const addBalance = async (token, amount, is_card_saved = false) => {
    let stripe_token = token == null ? "null" : token;

    const dataInApi = {
      balance: parseFloat(amount).toFixed(2),
      token: stripe_token,
      is_card_saved: is_card_saved,
      is_saved: (savedToken == "other" || (gpsPayment && !UserId)) ? false : true,
      status: "success",
    };
    console.log("dataInApi: ", dataInApi);

    onSuccess(dataInApi);
    // setloading(false);
  };

  const SubmitHandler = () => {
    try {
      setloading(true);
      let name = CardHolder;
      let card_saved_status = SaveCrad;
      let is_card_saved = card_saved_status == "yes" ? true : false;
      let card = CardNumber;
      let month = ExpMonth;
      let year = ExpYear;
      let cvc = Cvc;
      let token = savedToken;

      if (token == null) {
        dispatch(setalertMessagetype("error"));
        dispatch(
          setalertMessage("Please select valid payment method to proceed")
        );
        setloading(false);
        return false;
      }

      if (token == "other") {
        if (name == null || name == "") {
          dispatch(setalertMessage("Please fill card holder name to proceed"));
          dispatch(setalertMessagetype("error"));
          setloading(false);
          return false;
        }

        if (card == null) {
          dispatch(setalertMessage("Please fill card number to proceed"));
          dispatch(setalertMessagetype("error"));
          setloading(false);
          return false;
        }

        if (card.length != 16) {
          dispatch(
            setalertMessage("Please enter valid card number to proceed")
          );
          dispatch(setalertMessagetype("error"));
          setloading(false);
          return false;
        }

        if (month == null) {
          dispatch(setalertMessage("Please fill expire month to proceed"));
          dispatch(setalertMessagetype("error"));
          setloading(false);
          return false;
        }

        if (year == null) {
          dispatch(setalertMessage("Please fill expire year to proceed"));
          dispatch(setalertMessagetype("error"));
          setloading(false);
          return false;
        }

        if (year.length != 2) {
          dispatch(
            setalertMessage("Please enter valid expire year to proceed")
          );
          dispatch(setalertMessagetype("error"));
          setloading(false);
          return false;
        }
        var c_data = new Date();
        var shortYear = c_data.getFullYear();
        var CurrentYear = shortYear.toString().substr(-2);
        var CurrentMonth = c_data.getMonth();
        if (CurrentYear == year) {
          if (CurrentMonth + 1 > month) {
            dispatch(
              setalertMessage("Please enter valid card expire month to proceed")
            );
            dispatch(setalertMessagetype("error"));
            setloading(false);
            return false;
          }
        }

        if (CurrentYear > year) {
          dispatch(
            setalertMessage("Please enter valid card expire year to proceed")
          );
          dispatch(setalertMessagetype("error"));
          setloading(false);
          return false;
        }

        if (cvc == null) {
          dispatch(setalertMessage("Please fill cvv number to proceed"));
          dispatch(setalertMessagetype("error"));
          setloading(false);
          return false;
        }

        if (cvc.length == 0 || cvc.length == 1 || cvc.length == 2) {
          dispatch(setalertMessage("Please enter valid cvv number to proceed"));
          dispatch(setalertMessagetype("error"));
          setloading(false);
          return false;
        }

        token = null;
      }

      setloading(true);
      addBalance(token, amount);
    } catch (err) {
      console.log(err);
      setloading(false);
    } finally {
      // setloading(false);
    }
  };

  const StripeComp = useCallback(
    function StripeComp() {
      const stripe = useStripe();
      const elements = useElements();
      const [error, setError] = useState(null);
      const [loading, setLoading] = useState(false);
      //below lines added by pp on 2 Apr 2024 due to amount decimal precision issues
      const [balanceAmount, setBalanceAmount] = useState("");

      const handleSubmitStripe = async (event) => {
        event.preventDefault();
        try {
          setloading(true);
          const { currentTarget } = event;
          const fD = new FormData(currentTarget);

          let balancecardholdername = fD.get("name");
          let name = balancecardholdername;
          // let name = newBalancecardholdername;
          if (name != null) {
            name = name;
          }

          if (name == null || name == "" || name.toString().trim() == "") {
            dispatch(setalertMessage("Please provide the card holder name"));
            dispatch(setalertMessagetype("error"));
            setloading(false);
            return false;
          }

          if (!stripe || !elements) {
            dispatch(setalertMessagetype("error"));
            dispatch(setalertMessage("Stripe Error in elements error"));
            setloading(false);
            console.log("Stripe Error in elements error");
            // Stripe.js has not loaded yet. Make sure to disable form submission or show an error.
            return;
          }
          // setLoading(true);
          const result = await stripe.createToken(
            elements.getElement(CardNumberElement)
          );

          const card = elements.getElement(CardNumberElement);

          // saveCardCheckbox
          let saveCheck = fD.get("saveCardCheckbox");
          console.log("save card checkbox:", saveCheck);
          if (saveCheck) {
            setSaveCrad("yes");
          } else {
            setSaveCrad("no");
          }
          // setLoading(false);
          if (result.error) {
            setloading(false);
            dispatch(setalertMessagetype("error"));
            dispatch(setalertMessage(result.error.message));
            return false;
          } else {
            setloading(true);
            addBalance(result.token.id, amount, saveCheck ? true : false);
          }
        } catch {
          setLoading(false);
        } finally {
          setloading(false);
        }
      };

      const inputStyle = {
        base: {
          fontSize: "16px", // Adjust the font size
          color: "#000", // Set the text color
          // fontFamily: "Gotham",
        },
        invalid: {
          color: "#dc3545", // Change color for invalid input
        },
      };
      return (
        <>
          <form onSubmit={handleSubmitStripe}>
            <Box className="addmoney-modal-content modal-content wallet-modal-content">
              {loading && (
                <Box>
                  <Backdrop
                    sx={{
                      color: "#fff",
                      zIndex: (theme) => theme.zIndex.drawer - 1,
                    }}
                    open={loading}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                </Box>
              )}

              <FormControl>
                <NewCssTextField
                  autoFocus
                  name="name"
                  id="name"
                  label="Name"
                  type="text"
                  fullWidth
                  variant="filled"
                  className="popup-input"
                  inputProps={{
                    maxLength: 60,
                    disableUnderline: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  // value={balancecardholdername}
                  placeholder={"Account holder name"}
                />
              </FormControl>

              <CardNumberElement
                className={"popup-input-strp"}
                options={{ style: inputStyle }}
              />
              <CardExpiryElement
                className={"popup-input-strp"}
                options={{ style: inputStyle }}
              />
              <CardCvcElement
                className={"popup-input-strp"}
                options={{ placeholder: "CVV", style: inputStyle }}
              />
              {/* by GD 2 Feb 2024 */}

              {(!gpsPayment || UserId>0) && <FormControl sx={{ marginTop: "1rem" }}>
                <FormControlLabel
                  name="saveCardCheckbox"
                  id="saveCardCheckbox"
                  control={
                    <Checkbox
                    // defaultChecked={SaveCrad == 'yes' ? true : false}
                    // onChange={HandleSaveCard}
                    />
                  }
                  label="Save card information to pay faster next time."
                  sx={{
                    background: "#faa61a",
                    borderRadius: "8px",
                    color: "#fff !important",
                    padding: "5px 0 !important",
                    fontFamily: "Gotham !important",
                    // "& .MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label":
                    //   {
                    //     fontFamily: "Gotham !important",
                    //     fontSize: "14px !important",
                    //   },
                  }}
                />
              </FormControl>}

              <Button
                type="submit"
                variant="contained"
                className="reset-cal-btn addmoney--checkout-btn new-money-btn transfer-btn"
              >
                Checkout
              </Button>
            </Box>
          </form>
        </>
      );
    },
    [savedToken]
  );

  const getSavedCard = async (user_id) => {
    try {
      if(!user_id) {
        return false;
      }
      setloading(true);
      const headers = { Authorization: `${AuthToken}`, request_from: "Web" };
      const Result = await EquipmentAPIs.SavedCardList(
        {
          user_id: user_id.toString(),
        },
        headers
      );

      if (Result["type"] == "RXSUCCESS") {
        let savedcards = Result.data.data;
        let othercardObj = [
          {
            id: "other",
            // label: "Another Crad",
            card: {
              last4: "Another Crad",
              brand: "",
            },
          },
        ];

        let final_result = [...savedcards, ...othercardObj];
        // console.log("SaveCards final_result: ", final_result);
        // if added by GD for selecting card if user has no card saved
        if (
          final_result &&
          final_result.length == 1 &&
          final_result[0].id == "other"
        ) {
          setsavedToken("other");
        }
        final_result = final_result.map((cardObj) => {
          if (cardObj.card) {
            cardObj["cardExpiry"] = checkCardExpiry(
              cardObj.card.exp_year,
              cardObj.card.exp_month
            );
          }
          return cardObj;
        });

        setSaveCards(final_result);
        //console.log(Result.data.data[0].card.last4)
        setloading(false);
      } else {
        dispatch(setalertMessage(Result["message"]));
        dispatch(setalertMessagetype("error"));
        setloading(false);
        return false;
      }
    } catch (err) {
      console.log(err);
      setloading(false);
    }
  };

  // commented by gd for live code on 29 Dec
  useEffect(() => {
    if (amount) {
      getSavedCard(UserId);
      setbalanceamount(amount);
    }
  }, [amount]);

  return (
    <>
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
      {!gpsPayment && (
        <Typography
          variant="h5"
          style={{ color: "#fff", marginBottom: "0.5rem", fontSize: "14px" }}
        >
          <span style={{ color: "#faa61a" }}>Note:</span> You have insufficient
          balance in your wallet, you can pay directly using your credit card.
          If the rental request is rejected by the rental owner, the amount paid
          via the credit card will be refunded back to you.
        </Typography>
      )}
      <Typography
        variant="h5"
        style={{ color: "#fff", marginBottom: "0.5rem", fontSize: "20px" }}
      >
        Pay Amount : {amount?.toFixed(2)}
      </Typography>
      {/* For Staging stripe card save method */}
      <Box
        className="wallet-accordion"
        sx={{ background: "transparent !important" }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            margin: "1rem 0",
          }}
        >
          {/* {SaveCards.length > 0 && SaveCards.map((CardsObj, index) => ( */}
          {SaveCards.length > 0 &&
            SaveCards.length != 1 &&
            SaveCards.map((CardsObj, index) => (
              <Box key={index}>
                <Box
                  sx={{
                    background: "#faa61a !important",
                    borderRadius: "10px !important",
                    boxShadow: "unset !important",
                    padding: "0 10px !important",
                    marginLeft: "0 !important",
                    marginRight: "0 !important",
                  }}
                >
                  <div
                    style={{
                      pointerEvents: CardsObj?.cardExpiry ? "none" : "auto",
                      opacity: CardsObj?.cardExpiry ? 0.5 : 1,
                    }}
                  >
                    <FormControlLabel
                      checked={savedToken == CardsObj.id}
                      value={CardsObj.id}
                      aria-label="Acknowledge"
                      onClickCapture={
                        (event) => {
                          setsavedToken(CardsObj.id);
                        }
                        //event.stopPropagation()
                      }
                      // onFocus={(event) => event.stopPropagation()}
                      control={<Checkbox />}
                      label={
                        CardsObj.id == "other"
                          ? "Use Another Card"
                          : "************" +
                            CardsObj.card.last4 +
                            " (" +
                            CardsObj.card.brand +
                            ")"
                      }
                      sx={{
                        color: "#fff !important",
                        padding: "5px 0 !important",
                        fontFamily: "Gotham !important",
                      }}
                    />
                    <span
                      style={{
                        color: "red",
                        marginLeft: "0.5rem",
                        fontSize: "12px",
                      }}
                    >
                      {CardsObj?.cardExpiry
                        ? "This card is past its expiration date"
                        : ""}
                    </span>
                  </div>
                </Box>
              </Box>
            ))}

          {/*by GD 2 Feb 2024 for creating a token  */}
          {(savedToken == "other" || (!UserId && gpsPayment)) && (
            <Elements stripe={stripePromise}>
              <StripeComp />
            </Elements>
          )}
        </Box>
        {(savedToken !== "other" && UserId>0) && (
          <Box className="wallet-accordion-footer">
            <Box>
              <Button
                type="submit"
                variant="contained"
                className="reset-cal-btn addmoney--checkout-btn new-money-btn transfer-btn"
                sx={{ width: "100%" }}
                onClickCapture={SubmitHandler}
              >
                Checkout
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};
export default PaymentHold;
